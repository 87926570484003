import * as React from 'react'

function SvgCarTestDrive(props) {
  return (
    <svg width={80} height={80} viewBox="0 0 80 80" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 57c1.105 0 2 .81 2 1.81 0 1.745-.745 3.068-1.833 4.056-1.008.915-2.283 1.515-3.278 1.961l-.469.21c-.866.385-1.476.657-1.943.985-.438.309-.477.477-.477.66 0 .21.07.434.543.797.542.416 1.314.783 2.378 1.283l.046.021c.946.444 2.154 1.01 3.105 1.77C17.11 71.38 18 72.559 18 74.19 18 75.19 17.105 76 16 76s-2-.81-2-1.81c0-.29-.11-.553-.572-.922-.533-.425-1.301-.802-2.349-1.294l-.146-.068c-.915-.429-2.067-.969-2.976-1.666C6.93 69.452 6 68.303 6 66.68c0-1.65.962-2.77 2.023-3.516.82-.577 1.864-1.038 2.7-1.407l.388-.172c1.005-.452 1.73-.827 2.222-1.274.412-.375.667-.801.667-1.502 0-1 .895-1.81 2-1.81zM62 57c1.105 0 2 .81 2 1.81 0 1.745-.745 3.068-1.833 4.056-1.008.915-2.283 1.515-3.278 1.961l-.469.21c-.866.385-1.476.657-1.943.985-.438.309-.477.477-.477.66 0 .21.07.434.543.797.542.416 1.314.783 2.378 1.283l.046.021c.946.444 2.154 1.01 3.105 1.77C63.11 71.38 64 72.559 64 74.19 64 75.19 63.105 76 62 76s-2-.81-2-1.81c0-.29-.11-.553-.572-.922-.533-.425-1.301-.802-2.349-1.294l-.145-.068c-.916-.429-2.068-.969-2.977-1.666C52.93 69.452 52 68.303 52 66.68c0-1.65.962-2.77 2.023-3.516.82-.577 1.864-1.038 2.7-1.407l.388-.172c1.005-.452 1.73-.827 2.222-1.274.412-.375.667-.801.667-1.502 0-1 .895-1.81 2-1.81zM26.613 8H54.21a8 8 0 017.888 6.666l.467 2.76H18.259l.466-2.76A8 8 0 0126.613 8zm39.429 6l.58 3.426.138.818C72.056 19.49 76 24.247 76 29.924v1.348c0 5.473-3.664 10.09-8.673 11.532v5.63a5.468 5.468 0 01-10.937 0v-5.162H22.228v5.161a5.468 5.468 0 01-10.937 0v-6.12C7.004 40.481 4 36.227 4 31.271v-1.347c0-5.978 4.371-10.935 10.092-11.85l.11-.649.58-3.427A12 12 0 0126.612 4H54.21a12 12 0 0111.832 10zM66 31a4 4 0 11-8 0 4 4 0 018 0zm-49 4a4 4 0 100-8 4 4 0 000 8z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCarTestDrive
