import React from 'react'
import { isTestEnvironment } from '@elementinsurance/utils'
import Box from '@elementinsurance/uikit/box/Box'
import Text from '@elementinsurance/uikit/text/Text'

const FlowStateCheck = ({ flow, children }) => {
  const isInactive = flow.state === 'INACTIVE'
  const isNotPublished = flow.state !== 'PUBLISHED'

  if (isNotPublished) {
    const robotsMeta = document.createElement('meta')
    robotsMeta.name = 'robots'
    robotsMeta.content = 'noindex'

    document.head.appendChild(robotsMeta)
  }

  return isInactive && !isTestEnvironment() ? (
    <Box fullWidth style={{ height: '100vh' }} justify="center" align="center">
      <Text size="l">Tut mir leid. Diese Seite ist nicht mehr verfügbar.</Text>
    </Box>
  ) : (
    children
  )
}

export default FlowStateCheck
