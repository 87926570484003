import ReactModal from 'react-modal'
import React, { useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { Box } from '@elementinsurance/uikit'
import { Cross } from '@elementinsurance/uikit/icons/svgr/m'
import _isFunction from 'lodash/isFunction'
import ModalConfirm from './ModalConfirm'

import styles from './modal.module.scss'

export const colors = ['primary', 'secondary', 'success', 'warning', 'danger']
export const aligns = ['center', 'start', 'end', 'baseline']
export const justifies = ['center', 'left', 'right', 'around', 'between', 'start', 'end']

const Modal = ({
  open = false,
  color,
  onClose,
  showClose,
  children,
  className,
  justify = 'center',
  align = 'center',
  fullWidth,
  rounded,
  dataCy,
  ...props
}) => {
  const [isOpen, setOpen] = useState(open)

  useEffect(() => {
    setOpen(open)
  }, [open])

  const handleClose = () => {
    setOpen(false)
    onClose && onClose()
  }

  return (
    <ReactModal
      className={cn(styles.modal, className)}
      overlayClassName={cn(styles.overlay, 'data-testid-overlay', {
        [styles[`justify_${justify}`]]: !!justify,
        [styles[`align_${align}`]]: !!align,
      })}
      isOpen={isOpen}
      onRequestClose={handleClose}
      {...props}
    >
      <Box
        data-testid={dataCy}
        className={cn(styles.wrapper, className, {
          [styles[color]]: !!color,
          [styles.fullWidth]: !!fullWidth,
          [styles.rounded]: !!rounded,
        })}
        fullWidth
      >
        <Box className={styles.content} direction="column">
          {_isFunction(children) ? children() : children}
        </Box>
        {showClose && (
          <Box justify="end" className={styles.icon} onClick={handleClose}>
            <Cross data-testid="modal-close-icon" className={styles.cross} />
          </Box>
        )}
      </Box>
    </ReactModal>
  )
}

export { ReactModal }

Modal.confirm = props => {
  const clear = () => {
    document.body.removeChild(document.querySelector('.ReactModalPortal'))
  }

  const container = document.createElement('div')
  const root = createRoot(container)
  root.render(<ModalConfirm {...props} clear={clear} />)
}

Modal.propTypes = {
  open: PropTypes.bool,
  fullWidth: PropTypes.bool,
  rounded: PropTypes.bool,
  color: PropTypes.oneOf(colors),
  align: PropTypes.oneOf(aligns),
  justify: PropTypes.oneOf(justifies),
}

export default Modal
