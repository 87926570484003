import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { Box, Text } from '@elementinsurance/uikit'
import { useTranslation } from '@elementinsurance/utils'

import styles from './copyright.module.scss'

const Copyright = ({ children, prefix = 'common:footer', name, className, ...props }) => {
  const { t } = useTranslation()

  return (
    <Box direction="column" className={cn(styles.copyright, className)} {...props}>
      <Text data-testid="footer-copyright-parnter" size="xs" color="footer">
        {name &&
          t(prefix + '.partner', {
            name,
          })}
      </Text>
      <Text data-testid="footer-copyright-info" size="xs" color="footer">
        {t(prefix + '.info')}
      </Text>
      <Text data-testid="footer-copyright-legend" top="xs" size="xs" color="footer">
        {t(prefix + '.legend')}
      </Text>
      {children}
    </Box>
  )
}

export const copyrightProps = {
  className: PropTypes.string,
}

Copyright.propTypes = copyrightProps

export default Copyright
