import { createReducer } from '@elementinsurance/store'
import { Step } from 'pages/purchase-flow/wizard/useStep'

import {
  SET_SELECTED_FREQUENCY_VARIANTS,
  CLEAR_WIZARD_DATA,
  WIZARD_SET_DATA,
  SET_STEPS,
  WIZARD_SET_COMPLETED,
} from './wizard.constants'

export type Wizard = Record<string, object> & {
  steps: Step[]
  completed?: boolean
  selectedFrequencyVariants: null | any
  dependencies?: Record<string, unknown>
}

export const initialState = {
  selectedFrequencyVariants: null,
  steps: [], // TODO: make initial value null
} as unknown as Wizard

export default createReducer(initialState, {
  [SET_STEPS]: (state: Wizard, { payload }: { payload: Step[] }) => ({ ...state, steps: payload }),
  [SET_SELECTED_FREQUENCY_VARIANTS]: handleSelectedFrequencyVariants,
  [CLEAR_WIZARD_DATA]: ({ steps }: Wizard) => ({ steps }),
  [WIZARD_SET_COMPLETED]: (wizard: Wizard) => ({ ...wizard, completed: true }),
  [WIZARD_SET_DATA]: handleData,
})

function handleData(state: Wizard, { payload }: { payload: any }) {
  return payload
    ? {
        ...state,
        [payload.id]: payload,
      }
    : {}
}

function handleSelectedFrequencyVariants(state: Wizard, { payload }: { payload: any }) {
  return { ...state, selectedFrequencyVariants: payload }
}
