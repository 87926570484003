import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { Box } from '@elementinsurance/uikit'

import styles from './block.module.scss'

const Block = ({ children, className, ...props }) => {
  return (
    <Box className={cn(styles.block, className)} {...props}>
      {children}
    </Box>
  )
}

export const blockProps = {
  className: PropTypes.string,
}

Block.propTypes = blockProps

export default Block
