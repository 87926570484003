import { createSelector } from 'reselect'
import _get from 'lodash/get'

export const getMainQuotes = state => state.quotes

export const getSelectedVariant = createSelector(getMainQuotes, field => {
  const selectedQuote = field.quotes.find(i => i.id === field.selectedQuoteId)
  return selectedQuote.key
})

export const getSelectedQuoteId = createSelector(getMainQuotes, field => field.selectedQuoteId)

export const getQuotes = createSelector(getMainQuotes, quotes => _get(quotes, 'quotes'))

export const getDocuments = id => createSelector(getQuotes, quotes => quotes?.find(i => i.id === id)?.documents || [])

export const getQuoteOptions = createSelector(getMainQuotes, quotes => _get(quotes, 'options'))

export const getQuoteOption = type => createSelector(getQuoteOptions, options => _get(options, type))

export const getSelectedQuote = createSelector(getSelectedQuoteId, getQuotes, (selectedQuoteId, quotes = []) => {
  const selectedQuote = quotes.find(quote => quote.id === selectedQuoteId)
  return selectedQuote || throwSelectedQuoteNotFoundException(quotes, selectedQuoteId)
})

const throwSelectedQuoteNotFoundException = (quotes, selectedQuoteId) => {
  throw new Error(`Unable to find selectedQuoteId '${selectedQuoteId}' in [${quotes?.map(x => x.id)}]`)
}
