import React from 'react'
import cn from 'classnames'
import { Text, Box } from '@elementinsurance/uikit'
import styles from './warning.module.scss'

const Warning = ({ className, name, children, ...props }) => {
  return (
    <Box className={cn(styles.wrapper, className)} padding="xxs" {...props}>
      <Text data-testid={`${name}-warning-label`}>{children}</Text>
    </Box>
  )
}

export default Warning
