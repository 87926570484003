import { useEffect, useState } from 'react'

export function useFontCss(cssUrl: string | undefined | null) {
  const [state, setState] = useState<{ loading: boolean; error?: unknown }>({ loading: true })
  useEffect(() => {
    let disposed = false

    if (!cssUrl) {
      setState({ loading: false })
      return
    }

    const link = Object.assign(document.createElement('link'), {
      rel: 'stylesheet',
      crossorigin: 'anonymous',
      href: cssUrl,
      onload: () => {
        document.fonts?.ready.then(() => !disposed && setState({ loading: false }))
      },
      onerror: () => {
        document.fonts?.ready.then(() => !disposed && setState({ loading: false, error: true }))
      },
    })

    // TODO: to speed up font loading, our html>head also needs a single instance of <link rel="preconnect" href="https://domain.with.fonts">
    document.head.appendChild(link)

    return () => {
      disposed = true
      link.remove()
    }
  }, [cssUrl])

  return state
}
