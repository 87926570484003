import { Box, Label, Tooltip } from '@elementinsurance/uikit'
import cn from 'classnames'
import PropTypes from 'prop-types'
import RcSelect, { Option } from 'rc-select'
import React, { memo } from 'react'
import { ArrowDown } from '../icons/svgr/m'
import styles from './select.module.scss'
import inputBaseStyles from '../input/inputBase.module.scss'

export const sizes = ['xs', 's', 'm', 'l', 'xl', 'xxl']

const Select = ({
  className,
  name,
  inputIcon,
  id,
  size,
  children,
  label,
  placeholder,
  top,
  bottom,
  left,
  right,
  options,
  value,
  onChange,
  tooltip,
  tooltipPosition,
  showSearch,
  required,
  loading,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  serverName, // we don't want to pass it down to html elements
  ...props
}) => {
  const emptyOrLoadingOptions = !options || options.length === 0 || loading
  return (
    <Box direction="column" top={top} right={right} bottom={bottom} left={left} fullWidth>
      <Box justify="between">
        <Label htmlFor={id} name={name} data-testid={`select-${name}-label`}>
          {label}
        </Label>
        <Tooltip tooltip={tooltip} tooltipPosition={tooltipPosition} name={name} />
      </Box>
      <RcSelect
        id={id}
        name={name}
        prefixCls="element-in-select"
        data-testid={`select-${name}`}
        optionLabelProp="children"
        dropdownClassName={styles.dropdown}
        className={cn(styles.select, inputBaseStyles.inputBase, className, {
          [styles[size]]: !!size,
          [styles.filled]: value != null,
        })}
        virtual={false} /* false - forces scroll when select is using virtual list internally */
        value={value}
        showSearch={showSearch}
        inputIcon={inputIcon || <ArrowDown />}
        placeholder={
          placeholder ? (
            <span aria-placeholder={placeholder} data-testid={`select-${name}-placeholder`}>
              {placeholder}
            </span>
          ) : null
        }
        required={required}
        loading={loading}
        dropdownStyle={emptyOrLoadingOptions ? { display: 'none' } : undefined}
        onChange={onChange}
        {...props}
      >
        {options?.map(({ value, label }) => (
          <Option
            data-testid={`select-${name}-option-${value}`}
            data-parent-testid={`select-${name}`}
            key={`${value}`}
            value={value}
          >
            {label}
          </Option>
        ))}
        {children}
      </RcSelect>
    </Box>
  )
}

Select.propTypes = {
  tooltipPosition: PropTypes.oneOf(['top', 'left', 'right', 'bottom']),
  bottom: PropTypes.oneOf(sizes),
  placeholder: PropTypes.string,
  right: PropTypes.oneOf(sizes),
  left: PropTypes.oneOf(sizes),
  size: PropTypes.oneOf(sizes),
  top: PropTypes.oneOf(sizes),
  tooltip: PropTypes.node,
  label: PropTypes.any,
}

export default memo(Select)
