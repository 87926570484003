import React from 'react'
import cn from 'classnames'
import { Box, Button } from '@elementinsurance/uikit'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from '@elementinsurance/utils'

import styles from './fillFormButton.module.scss'

export const testValues = {}

const FillFormButton = ({ size, className }) => {
  const { t } = useTranslation()

  const context = useFormContext()
  if (!context) {
    return null
  }

  const { setValue, control } = context
  const formFields = control?.fieldsRef?.current

  const fillForm = async () => {
    const fields = Object.values(formFields)

    await Promise.all(
      fields.map(async field => {
        const { name } = field.ref

        let value = testValues[name]
        let options = {}
        if (typeof value === 'function') {
          // used for payment iframe, for example
          const result = await value()
          value = result.value
          options = result.options ?? {}
        }

        setValue(name, value, { shouldValidate: true, ...options })
        if (field.onChange) field.onChange(value)
      })
    )
  }

  return (
    <Box className={cn(styles.wrapper, className)} right="xs">
      <Button
        className={styles.button}
        data-testid="button-test"
        onClick={fillForm}
        color="primary"
        size={size}
        type="button"
      >
        {t('common:testButton')}
      </Button>
    </Box>
  )
}

export default FillFormButton
