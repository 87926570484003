import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@elementinsurance/uikit'
import cn from 'classnames'

import inputBaseStyles from '@elementinsurance/uikit/input/inputBase.module.scss'
import inputStyles from '@elementinsurance/uikit/input/input.module.scss'
import styles from './dateInputTriplet.module.scss'

const emptyValue = null

const DateInputTriplet = ({
  value = emptyValue,
  disabled,
  onChange,
  onBlur,
  onPartFocus,
  invalid,
  placeholders,
  labelledById,
}) => {
  const propsForParts = [
    {
      part: 'day',
      ref: useRef(),
      maxLength: 2,
    },
    {
      part: 'month',
      ref: useRef(),
      maxLength: 2,
    },
    {
      part: 'year',
      ref: useRef(),
      maxLength: 4,
    },
  ]

  const [rawParts, setRawParts] = useState(createRawParts(value))

  const internalValue = getDateFromRawParts(rawParts)
  useEffect(() => {
    if (internalValue !== value) {
      // prevent rerender when there is only a difference in formatting
      setRawParts(createRawParts(value))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]) // do no trigger when internal value changes

  const correctAndFormatParts = event => {
    if (event.relatedTarget && event.currentTarget.contains(event.relatedTarget)) {
      return // switched focus between date inputs, so do nothing
    }

    const correctedRawParts = formatRawParts(correctRawParts(rawParts))
    setRawParts(correctedRawParts)
    const updatedDate = getDateFromRawParts(correctedRawParts)
    if (updatedDate !== value) {
      onChange(updatedDate)
    }

    onBlur?.()
  }

  const updateInternalValue = e => {
    if (!e.target.dataset.part) {
      throw new Error('Invalid event target')
    }
    const updatedRawParts = { ...rawParts, [e.target.dataset.part]: e.target.value }
    setRawParts(updatedRawParts)

    if (e.target.value.length === e.target.maxLength) {
      const nextPartProps = propsForParts[propsForParts.findIndex(x => x.part === e.target.dataset.part) + 1]
      nextPartProps?.ref.current.focus()
    }

    // We do no propagate onChange here (we do it in onBlur instead)
  }

  const commonPartProps = {
    disabled,
    className: cn(inputBaseStyles.inputBase, inputStyles.input),
    onChange: updateInternalValue,
    onKeyPress: onlyNumbers,
    onFocus: onPartFocus,
    type: 'tel',
    'aria-invalid': invalid,
  }

  return (
    <Box
      fullWidth
      role="group"
      aria-labelledby={labelledById}
      className={cn(styles.container, inputBaseStyles.inputGroup)}
      onBlur={correctAndFormatParts}
    >
      {propsForParts.map(x => (
        <InputWrapper key={x.part} value={rawParts[x.part]} invalid={invalid}>
          <input
            ref={x.ref}
            data-part={x.part}
            maxLength={x.maxLength}
            placeholder={placeholders?.[x.part]}
            value={rawParts[x.part]}
            {...commonPartProps}
          />
        </InputWrapper>
      ))}
    </Box>
  )
}

DateInputTriplet.propTypes = {
  onChange: PropTypes.func,
}

function InputWrapper({ value, invalid, children }) {
  const isFilled = (value ?? '').trim() !== ''
  return (
    <div
      aria-invalid={invalid}
      className={cn(inputBaseStyles.inputContainer, {
        [inputBaseStyles.inputContainerFilled]: isFilled,
        inputContainerFilled: isFilled,
      })}
    >
      {children}
    </div>
  )
}

function onlyNumbers(event) {
  if (!/\d+/.test(event.key)) {
    event.preventDefault()
  }
}

function formatRawParts(rawParts) {
  const padZeroIfInt = input => {
    const number = parseInt(input)
    if (number > 0 && number < 10) {
      return '0' + number
    }

    return input
  }

  return {
    year: rawParts.year,
    month: padZeroIfInt(rawParts.month),
    day: padZeroIfInt(rawParts.day),
  }
}

const getBetween = (value, min, max) => Math.max(Math.min(value, max), min)

function correctRawParts(rawParts) {
  const currentYear = new Date().getFullYear()
  const MIN_YEAR = currentYear - 120
  const MAX_YEAR = currentYear + 120

  const month = parseInt(rawParts.month)
  const year = parseInt(rawParts.year)

  return {
    year: Number.isSafeInteger(year) ? getBetween(year, MIN_YEAR, MAX_YEAR).toString() : '',
    month: Number.isSafeInteger(month) ? getBetween(month, 1, 12).toString() : '',
    day: rawParts.day,
  }
}

function getDateFromRawParts(rawParts) {
  if (!rawParts.year && !rawParts.month && !rawParts.day) {
    return emptyValue
  }

  const formattedRawParts = formatRawParts(rawParts)

  return [formattedRawParts.year, formattedRawParts.month, formattedRawParts.day]
    .map(x => (Number.isSafeInteger(parseInt(x)) ? x : ''))
    .join('-')
}

export function createRawParts(value) {
  if (!value) {
    return { year: '', month: '', day: '' }
  }

  // if (typeof value !== 'string' || value.length !== 10) {
  //   console.warn('Field is designed to accept dates as strings formatted as YYYY-MM-DD, but instead received', value)
  // }

  if (typeof value === 'string') {
    return createRawPartsFromDateOnlyString(value.split('T')[0])
  }

  if (typeof value === 'object' && 'toISOString' in value) {
    return createRawPartsFromDateOnlyString(value.toISOString().split('T')[0])
  }

  if (typeof value === 'object' && 'toISODate' in value) {
    return createRawPartsFromDateOnlyString(value.toISODate())
  }

  throw new TypeError(typeof value)
}

function createRawPartsFromDateOnlyString(dateOnlyString) {
  const [year = '', month = '', day = ''] = dateOnlyString.split('-')
  return { year, month, day }
}

export default DateInputTriplet
