import * as React from 'react'

function SvgCalendar(props) {
  return (
    <svg width={48} height={48} viewBox="0 0 48 48" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 9a3 3 0 00-3 3v27a3 3 0 003 3h30a3 3 0 003-3V12a3 3 0 00-3-3H9zm-6 3a6 6 0 016-6h30a6 6 0 016 6v27a6 6 0 01-6 6H9a6 6 0 01-6-6V12z"
        fill="currentColor"
      />
      <path
        d="M27.75 24a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5zM35.25 24a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5zM27.75 31.5a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5zM35.25 31.5a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5zM12.75 31.5a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5zM20.25 31.5a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5zM12.75 39a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5zM20.25 39a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5zM27.75 39a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3a1.5 1.5 0 011.5 1.5v3a1.5 1.5 0 01-3 0v-3A1.5 1.5 0 0112 3zM36 3a1.5 1.5 0 011.5 1.5v3a1.5 1.5 0 01-3 0v-3A1.5 1.5 0 0136 3zM4.5 13.5h39v3h-39v-3z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCalendar
