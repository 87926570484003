import { useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import 'normalize.css'
import '@elementinsurance/themes/variables.css'
import '@elementinsurance/themes/fonts.css'
import './main.scss'

import { Pages } from './pages'
import { useAnalyticsContext } from 'AnalyticsProvider'

export default function App() {
  const { analytics } = useAnalyticsContext()
  useEffect(() => {
    analytics().user()
    return () => {
      analytics().reset() // Important: wipes all data
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Router>
      <Pages />
    </Router>
  )
}
