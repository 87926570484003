import * as React from 'react'

function SvgPaw(props) {
  return (
    <svg width={48} height={48} viewBox="0 0 48 48" fill="none" {...props}>
      <g clipPath="url(#paw_svg__clip0)">
        <path
          d="M24 21C16.555 21 6 32.509 6 39.773 6 43.045 8.513 45 12.726 45c4.578 0 7.602-2.351 11.274-2.351 3.704 0 6.736 2.351 11.274 2.351C39.486 45 42 43.045 42 39.773 42 32.51 31.445 21 24 21zm-13.807-1.182c-.976-3.249-3.98-5.352-6.71-4.7-2.73.653-4.151 3.815-3.176 7.063.975 3.249 3.978 5.352 6.708 4.7 2.73-.652 4.153-3.815 3.178-7.063zm7.942-1.948c2.9-.763 4.352-4.682 3.242-8.753-1.11-4.07-4.361-6.75-7.262-5.988-2.9.764-4.352 4.682-3.242 8.753 1.11 4.07 4.362 6.752 7.262 5.988zm26.38-2.75c-2.73-.653-5.733 1.45-6.709 4.699-.974 3.248.448 6.41 3.178 7.063 2.73.652 5.733-1.451 6.709-4.7.974-3.248-.448-6.41-3.178-7.063zm-14.65 2.75c2.9.763 6.152-1.918 7.262-5.988 1.11-4.07-.341-7.989-3.242-8.753-2.9-.764-6.152 1.918-7.262 5.988-1.11 4.07.341 7.99 3.242 8.753z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="paw_svg__clip0">
          <path fill="#fff" d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgPaw
