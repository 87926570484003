export const isIOS = () => /iP(hone|od|ad)/.test(navigator.userAgent)

export const scrollToHighestOfElementsByAnchorAttr = (anchors: string[]) => {
  const body = document.querySelector('body')!
  const getTop = (element: Element) => element.getBoundingClientRect().top + window.pageYOffset

  const sortedHights = anchors
    .map(anchor => document.querySelector(`[data-anchor="${anchor}"]`))
    .filter((element): element is Element => element != null)
    .map(element => getTop(element))
    .sort((a, b) => a - b)
  const top = sortedHights[0] ?? getTop(body)

  window.scrollTo({ top, behavior: 'smooth' })
}

export const commonCustomerValues = [
  'type',
  'name',
  'firstName',
  'lastName',
  'gender',
  'email',
  'phone',
  'title',
  'externalId',
]
