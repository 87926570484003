import cn from 'classnames'
import PropTypes from 'prop-types'
import React, { FC, forwardRef } from 'react'
import styles from './box.module.scss'

export const sizes = ['xxs', 'xs', 's', 'm', 'l', 'xl']
export const justifies = ['center', 'left', 'right', 'around', 'between', 'start', 'end']
export const directions = ['row', 'column', 'row-reverse', 'column-reverse']
export const aligns = ['center', 'start', 'end', 'baseline']

const Box: FC<any> = forwardRef((props, ref) => {
  const {
    component: Component = 'div',
    className,
    margin,
    position = 'relative',
    top,
    bottom,
    left,
    right,
    justify,
    align,
    padding,
    direction,
    inject,
    flex,
    width,
    wrap,
    fullWidth = false,
    flexGrow,
    flexBasis,
    flexShrink,
    order,
    style: propStyles,
    children,
    danger,
    serverName,
    ...rest
  } = props

  const classes = cn(styles.box, className, {
    [styles[`justify_${justify}`]]: !!justify,
    [styles[`align_${align}`]]: !!align,
    [styles['wrap']]: !!wrap,
    [styles['fullWidth']]: !!fullWidth,
    [styles[`width_${width}`]]: !!width,
    [styles[position]]: !!position,
  })

  const inlineStyles = {
    marginTop: top ? `var(--b-size-${top})` : '',
    marginBottom: bottom ? `var(--b-size-${bottom})` : '',
    marginLeft: left ? `var(--b-size-${left})` : '',
    marginRight: right ? `var(--b-size-${right})` : '',
    flexDirection: direction,
    order,
    flex,
    ...propStyles,
  }

  const getSpaces = (type: string, size: string) => {
    if (!size.includes(' ')) {
      inlineStyles[type] = `var(--b-size-${size})`
    } else {
      inlineStyles[type] = size.split(' ').reduce((acc, s) => (acc + s !== '0' ? `var(--b-size-${[s]})` : '0 '), '')
    }
  }

  if (flexGrow !== undefined) {
    inlineStyles.flexGrow = flexGrow
  }

  if (flexBasis !== undefined) {
    inlineStyles.flexBasis = flexBasis
  }

  if (flexShrink !== undefined) {
    inlineStyles.flexShrink = flexShrink
  }

  if (padding) {
    getSpaces('padding', padding)
  }

  if (margin) {
    getSpaces('margin', margin)
  }

  if (inject && !Array.isArray(children)) {
    return React.cloneElement(children, {
      className: classes,
      style: inlineStyles,
    })
  }

  if (danger) {
    return (
      <Component
        ref={ref}
        {...rest}
        dangerouslySetInnerHTML={{ __html: children }}
        className={classes}
        style={inlineStyles}
      />
    )
  }

  return (
    <Component ref={ref} className={classes} style={inlineStyles} {...rest}>
      {children}
    </Component>
  )
})

Box.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  top: PropTypes.oneOf(sizes),
  bottom: PropTypes.oneOf(sizes),
  left: PropTypes.oneOf(sizes),
  right: PropTypes.oneOf(sizes),
  justify: PropTypes.oneOf(justifies),
  align: PropTypes.oneOf(aligns),
  padding: PropTypes.string,
  margin: PropTypes.string,
  direction: PropTypes.oneOf(directions),
  flex: PropTypes.string,
  width: PropTypes.oneOf([
    '5',
    '10',
    '15',
    '20',
    '25',
    '30',
    '35',
    '40',
    '45',
    '50',
    '55',
    '60',
    '65',
    '70',
    '75',
    '80',
    '85',
    '90',
    '95',
    '100',
  ]),
  position: PropTypes.oneOf(['fixed', 'absolute', 'relative', 'static']),
  flexGrow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flexBasis: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flexShrink: PropTypes.number,
  fullWidth: PropTypes.bool,
  inject: PropTypes.bool,
  danger: PropTypes.bool,
  wrap: PropTypes.bool,
  order: PropTypes.number,
}

Box.displayName = 'Box'

export default Box
