import * as React from 'react'

function SvgPlus(props) {
  return (
    <svg width={25} height={24} viewBox="0 0 25 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 3.392c.523 0 .947.425.947.948v14.29a.948.948 0 01-1.894 0V4.34c0-.523.424-.948.947-.948z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.407 11.485c0-.523.424-.947.947-.947h14.291a.947.947 0 010 1.895H5.355a.947.947 0 01-.948-.948z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgPlus
