import _set from 'lodash/set'

export const getFormatter = (option, params = {}) => {
  // Example 1:
  // const formattedValue = formatValueAs('currency')(13123123131)

  // Example 2:
  // const format = formatValueAs('currency')
  // format(123123)
  // format(4343)
  // format(99999)

  const formatFunc = {
    currency: v => new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', ...params }).format(v),
    percentage: v => v * 100 + '%',
    string: v => v + '',
  }
  const i = formatFunc[option]
  return value => (i ? i(value) : value)
}

export const formatAsEuro = (value, params) => getFormatter('currency', params)(value)

export const getParser = option => {
  const formatFunc = {
    number: v => +v,
    string: v => v + '',
  }
  const i = formatFunc[option]
  return value => (i ? i(value) : value)
}

export const parseSrtingKeysToObject = (obj = {}) => Object.keys(obj).reduce((acc, key) => _set(acc, key, obj[key]), {})

export const capitalize = (str = '') => str[0].toUpperCase() + str.slice(1, str.length)
