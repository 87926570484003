import * as React from 'react'

function SvgBuilding(props) {
  return (
    <svg width={48} height={48} viewBox="0 0 48 48" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 40.2a1.8 1.8 0 011.8-1.8h32.4a1.8 1.8 0 010 3.6H7.8A1.8 1.8 0 016 40.2z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.746 6.269A1.8 1.8 0 0127.6 7.8v32.4a1.8 1.8 0 01-3.6 0V10.712l-10.8 5.4V40.2a1.8 1.8 0 01-3.6 0V15a1.8 1.8 0 01.995-1.61l14.4-7.2a1.8 1.8 0 011.751.079z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.302 14.001a1.8 1.8 0 012.497-.499l10.8 7.2c.5.334.801.896.801 1.498v18a1.8 1.8 0 01-3.6 0V23.163l-9.998-6.665a1.8 1.8 0 01-.5-2.497zM18.6 16.8a1.8 1.8 0 011.8 1.8v.018a1.8 1.8 0 01-3.6 0V18.6a1.8 1.8 0 011.8-1.8zM18.6 22.2a1.8 1.8 0 011.8 1.8v.018a1.8 1.8 0 01-3.6 0V24a1.8 1.8 0 011.8-1.8zM18.6 27.6a1.8 1.8 0 011.8 1.8v.018a1.8 1.8 0 01-3.6 0V29.4a1.8 1.8 0 011.8-1.8zM18.6 33a1.8 1.8 0 011.8 1.8v.018a1.8 1.8 0 01-3.6 0V34.8a1.8 1.8 0 011.8-1.8z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgBuilding
