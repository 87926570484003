import * as React from 'react'

function SvgLicensePlate(props) {
  return (
    <svg width={36} height={36} viewBox="0 0 36 36" fill="none" {...props}>
      <g clipPath="url(#licensePlate_svg__clip0)">
        <g clipPath="url(#licensePlate_svg__clip1)">
          <path
            d="M33.351 12.942l-2.394-7.296a5.32 5.32 0 00-1.909-2.64A5.186 5.186 0 0025.978 2H10.022a5.186 5.186 0 00-3.07 1.005 5.32 5.32 0 00-1.909 2.641l-2.394 7.296c-.637.27-1.18.727-1.563 1.31a3.596 3.596 0 00-.586 1.97v8.89c0 1.311.707 2.445 1.75 3.062v4.048c0 .472.184.924.513 1.257.328.334.773.521 1.237.521h1.75c.464 0 .91-.187 1.237-.52.329-.334.513-.786.513-1.258v-3.555h21v3.555c0 .472.184.924.513 1.257.328.334.773.521 1.237.521H32c.464 0 .91-.187 1.237-.52.329-.334.513-.786.513-1.258v-4.048a3.51 3.51 0 001.28-1.293 3.58 3.58 0 00.47-1.77v-8.889c0-.7-.204-1.386-.586-1.97a3.513 3.513 0 00-1.563-1.31zm-23.33-7.386h15.956c.754 0 1.422.487 1.66 1.216l1.936 5.895H6.427l1.934-5.895c.116-.355.339-.663.637-.88a1.731 1.731 0 011.024-.336zM6.626 23.333a2.59 2.59 0 01-1.005-.203 2.622 2.622 0 01-.852-.579 2.704 2.704 0 01.001-3.773c.245-.247.534-.443.853-.577a2.592 2.592 0 012.861.58c.493.5.769 1.178.769 1.886a2.69 2.69 0 01-.77 1.886c-.493.5-1.16.78-1.857.78zm22.75 0a2.621 2.621 0 01-1.857-.782 2.67 2.67 0 01-.569-.865 2.706 2.706 0 01.57-2.907c.245-.248.534-.444.853-.578a2.591 2.591 0 012.861.58c.492.5.769 1.178.769 1.886a2.69 2.69 0 01-.77 1.886c-.493.5-1.16.78-1.857.78z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="licensePlate_svg__clip0">
          <path fill="#fff" d="M0 0h36v36H0z" />
        </clipPath>
        <clipPath id="licensePlate_svg__clip1">
          <path fill="#fff" transform="translate(-.375)" d="M0 0h36v36H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgLicensePlate
