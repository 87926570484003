import Analytics from 'analytics'
import googleTagManager from '@analytics/google-tag-manager'

type FlowProps = {
  id: string
  audience: string
}

export const BAVARIA_DIRECT_PRODUCT_IDS = [
  '93e07322-8943-4647-a252-eb84d724a887',
  '929dd3ea-e3c9-4bcd-8440-7c72089450f5',
].filter(Boolean)

export const MENEKS_PRODUCT_IDS = [
  '4ef3eee5-46d1-4287-ae41-c88fae1d6cac',
  'a4b67156-5798-4178-bc7c-05b50cb662eb',
  'e8b12cdc-f980-4ccd-8f4f-c460146191a4',
  'ab67b4af-f117-4b8e-af08-7b5be61e62e3',
].filter(Boolean)

export const NEOVA_PRODUCTS_IDS = [
  '31c0df36-07ba-43ad-8196-49cb6b3345bb',
  '6ac51b54-1d5e-4480-98eb-0114784ff012',
  '51366499-70ba-4b5f-a24c-869e62dd1149',
  'd02955e3-41f9-495f-94cb-6c75ffca29a0',
]

export const PANDA_PRODUCT_IDS = ['40775142-3819-483b-8a1f-39ce26dd25b8', 'ba11c622-2fb1-40cf-afdf-b3d38aa45126']

export const isBavariaDirect = (id: string) => BAVARIA_DIRECT_PRODUCT_IDS.includes(id)

export const isNeova = (id: string) => NEOVA_PRODUCTS_IDS.includes(id)

const isTest = 'Cypress' in window || process.env.NODE_ENV === 'test'

export const GtmBasic = {
  ...googleTagManager({ containerId: process.env.REACT_APP_GTM_CONTAINER_ID }),
  name: 'google-tag-manager',
}

export const GtmBavariaDirect = {
  ...googleTagManager({ containerId: process.env.REACT_APP_GTM_BAVARIA_DIRECT_CONTAINER_ID }),
  name: 'google-tag-manager-bavaria-direct',
}

export const GtmNeova = {
  ...googleTagManager({ containerId: process.env.REACT_APP_GTM_NEOVA_CONTAINER_ID }),
  name: 'google-tag-manager-neova',
}

export const GtmMeneks = {
  ...googleTagManager({ containerId: process.env.REACT_APP_GTM_MENEKS_CONTAINER_ID }),
  name: 'google-tag-manager-meneks',
}

export const GtmPanda = {
  ...googleTagManager({ containerId: process.env.REACT_APP_GTM_PANDA_CONTAINER_ID }),
  name: 'google-tag-manager-panda',
}

const getPlugins = (id?: string, flow?: FlowProps) => {
  if (BAVARIA_DIRECT_PRODUCT_IDS.includes(id as string)) {
    return [GtmBavariaDirect]
  }

  if (PANDA_PRODUCT_IDS.includes(id as string)) {
    return [GtmPanda]
  }

  if (
    MENEKS_PRODUCT_IDS.includes(id as string) &&
    flow?.id === '3351a170-671c-4a59-a308-0d3d22dbcb8f' &&
    flow?.audience === 'B2C'
  ) {
    return [GtmMeneks]
  }

  if (NEOVA_PRODUCTS_IDS.includes(id as string)) {
    return [GtmNeova]
  }

  return [GtmBasic]
}

export const analytics = (id?: string, flow?: FlowProps) =>
  Analytics({
    app: 'jetzt-abschliessen.de',
    plugins: getPlugins(id, flow),
  })

export const analyticsMain = (isAllowed?: boolean) => (id?: string, flow?: FlowProps) => {
  if (isAllowed) {
    return Analytics({
      app: 'jetzt-abschliessen.de',
      plugins: getPlugins(id, flow),
    })
  } else return analyticsNotInit(id, flow)
}

const trackPayloads: string[] = []

export const analyticsNotInit = (id?: string, flow?: FlowProps) => {
  ;(window as any).trackPayloads = trackPayloads
  return {
    track: () => {
      isTest && trackPayloads.push('track')
      console.log('Google Analytics track')
    },
    user: () => {
      isTest && trackPayloads.push('user')
      console.log('Google Analytics user')
    },
    reset: () => {
      isTest && trackPayloads.push('reset')
      console.log('Google Analytics reset')
    },
  }
}
