import * as React from 'react'

function SvgCarThirdParty(props) {
  return (
    <svg width={80} height={80} viewBox="0 0 80 80" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.842 40h16.957a9 9 0 018.874 7.5l.009.054H22.959l.009-.054a9 9 0 018.874-7.5zm28.789 7l.093.554.11.648C64.97 49.373 68 53.177 68 57.688c0 4.356-2.826 8.053-6.746 9.355v4.516a4.253 4.253 0 11-8.506 0v-4.014h-26.57v4.014a4.253 4.253 0 11-8.507 0v-4.945A9.858 9.858 0 0112 57.687c0-4.75 3.36-8.716 7.834-9.649l.082-.484.094-.555A12 12 0 0131.842 37h16.957A12 12 0 0160.63 47zm-.409 11A3.111 3.111 0 1154 58a3.111 3.111 0 016.222 0zm-38.11 3.111a3.111 3.111 0 10-.001-6.222 3.111 3.111 0 000 6.222z"
        fill="currentColor"
      />
      <path
        d="M64.136 12.25c-5.484-.074-9.96 4.402-9.886 9.886.073 5.259 4.355 9.54 9.614 9.614 5.484.075 9.96-4.402 9.885-9.885-.072-5.26-4.354-9.542-9.613-9.615zm5.926 15.34a.187.187 0 01-.288-.015 5.826 5.826 0 00-1.516-1.387C67.064 25.422 65.552 25 64 25s-3.064.422-4.258 1.188a5.824 5.824 0 00-1.516 1.386.188.188 0 01-.288.015 8.218 8.218 0 01-2.188-5.448c-.077-4.562 3.666-8.38 8.23-8.391 4.563-.011 8.27 3.694 8.27 8.25a8.218 8.218 0 01-2.188 5.59z"
        fill="currentColor"
      />
      <path
        d="M64 16.75c-.924 0-1.76.346-2.354.976-.594.63-.89 1.5-.824 2.434C60.958 22 62.384 23.5 64 23.5c1.616 0 3.039-1.5 3.178-3.34.069-.925-.225-1.787-.83-2.428-.595-.633-1.43-.982-2.348-.982zM40.136 4.25c-5.484-.074-9.96 4.402-9.886 9.886.073 5.259 4.355 9.54 9.614 9.614 5.484.075 9.96-4.402 9.885-9.885-.072-5.26-4.354-9.542-9.613-9.615zm5.926 15.34a.187.187 0 01-.288-.015 5.826 5.826 0 00-1.516-1.387C43.064 17.422 41.552 17 40 17s-3.064.422-4.258 1.188a5.824 5.824 0 00-1.516 1.386.188.188 0 01-.288.015 8.218 8.218 0 01-2.188-5.448c-.076-4.562 3.666-8.38 8.23-8.391 4.563-.011 8.27 3.694 8.27 8.25a8.218 8.218 0 01-2.188 5.59z"
        fill="currentColor"
      />
      <path
        d="M40 8.75c-.924 0-1.76.346-2.354.976-.594.63-.89 1.5-.824 2.434C36.958 14 38.384 15.5 40 15.5c1.616 0 3.039-1.5 3.178-3.34.069-.925-.225-1.787-.83-2.428-.595-.633-1.43-.982-2.348-.982zM16.136 12.25c-5.484-.074-9.96 4.402-9.886 9.886.073 5.259 4.355 9.54 9.614 9.614 5.484.075 9.96-4.402 9.885-9.885-.072-5.26-4.354-9.542-9.613-9.615zm5.926 15.34a.187.187 0 01-.288-.015 5.826 5.826 0 00-1.516-1.387C19.064 25.422 17.552 25 16 25s-3.064.422-4.258 1.188a5.824 5.824 0 00-1.516 1.386.188.188 0 01-.288.015 8.218 8.218 0 01-2.188-5.448c-.076-4.562 3.666-8.38 8.23-8.391 4.563-.011 8.27 3.694 8.27 8.25a8.218 8.218 0 01-2.188 5.59z"
        fill="currentColor"
      />
      <path
        d="M16 16.75c-.924 0-1.76.346-2.354.976-.594.63-.89 1.5-.824 2.434C12.958 22 14.384 23.5 16 23.5c1.616 0 3.039-1.5 3.178-3.34.069-.925-.225-1.787-.83-2.428-.595-.633-1.43-.982-2.348-.982z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCarThirdParty
