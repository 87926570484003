import * as React from 'react'

function SvgDogActive(props) {
  return (
    <svg width={65} height={64} viewBox="0 0 65 64" fill="none" {...props}>
      <path
        d="M5.825 11.858c1.488 3.663 4.497 7.476 10.05 8.586 10.919 2.184 15.3 2.915 24.086-.9-.339-.41-.6-.848-.746-1.288-.73-2.188 1.823-3.646 6.199-8.022 4.376-4.377 8.387.364 9.117 2.552.73 2.188 6.413 2.918 6.413 4.012 0 1.094-1.608 3.282-4.74 3.282-1.094 0-.944-.365-4.226-.365-2.626 0-3.282 1.945-3.282 2.917-.343 4.29-.525 5.835-1.191 7.294-.053.117-.11.233-.17.35-2.18 4.829 4.78 4.699 10.084 12.11.053.074.121.146.191.205 2.717 2.272-.478 2.166-1.813 1.816a.88.88 0 01-.448-.305C53.007 41.277 47.39 37.987 43 37.5c-.406.731-1.26 2.276-1.558 3.001-2.915 7.09-.93 13.697 1.055 15.681 1.094 1.094-.053 2.352-2.553 1.459-4.548-1.624-3.598-14.823-2.563-20.007.143-.715-.455-1.427-1.185-1.43-3.473-.009-7.531-1.137-10.625-2.27-.364 2.92-2.552 6.567-4.011 9.485-1.468 2.935 1.741 7.135 2.917 9.487 1.094 2.188-1.458 3.647-2.917 1.094-1.546-2.705-4.952-6.934-3.205-13.134a28.97 28.97 0 01-2.48 2.553c-4.01 4.376-.729 9.846.365 11.67 1.459 2.43-.775 4.686-2.552 2.552-7.234-8.68-.478-17.719-1.79-20.357a1.56 1.56 0 01-.06-.135c-2.47-6.472 1.752-12.926.082-14.469a.701.701 0 00-.12-.085c-5.065-2.994-7.047-7.098-7.583-10.63-.168-1.11 1.185-1.148 1.608-.107z"
        fill="currentColor"
      />
      <path
        d="M40.014 19.521c-8.822 3.84-13.198 3.111-24.139.923-5.553-1.11-8.562-4.923-10.05-8.586-.423-1.04-1.776-1.003-1.608.107.536 3.532 2.518 7.636 7.584 10.63a.701.701 0 01.118.085c1.671 1.543-2.55 7.997-.082 14.47.018.046.04.09.061.134 1.312 2.638-5.444 11.677 1.79 20.357 1.777 2.134 4.01-.121 2.552-2.553-1.094-1.823-4.376-7.293-.364-11.67a28.97 28.97 0 002.479-2.552M43 37.5c1.893-3.408 2.628-4.43 3.468-5.75.444-.7.777-1.256 1.037-1.824M43 37.5c-.406.731-1.26 2.276-1.558 3.001-2.915 7.09-.93 13.697 1.055 15.681 1.094 1.094-.053 2.352-2.553 1.459-4.548-1.624-3.598-14.823-2.563-20.007.143-.715-.455-1.427-1.185-1.43-3.473-.009-7.531-1.137-10.625-2.27m17.43 3.566c4.389.487 10.006 3.777 12.348 6.602a.88.88 0 00.448.305c1.335.35 4.53.456 1.813-1.816a1.132 1.132 0 01-.19-.205c-5.43-7.587-12.594-7.271-9.915-12.46m1.191-16.41c.527 3.164-.74 5.565-1.977 6.928-.473.523-.943.892-1.305 1.094-1.897 1.058-5.47-1.094-6.2-3.282-.729-2.188 1.824-3.646 6.2-8.022 4.376-4.377 8.387.364 9.117 2.552.73 2.188 6.413 2.918 6.413 4.012 0 1.094-1.608 3.282-4.74 3.282-1.094 0-.944-.365-4.226-.365-2.626 0-3.282 1.945-3.282 2.917-.343 4.29-.525 5.835-1.191 7.294m-21.934 4.007a47.017 47.017 0 01-1.548-.598c-.784-.32-1.795.427-1.984 1.252-.479 2.09-2.078 4.414-3.684 6.279m7.216-6.933c-.364 2.922-2.552 6.568-4.011 9.486-1.468 2.935 1.741 7.135 2.917 9.487 1.094 2.188-1.458 3.647-2.917 1.094-1.546-2.705-4.952-6.934-3.205-13.134"
        stroke="currentColor"
        strokeWidth={2}
      />
      <path d="M5.241 6.222C7.611 7.407 8.204 8 9.389 9.778" stroke="currentColor" strokeLinecap="round" />
      <path
        d="M45.392 21.328a1 1 0 00-.895 1.789l.895-1.79zm4.886 1.08c-.901 0-1.553-.003-2.266-.133-.7-.127-1.498-.386-2.62-.947l-.895 1.789c1.248.623 2.227.957 3.157 1.126.918.167 1.747.164 2.624.164v-2zM39.25 38.5l-.135.99.135-.99zM31.5 37a1 1 0 100 2v-2zm14.707 2.793c-.684-.684-1.762-1.147-2.888-1.485-1.163-.349-2.532-.608-3.934-.799l-.27 1.982c1.348.184 2.604.425 3.629.733 1.061.318 1.733.667 2.049.983l1.414-1.414zm-6.822-2.284C36.578 37.126 33.531 37 31.5 37v2c1.969 0 4.922.124 7.615.49l.27-1.98z"
        fill="#fff"
      />
    </svg>
  )
}

export default SvgDogActive
