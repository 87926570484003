import { isTestEnvironment, isPreviewEnvironment } from '@elementinsurance/utils/environment'
import { captureConsoleIntegration } from '@sentry/integrations'
import { getI18nConfig, i18n, I18nProvider } from '@elementinsurance/utils'
import * as reducers from '@elementinsurance/common/modules/reducers'
import { AnalyticsProvider } from 'AnalyticsProvider'
import { ReactModal } from '@elementinsurance/uikit'
import mainStore from '@elementinsurance/store'
import reportWebVitals from './reportWebVitals'
import client from '@elementinsurance/client'
import Cookiebot from 'react-cookiebot'
import * as Sentry from '@sentry/react'
import { Provider } from 'react-redux'
import React, { memo } from 'react'
import { createRoot } from 'react-dom/client'
import 'react-app-polyfill/stable'
import App from './App'

const FallbackComponent = () => {
  return <div>Page loading failed</div>
}

declare global {
  interface Window {
    store: any
    i18n: typeof i18n
    Cypress: unknown
  }
}

// eslint-disable-next-line react/display-name
const CookieBotWrapper = memo(function () {
  const COOKIE_BOT_ID = '0dd2c7a8-de1c-4edb-b276-537f7e8ad9cf'
  return process.env.NODE_ENV === 'production' ? <Cookiebot domainGroupId={COOKIE_BOT_ID} /> : null
})

if (window.location.search.includes('flow=')) {
  window.location.assign(
    `https://${isTestEnvironment() ? 'sandbox-' : ''}versicherung.jetzt-abschliessen.de${window.location.search}`
  )
} else {
  const i18n = getI18nConfig({
    languages: { 'de-DE': {} },
    language: 'de-DE',
  })

  const { store } = mainStore(reducers, client)

  if (process.env.NODE_ENV === 'development') {
    window.store = store
    window.i18n = i18n
  }
  const root = createRoot(document.getElementById('root') as HTMLElement)

  ReactModal.setAppElement('#root')

  if (process.env.NODE_ENV === 'production' && !window.Cypress && !isPreviewEnvironment()) {
    installSentryIO()
  }

  root.render(
    <React.StrictMode>
      <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
        <I18nProvider i18n={i18n}>
          <Provider store={store}>
            <AnalyticsProvider>
              <App />
            </AnalyticsProvider>
          </Provider>
        </I18nProvider>
        <CookieBotWrapper />
      </Sentry.ErrorBoundary>
    </React.StrictMode>
  )
}

function installSentryIO() {
  Sentry.init({
    dsn: 'https://9d55eebf2de84255b39aceeddca949e5@o151972.ingest.sentry.io/5370902',
    release: process.env.REACT_APP_VERSION,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      captureConsoleIntegration({
        levels: ['error'],
      }),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
      new Sentry.BrowserTracing(),
      new Sentry.BrowserProfilingIntegration(),
    ],
    tracesSampleRate: 1.0,
    profilesSampleRate: 1.0,
    denyUrls: [
      'https://payments-js.element.in/v1',
      'https://app.usercentrics.eu/latest/main.js',
      'https://app.usercentrics.eu/latest/bundle.js',
      'https://cdn-eu.dynamicyield.com/scripts/1.85.0/dy-coll-nojq-min.js',
      'https://consent.cookiebot.com/uc.js',
    ],
    ignoreErrors: [
      'ResizeObserver loop limit exceeded', // https://github.com/WICG/resize-observer/issues/38
    ],
  })
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
