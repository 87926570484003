import { isTestEnvironment, useTranslation } from 'packages/utils'
import s from './BannerForTestFlow.module.scss'

const BannerForTestFlow = () => {
  const { t } = useTranslation()
  const isTestEnv = process.env.NODE_ENV !== 'production' || isTestEnvironment()
  return isTestEnv ? (
    <div className={s.banner}>
      <p>{t('common:testEnv.title')}</p>
    </div>
  ) : null
}

export default BannerForTestFlow
