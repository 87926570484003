import React from 'react'
import { Helmet } from 'react-helmet'
import { isBavariaDirect } from 'analyticsHelper'

const BavariaDirectDynamicYield = ({ product }) => {
  const { id } = product

  return (
    isBavariaDirect(id) && (
      <Helmet>
        <link rel="preconnect" href="//cdn-eu.dynamicyield.com" />
        <link rel="preconnect" href="//st-eu.dynamicyield.com" />
        <link rel="preconnect" href="//rcom-eu.dynamicyield.com" />
        <script type="text/javascript" src="//cdn-eu.dynamicyield.com/api/9877144/api_dynamic.js"></script>
        <script type="text/javascript" src="//cdn-eu.dynamicyield.com/api/9877144/api_static.js"></script>
      </Helmet>
    )
  )
}

export default BavariaDirectDynamicYield
