import * as React from 'react'

function SvgDog(props) {
  return (
    <svg width={59} height={53} viewBox="0 0 59 53" fill="none" {...props}>
      <path
        d="M2.825 4.858c1.488 3.663 4.497 7.476 10.05 8.586 10.919 2.184 15.299 2.915 24.086-.9-.339-.41-.6-.848-.747-1.288-.729-2.188 1.824-3.646 6.2-8.022 4.376-4.377 8.387.364 9.117 2.552.729 2.188 6.413 2.918 6.413 4.012 0 1.094-1.609 3.282-4.74 3.282-1.094 0-.944-.365-4.226-.365-2.626 0-3.282 1.945-3.282 2.917-.477 5.962-.642 6.622-2.228 9.117-1.203 5.47-2.44 17.359 2.228 21.151 3.132 2.545-.88 2.232-1.974 1.824-1.952-.73-5.128-7.804-5.28-14.223-2.915 7.09-.93 13.697 1.054 15.681 1.094 1.094-.052 2.352-2.552 1.459-4.548-1.624-3.598-14.823-2.563-20.007.143-.715-.455-1.427-1.185-1.43-3.474-.009-7.531-1.137-10.625-2.27-.365 2.92-2.612 6.567-4.071 9.485-1.468 2.935.343 7.13 1.518 9.481 1.095 2.188-1.458 3.647-2.917 1.094-1.546-2.705-3.493-6.928-1.746-13.128a28.956 28.956 0 01-2.48 2.553c-4.011 4.376-.729 9.846.365 11.67 1.459 2.43-.775 4.686-2.553 2.552-7.233-8.68-.477-17.719-1.789-20.357a1.533 1.533 0 01-.061-.135c-2.468-6.472 1.753-12.926.082-14.469a.698.698 0 00-.118-.085c-5.066-2.994-7.048-7.098-7.584-10.63-.169-1.11 1.185-1.148 1.608-.107z"
        fill="currentColor"
      />
      <path
        d="M37.014 12.521c-8.822 3.84-13.199 3.111-24.139.923-5.553-1.11-8.562-4.923-10.05-8.586-.423-1.04-1.777-1.003-1.608.107.536 3.532 2.518 7.636 7.584 10.63a.698.698 0 01.118.085c1.671 1.543-2.55 7.997-.082 14.47.018.046.04.09.061.134 1.312 2.638-5.444 11.677 1.79 20.357 1.777 2.134 4.01-.121 2.552-2.553-1.094-1.823-4.376-7.293-.365-11.67a28.956 28.956 0 002.48-2.552m30.34-27.35c.73 4.376-1.973 7.293-3.281 8.022-1.897 1.058-5.47-1.094-6.2-3.282-.729-2.188 1.824-3.646 6.2-8.022 4.376-4.377 8.387.364 9.117 2.552.729 2.188 6.413 2.918 6.413 4.012 0 1.094-1.609 3.282-4.74 3.282-1.094 0-.944-.365-4.226-.365-2.626 0-3.282 1.945-3.282 2.917-.477 5.962-.642 6.622-2.228 9.117M22.57 26.933a46.93 46.93 0 01-1.548-.598c-.784-.32-1.795.427-1.984 1.252-.479 2.09-2.078 4.414-3.684 6.279m7.216-6.933c3.094 1.134 7.151 2.262 10.625 2.272.73.002 1.328.714 1.185 1.43-1.035 5.183-1.985 18.382 2.563 20.006 2.5.893 3.646-.365 2.552-1.459-1.984-1.985-3.97-8.59-1.054-15.68m-15.87-6.569c-.366 2.922-2.613 6.568-4.072 9.486-1.468 2.935.343 7.13 1.518 9.481 1.095 2.188-1.458 3.647-2.917 1.094-1.546-2.705-3.493-6.928-1.746-13.128m28.113-9.117c-.84 1.321-2.078 3.156-3.972 6.564a20.754 20.754 0 00-1.054 2.188m5.026-8.752c-1.203 5.47-2.44 17.359 2.228 21.151 3.132 2.545-.88 2.232-1.974 1.824-1.952-.73-5.128-7.804-5.28-14.223"
        stroke="currentColor"
        strokeWidth={2}
      />
    </svg>
  )
}

export default SvgDog
