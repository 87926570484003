import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react'
import { useHotjarInit, hotjarsMain } from 'hotjarHelper'
import { analyticsMain } from 'analyticsHelper'

interface IAnalyticsContext {
  hotjars: any
  analytics: any
  productMappings: {
    insuranceProducts: string
    partnerName: string
  }
  setProductMappings: Dispatch<
    SetStateAction<{
      insuranceProducts: string
      partnerName: string
    }>
  >
}

export const AnalyticsContext = createContext<IAnalyticsContext>({} as any)

export const useAnalyticsContext = () => useContext(AnalyticsContext)

export const AnalyticsProvider = ({ children }: { children: ReactNode }) => {
  const isStatisticEnabled = useHotjarInit()
  const [productMappings, setProductMappings] = useState({ insuranceProducts: '', partnerName: '' })

  return (
    <AnalyticsContext.Provider
      key={isStatisticEnabled}
      value={{
        hotjars: hotjarsMain(isStatisticEnabled),
        analytics: analyticsMain(isStatisticEnabled),
        productMappings,
        setProductMappings,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  )
}
