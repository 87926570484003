import { FlowContext } from '@elementinsurance/common/modules/flow/flow.context'
import useSessionStorage from '@elementinsurance/common/hooks/useSesionStorage'
import { useFlow } from '@elementinsurance/common/modules/flow/flow.loaders'
import DynamicLoader from '@elementinsurance/common/components/dynamic-loader'
import { useFlowSettingsInDocument } from './useFlowSettingsInDocument'
import ProductPurchaseWizard from './wizard/ProductPurchaseWizard'
import { useParams, useNavigate } from 'react-router-dom'
import { BASE_URL } from '@elementinsurance/client'
import ProductSelection from './ProductSelection'
import { useFontCss } from './useFontCss'
import * as Sentry from '@sentry/browser'

import FlowStateCheck from './FlowStateCheck'

export function PurchaseFlowContainer() {
  const { flowCrumb } = useParams()
  const { data: flow = { products: [], formData: {} }, error, isValidating: fetching } = useFlow(flowCrumb)
  const [sessionStorageFromData] = useSessionStorage('from', '')
  const navigate = useNavigate()
  if (error) {
    // if (sessionStorageFromData === 'old') redirect(`/?flow=${flowCrumb}&from=new`)
    if (sessionStorageFromData === 'old') {
      Sentry.captureMessage(`Error in flow [${flowCrumb}]. Redirected from OLD WLPF.`)
      navigate(`/?flow=${flowCrumb}&from=new`)
    }
  }

  return (
    <FlowStateCheck flow={flow}>
      {flow.crumb && <PurchaseFlow flow={flow} />}
      <DynamicLoader color={flow?.theme?.colors?.primary && 'secondary'} externalLoading={fetching} />
    </FlowStateCheck>
  )
}

function PurchaseFlow({ flow }) {
  const { productCrumb } = useParams()
  useFlowSettingsInDocument(flow)
  useFontCss(flow.customFontFamily && `${BASE_URL}/api/v2/flows/${flow.id}/font-css/`)
  return (
    <FlowContext.Provider value={flow}>
      <ProductPurchaseWizard flow={flow} routerProductCrumb={productCrumb} />
    </FlowContext.Provider>
  )
}

export function ProductsByFlow() {
  const { flowCrumb } = useParams()
  const { data: flow = { products: [], formData: {} }, error, isValidating: fetching } = useFlow(flowCrumb)
  const [sessionStorageFromData] = useSessionStorage('from', '')
  const navigate = useNavigate()
  if (error) {
    // if (sessionStorageFromData === 'old') redirect(`/?flow=${flowCrumb}&from=new`)
    if (sessionStorageFromData === 'old') {
      Sentry.captureMessage(`Error in flow [${flowCrumb}]. Redirected from OLD WLPF.`)
      navigate(`/?flow=${flowCrumb}&from=new`)
    }
  }

  return (
    <>
      {flow.crumb && (
        <FlowContext.Provider value={flow}>
          <ProductSelection
            flow={flow}
            getPathToProduct={({ flow, product }) => `/${flow.crumb}/abschliessen/${product.name}`}
          />
        </FlowContext.Provider>
      )}
      <DynamicLoader color={flow?.theme?.colors?.primary && 'secondary'} externalLoading={fetching} />
    </>
  )
}
