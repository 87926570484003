import * as React from 'react'

function SvgHomeContent(props) {
  return (
    <svg width={80} height={80} viewBox="0 0 80 80" fill="none" {...props}>
      <path
        d="M17.637 72a2.41 2.41 0 002.409-2.41h-4.819a2.41 2.41 0 002.41 2.41zM46.546 72a2.41 2.41 0 002.409-2.41h-4.818A2.41 2.41 0 0046.546 72z"
        fill="currentColor"
      />
      <path
        d="M22.455 31.849a.803.803 0 11-1.606 0 .803.803 0 011.606 0zM24.864 43.09a.803.803 0 11-1.606 0 .803.803 0 011.606 0zM19.243 37.47a.803.803 0 11-1.606 0 .803.803 0 011.606 0zM43.334 31.849a.803.803 0 11-1.606 0 .803.803 0 011.606 0zM40.924 43.09a.803.803 0 11-1.606.001.803.803 0 011.606 0zM36.91 37.47a.803.803 0 11-1.607 0 .803.803 0 011.606 0zM36.91 26.227a.803.803 0 11-1.607 0 .803.803 0 011.606 0zM45.743 37.47a.803.803 0 11-1.607 0 .803.803 0 011.607 0zM32.894 31.849a.803.803 0 11-1.606 0 .803.803 0 011.606 0zM32.894 43.09a.803.803 0 11-1.606.001.803.803 0 011.606 0zM28.076 37.47a.803.803 0 11-1.606 0 .803.803 0 011.606 0zM28.076 26.227a.803.803 0 11-1.606 0 .803.803 0 011.606 0z"
        fill="#B7B7B7"
      />
      <path d="M53.266 9.822A2 2 0 0155.258 8h13.484a2 2 0 011.992 1.822L72 24H52l1.266-14.178z" fill="currentColor" />
      <rect x={54} y={70} width={16} height={2} rx={1} fill="currentColor" />
      <rect x={61} y={27} width={2} height={45} rx={1} fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.417 39.879c2.439 0 4.416 2.157 4.416 4.818V58.36a3.2 3.2 0 003.2 3.2h24.115a3.2 3.2 0 003.2-3.2v-3.237-10.427c0-2.661 1.978-4.818 4.417-4.818 2.44 0 4.417 2.157 4.417 4.818 0 .982-.27 1.895-.732 2.657-.535.881-.874 1.934-.874 2.964v12.046a5.621 5.621 0 01-5.621 5.62H15.227a5.621 5.621 0 01-5.62-5.62V50.318c0-1.03-.34-2.083-.875-2.964A5.112 5.112 0 018 44.697c0-2.661 1.977-4.818 4.417-4.818z"
        fill="currentColor"
      />
      <rect x={18.4} y={53.6} width={27.2} height={6.4} rx={2} fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.307 40.102a4.052 4.052 0 00-.45.074.797.797 0 01-.493.143c-1.762.633-3.034 2.444-3.034 4.578v5.89a19.576 19.576 0 003.977-10.685zM16.815 51.773a19.591 19.591 0 01-4.781-11.678 4.14 4.14 0 01.197-.013.797.797 0 001.034.09c2.024.439 3.55 2.388 3.55 4.725v6.876zm1.6-6.876v8.027c.668-.502 1.5-.8 2.4-.8H43.33c.827 0 1.595.251 2.233.681l.167-.168v-7.74c0-3.276 2.366-6.18 5.611-6.404-.203-10.69-8.932-19.293-19.67-19.293-10.738 0-19.467 8.603-19.671 19.292.131-.009.264-.013.398-.013 3.451 0 6.017 3.007 6.017 6.418zM27.2 24a.8.8 0 100-1.6.8.8 0 000 1.6zm9.6-.8a.8.8 0 11-1.6 0 .8.8 0 011.6 0zm-20 11.68a.8.8 0 100-1.6.8.8 0 000 1.6zm10.667-.8a.8.8 0 11-1.6 0 .8.8 0 011.6 0zm9.066.8a.8.8 0 100-1.6.8.8 0 000 1.6zm10.667-.8a.8.8 0 11-1.6 0 .8.8 0 011.6 0zM20 45.76a.8.8 0 100-1.6.8.8 0 000 1.6zm8.8-.8a.8.8 0 11-1.6 0 .8.8 0 011.6 0zm7.2.8a.8.8 0 100-1.6.8.8 0 000 1.6zm8.8-.8a.8.8 0 11-1.6 0 .8.8 0 011.6 0zm-20 6.24a.8.8 0 100-1.6.8.8 0 000 1.6zm8-.8a.8.8 0 11-1.6 0 .8.8 0 011.6 0zm6.4.8a.8.8 0 100-1.6.8.8 0 000 1.6zm-17-10.88a.8.8 0 100-1.6.8.8 0 000 1.6zm10.2-.8a.8.8 0 11-1.6 0 .8.8 0 011.6 0zm8.6.8a.8.8 0 100-1.6.8.8 0 000 1.6zM20 29.44a.8.8 0 100-1.6.8.8 0 000 1.6zm12-.8a.8.8 0 11-1.6 0 .8.8 0 011.6 0zm10.4.8a.8.8 0 100-1.6.8.8 0 000 1.6z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgHomeContent
