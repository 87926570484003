import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Tooltip, Box } from '@elementinsurance/uikit'

import styles from './cel.module.scss'

const Cel = ({ className, color, tooltipPosition, tooltip, justify = 'between', children, ...props }) => {
  return (
    <td
      className={cn(
        styles.cel,
        {
          [styles[color]]: !!color,
        },
        className
      )}
      {...props}
    >
      <Box justify={justify}>
        {children}
        {tooltip && (
          <Box position="relative" align="center">
            <Tooltip tooltip={tooltip} tooltipPosition={tooltipPosition} name={props.name} />
          </Box>
        )}
      </Box>
    </td>
  )
}

Cel.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'meta']),
  tooltipPosition: PropTypes.oneOf(['left', 'right', 'bottom']),
}

export default Cel
