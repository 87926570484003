import React, { memo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import styles from './text.module.scss'

export const sizes = ['xxs', 'xs', 's', 'm', 'l', 'xl']
export const colors = [
  'primary',
  'secondary',
  'body',
  'meta',
  'description',
  'highlight',
  'info',
  'success',
  'danger',
  'footer',
]
export const fontWeights = ['lighter', 'normal', 'bold', 'bolder']

const Text = ({
  component: Component = 'p',
  top,
  left,
  right,
  bottom,
  color = 'primary',
  size = 's',
  className,
  align = 'left',
  transform,
  decoration,
  bold,
  overflow,
  inject,
  italic = false,
  breakWord,
  danger,
  ...props
}) => {
  const classes = cn(className, styles.text, {
    [styles[color]]: !!color,
    [styles[size]]: !!size,
    [styles[align]]: !!align,
    [styles[bold]]: !!bold,
    [styles['italic']]: !!italic,
    [styles[breakWord]]: !!breakWord,
    [styles[transform]]: !!transform,
    [styles[decoration]]: !!decoration,
    [styles[overflow]]: !!overflow,
  })

  const inlineStyles = {
    marginTop: top ? `var(--b-size-${top})` : '',
    marginBottom: bottom ? `var(--b-size-${bottom})` : '',
    marginLeft: left ? `var(--b-size-${left})` : '',
    marginRight: right ? `var(--b-size-${right})` : '',
  }

  if (inject) {
    return React.cloneElement(props.children, {
      className: classes,
    })
  }

  if (danger) {
    const { children, ...rest } = props
    return (
      <Component style={inlineStyles} {...rest} dangerouslySetInnerHTML={{ __html: children }} className={classes} />
    )
  }

  return <Component style={inlineStyles} {...props} className={classes} />
}

Text.propTypes = {
  color: PropTypes.oneOf(colors),
  size: PropTypes.oneOf(sizes),
  top: PropTypes.oneOf(sizes),
  bottom: PropTypes.oneOf(sizes),
  left: PropTypes.oneOf(sizes),
  right: PropTypes.oneOf(sizes),
  transform: PropTypes.oneOf(['capitalize', 'lowercase', 'uppercase']),
  decoration: PropTypes.oneOf(['none', 'dashed', 'underline', 'all-time-underline', 'overline', 'line-through']),
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
  align: PropTypes.oneOf(['left', 'right', 'center', 'justify']),
  breakWord: PropTypes.oneOf(['break-all', 'break-word', 'keep-all', 'normal']),
  children: PropTypes.node,
  overflow: PropTypes.oneOf(['ellipsis', 'hidden']),
  italic: PropTypes.bool,
  inject: PropTypes.bool,
  className: PropTypes.string,
  bold: PropTypes.oneOf(fontWeights),
  danger: PropTypes.bool,
}

export default memo(Text)
