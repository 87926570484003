import * as React from 'react'

function SvgCart(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75 19.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM17.25 19.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM1.5 3.75A.75.75 0 012.25 3h3a.75.75 0 01.739.62l2.14 12.13H19.5a.75.75 0 010 1.5h-12a.75.75 0 01-.739-.62L4.621 4.5H2.25a.75.75 0 01-.75-.75z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.543 5.25a1.125 1.125 0 011.103 1.346l-1.35 6.75a1.126 1.126 0 01-1.104.904H7.5a.75.75 0 010-1.5h11.385l-.06.301a.375.375 0 01.367-.301h-.307l1.2-6h.457a.375.375 0 01-.367-.448m.367-1.052H6a.75.75 0 000 1.5h14.085l.09-.448m.367-1.052z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCart
