import * as React from 'react'

function SvgInfo(props) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 14.5a6.5 6.5 0 100-13 6.5 6.5 0 000 13zM8 16A8 8 0 108 0a8 8 0 000 16z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.25 5.5V4h1.5v1.5h-1.5zM7.25 12V7h1.5v5h-1.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgInfo
