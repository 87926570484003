import { BASE_URL } from '@elementinsurance/client'
import { isProductSupported } from './flow.constants'
import { PurchaseFlowResponse } from './PurchaseFlowResponse'

export type PurchaseFlow = ReturnType<typeof normalizeFlow>

export const normalizeFlow = ({
  hasLogo,
  id,
  products,
  customHeader,
  state,
  ...rest
}: PurchaseFlowResponse & { crumb: string }) => {
  const getAssetLink = (type: string) => `${BASE_URL}/api/v2/flows/${id}/assets/${type}`

  return {
    id,
    logo: hasLogo ? getAssetLink('logo') : null,
    products: products.filter(isProductSupported),
    state,
    ...rest,
    customHeader: {
      headerDesktop: customHeader?.headerDesktop ? getAssetLink('headerDesktop') : null,
      headerTablet: customHeader?.headerTablet ? getAssetLink('headerTablet') : null,
      headerMobile: customHeader?.headerMobile ? getAssetLink('headerMobile') : null,
    },
  }
}
