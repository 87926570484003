import * as React from 'react'

function SvgMinus(props) {
  return (
    <svg width={17} height={17} viewBox="0 0 17 17" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.407 8.485c0-.523.424-.947.947-.947h14.291a.947.947 0 010 1.895H1.356a.947.947 0 01-.948-.948z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgMinus
