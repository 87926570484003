import * as React from 'react'

function SvgCross(props) {
  return (
    <svg width={12} height={12} viewBox="0 0 12 12" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.277.277c.37-.37.97-.37 1.34 0l10.105 10.106a.947.947 0 11-1.34 1.34L.278 1.617a.947.947 0 010-1.34z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.277 11.723a.947.947 0 010-1.34L10.383.277a.947.947 0 011.34 1.34L1.617 11.722c-.37.37-.97.37-1.34 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCross
