import { useState } from 'react'
import { Select } from '@elementinsurance/uikit'
import style from './multiselect.module.scss'

const MultiSelect = props => {
  const [value, setValue] = useState([])

  const onChange = value => {
    setValue(value)
  }
  return (
    <div className={style.multiselect}>
      <Select
        className={style.multiSelect}
        allowClear
        multiple={true}
        choiceTransitionName="rc-select-selection__choice-zoom"
        tokenSeparators={[' ', ',']}
        mode="multiple"
        value={value}
        onChange={onChange}
        {...props}
      />
    </div>
  )
}

export default MultiSelect
