import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { Box, Image } from '@elementinsurance/uikit'
import { Media, DESKTOP_MIN_WIDTH, TABLET_MIN_WIDTH } from '@elementinsurance/utils'

import styles from './root.module.scss'

const Root = ({ children, className, customHeader = {}, ...props }) => {
  const { headerMobile, headerTablet, headerDesktop } = customHeader

  const renderDefaultHeader = () => (
    <Box fullWidth data-testid="header" component="header" className={cn(styles.header, className)} {...props}>
      {children}
    </Box>
  )

  const renderHeaderImage = src => (
    <Image data-testid="header" className={cn(styles.customHeader, className)} src={src} />
  )

  return (
    <>
      <Media query={{ maxWidth: TABLET_MIN_WIDTH - 1 }}>
        {isMobile => (isMobile ? (headerMobile ? renderHeaderImage(headerMobile) : renderDefaultHeader()) : null)}
      </Media>
      <Media query={{ minWidth: TABLET_MIN_WIDTH, maxWidth: DESKTOP_MIN_WIDTH - 1 }}>
        {isTablet => (isTablet ? (headerTablet ? renderHeaderImage(headerTablet) : renderDefaultHeader()) : null)}
      </Media>
      <Media query={{ minWidth: DESKTOP_MIN_WIDTH }}>
        {isDesktop => (isDesktop ? (headerDesktop ? renderHeaderImage(headerDesktop) : renderDefaultHeader()) : null)}
      </Media>
    </>
  )
}

export const rootProps = {
  className: PropTypes.string,
}

Root.propTypes = rootProps

export default Root
