import BannerForTestFlow from '@elementinsurance/common/components/banner-for-test-flow/BannerForTestFlow'
import { useLoadTranslations } from '@elementinsurance/common/modules/app/app.loaders'
import mainStyles from '@elementinsurance/common/components/main/main.module.scss'
import { PurchaseFlow } from '@elementinsurance/common/modules/flow/flow.normalizers'
import DynamicLoader from '@elementinsurance/common/components/dynamic-loader'
import Main from '@elementinsurance/common/components/main/Main'
import Header from '@elementinsurance/common/components/header'
import Footer from '@elementinsurance/common/components/footer'
import { Navigate, useNavigate } from 'react-router-dom'
import { useTranslation } from '@elementinsurance/utils'
import { useAnalyticsContext } from 'AnalyticsProvider'
import Button from '@elementinsurance/uikit/button'
import styles from './productSelection.module.scss'
import _camelCase from 'lodash/camelCase'
import { useEffect } from 'react'
import cn from 'classnames'

export default function ProductSelection({ flow, getPathToProduct }: ProductSelectionProps) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const products = flow.products
  const isInactiveFlow = flow?.state !== 'INACTIVE'
  const { hotjars } = useAnalyticsContext()
  const { loading } = useLoadTranslations(['common'], {
    speech: flow.speech,
    audience: flow.audience,
    partnerId: flow.partner?.id,
  })

  useEffect(() => {
    if (flow.id) {
      hotjars(flow.id).track(`statistics. flow=${flow.id}&product_selection=true`)
    }
  }, [flow?.id, hotjars])

  if (loading) {
    return <DynamicLoader.Start />
  }

  if (products.length === 1) {
    return <Navigate to={getPathToProduct({ flow, product: products[0] })} />
  }

  const hasProducts = products.length > 0

  return (
    <>
      {isInactiveFlow ? <Header className={undefined} /> : <BannerForTestFlow />}
      <Main>
        <div className={cn(styles.products, mainStyles.mainContent)}>
          <div className={styles.list}>
            <div className={styles.header}>{hasProducts ? t('productList.title') : t('productList.noProducts')}</div>
            <div data-testid="product-buttons">
              {products.map(product => (
                <div key={product.id}>
                  {/** @ts-ignore */}
                  <Button
                    color="primary"
                    className={styles.btn}
                    data-testid={`product-button-${_camelCase(product.name)}`}
                    onClick={() => {
                      navigate(getPathToProduct({ flow, product }))
                    }}
                  >
                    {product.name}
                  </Button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Main>
      {/** @ts-ignore */}
      {isInactiveFlow && <Footer />}
    </>
  )
}

type ProductSelectionProps = {
  flow: PurchaseFlow
  getPathToProduct: ({ flow, product }: { flow: PurchaseFlow; product: { name: string } }) => string
}
