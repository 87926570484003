import React, { useState, useLayoutEffect, useRef } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import InfoIcon from './icon/Icon'
import TooltipContent from './tooltip-content/TooltipContent'
import { useOnClickOutside } from '../hooks/useOnClickOutside'
import styles from './tooltip.module.scss'

export const colors = ['default', 'success', 'warning', 'danger']

const Tooltip = ({ tooltipPosition = 'bottom', className, tooltip, popover, children, name }) => {
  const [open, setOpen] = useState(false)
  const ref = useRef()
  const close = () => setOpen(false)
  useOnClickOutside(ref, close)

  return tooltip ? (
    <div className={cn('tooltip', styles.container)} ref={ref}>
      {children ? (
        <Popover nestedChildren={children} setOpen={setOpen} open={open} />
      ) : (
        <InfoIcon onClick={() => setOpen(!open)} color="primary" data-testid={`tooltip-icon-${name}`} />
      )}
      <TooltipContent
        data-testid={`tooltip-${name}`}
        onClose={popover ? undefined : close}
        position={tooltipPosition}
        className={className}
        popover={popover}
        open={open}
      >
        {tooltip}
      </TooltipContent>
    </div>
  ) : null
}

const Popover = ({ nestedChildren, setOpen }) => {
  const ref = useRef()
  const unset = () => setOpen(false)
  const set = () => setOpen(true)

  useLayoutEffect(() => {
    const readyRef = ref?.current
    readyRef?.addEventListener('mouseover', set)
    readyRef?.addEventListener('mouseout', unset)

    return () => {
      readyRef?.removeEventListener('mouseover', set)
      readyRef?.removeEventListener('mouseout', unset)
    }
  }, [ref]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div ref={ref} onClick={unset}>
      {nestedChildren}
    </div>
  )
}

Tooltip.propTypes = {
  tooltip: PropTypes.node,
  tooltipPosition: PropTypes.oneOf(['left', 'right', 'bottom']),
}

export default Tooltip
