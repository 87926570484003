import { i18n } from '@elementinsurance/utils'
import { useProductContext } from '@elementinsurance/common/modules/product/product.context'
import { useProductI18nVariables } from '@elementinsurance/common/modules/product/product.loaders'
import * as Sentry from '@sentry/browser'

export default function useFaqContent() {
  const product = useProductContext()
  const productI18nVariables = useProductI18nVariables()

  if (!product?.productLine) {
    return null
  }

  const key = product.productLine + ':FAQs'
  try {
    const faqs = i18n.exists(key) ? JSON.parse(i18n.t(key, productI18nVariables)) : null
    return faqs
  } catch (error) {
    console.log('error in faq ' + error)
    Sentry.captureMessage('faqs parse error')
  }
}
