import { formatAsEuro } from '@elementinsurance/utils'

export const normalizeQuote = ({ elements, ...rest }) => {
  const premium = elements[0]?.premium
  const attributes =
    elements[0]?.premiumDistribution?.defaultCoverage?.positions || elements[0]?.insuredEntities?.[0]?.positions || []
  return {
    ...rest,
    premium: formatAsEuro(premium),
    positions: attributes,
  }
}

export const normalizeQuotesData = ({ quotes }) => {
  return {
    quotes: quotes.map(normalizeQuote),
  }
}
