import React from 'react'
import cn from 'classnames'

import styles from './table.module.scss'

const Table = ({ className, children, ...props }) => {
  return (
    <table className={cn(styles.table, className)} border="0" cellSpacing="0" cellPadding="0" {...props}>
      <tbody>{children}</tbody>
    </table>
  )
}

export default Table
