import * as React from 'react'

function SvgHouseAddress(props) {
  return (
    <svg width={36} height={36} viewBox="0 0 36 36" fill="none" {...props}>
      <path
        d="M33 19a1 1 0 01-.71-.29L18 4.41 3.71 18.71A1 1 0 012.3 17.3l15-15a1 1 0 011.41 0l15 15A1 1 0 0133 19z"
        fill="currentColor"
      />
      <path d="M18 7.79L6 19.83V32a2 2 0 002 2h7V24h6v10h7a2 2 0 002-2V19.76L18 7.79z" fill="currentColor" />
    </svg>
  )
}

export default SvgHouseAddress
