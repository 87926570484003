import { createStore, applyMiddleware, combineReducers } from 'redux'
import { createLogger } from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension'

import thunk from './redux-thunk'
import reduxPromiseAction from './redux-promise-action'

export { createReducer } from './createReducer'

export default (reducers, client) => {
  const middlewares = [thunk({ client }), reduxPromiseAction(client)]

  if (process.env.NODE_ENV === 'development' && process.env.REDUX_LOGGER_ENABLED === 'true') {
    middlewares.push(createLogger({ collapsed: true, level: 'debug' }))
  }

  const store = createStore(combineReducers(reducers), composeWithDevTools(applyMiddleware(...middlewares)))

  return {
    store,
  }
}
