import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { getOverrides, Media, TABLET_MIN_WIDTH } from '@elementinsurance/utils'
import { useFlowContext } from '@elementinsurance/common/modules/flow/flow.context'
import * as defaultComponents from './components'

import styles from './footer.module.scss'

const Footer = ({ menu, className, children, overrides = {}, ...props }) => {
  const { partner } = useFlowContext()
  const name = partner?.name

  const {
    Root: { component: Root, props: rootProps },
    Block: { component: Block, props: blockProps },
    Menu: { component: Menu, props: menuProps },
    MenuItem: { component: MenuItem, props: menuItemProps },
    Partners: { component: Partners, props: partnersProps },
    Copyright: { component: Copyright, props: copyrightProps },
  } = getOverrides(defaultComponents, overrides)

  const renderMenuItems = ({ ...itemProps }, idx) => <MenuItem key={idx} {...itemProps} {...menuItemProps} />

  const containerClassName = cn(styles.slideIn, className)

  return (
    <Root className={containerClassName} {...rootProps} {...props}>
      <Media query={{ maxWidth: TABLET_MIN_WIDTH }}>
        {matches =>
          matches ? (
            <>
              <Partners {...partnersProps} />
              <Block className={styles.menu} direction="column" fullWidth {...blockProps}>
                {menu && (
                  <Menu items={menu} {...menuProps}>
                    {({ items }) => items.map(renderMenuItems)}
                  </Menu>
                )}
              </Block>
            </>
          ) : (
            <Block className={styles.menu} justify="start" align="start" fullWidth {...blockProps}>
              <Block direction="column" width="50" {...blockProps}>
                {menu && (
                  <Menu items={menu} {...menuProps}>
                    {({ items }) => items.map(renderMenuItems)}
                  </Menu>
                )}
              </Block>
              <Block direction="column" width="50" justify="end" align="end" {...blockProps}>
                <Partners {...partnersProps} />
              </Block>
            </Block>
          )
        }
      </Media>
      <Copyright name={name} {...copyrightProps} />
      {children}
    </Root>
  )
}

Footer.defaultProps = {
  menu: [
    {
      name: 'Imprint',
      modalContent: 'common:imprint',
    },
    {
      name: 'Privacy',
      modalContent: 'common:privacy',
      // eslint-disable-next-line no-script-url
      contentLinkReplacer: uri => (uri === 'COOKIEBOT_RENEW_LINK' ? 'javascript:Cookiebot.renew()' : null),
    },
  ],
}

Footer.propTypes = {
  name: PropTypes.string,
  menu: PropTypes.array,
}

export default Footer
