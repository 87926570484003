import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Box } from '@elementinsurance/uikit'
import { Cross } from '@elementinsurance/uikit/icons/svgr/m'

import styles from './tooltipContent.module.scss'

const TooltipContent = ({ position = 'right', open, popover, onClose, className, children, ...props }) => {
  return (
    <Box
      className={cn('tooltip-content', styles.container, className, {
        [styles[position]]: !!position,
        [styles.popover]: !!popover,
        [styles.open]: !!open,
      })}
      fullWidth
      direction="column"
      {...props}
    >
      <Box justify="between" fullWidth padding="xs">
        <Box className={styles.text} fullWidth direction="column">
          {children}
        </Box>
        <Box justify="end" className={cn('tooltip-close', styles.icon)} onClick={onClose}>
          {onClose && <Cross data-testid="tooltip-close-icon" className={styles.cross} />}
        </Box>
      </Box>
    </Box>
  )
}

TooltipContent.propTypes = {
  open: PropTypes.bool,
  position: PropTypes.oneOf(['left', 'right', 'bottom']),
  onClose: PropTypes.func,
  className: PropTypes.string,
}

export default TooltipContent
