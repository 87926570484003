import * as React from 'react'

function SvgDisabilitySubsistence(props) {
  return (
    <svg width={80} height={80} viewBox="0 0 80 80" fill="none" {...props}>
      <g clipPath="url(#disabilitySubsistence_svg__clip0)" fill="currentColor">
        <path d="M62.156 59.265c-.946 0-1.887-.598-2.394-1.522l-6.685-12.22-17.185.001c-.02 0-.215-.016-.215-.016l-.312.003a3.742 3.742 0 01-3.724-3.488l-1.09-16.62a3.745 3.745 0 013.489-3.972l.977-.061a3.737 3.737 0 013.977 3.472l.352 5.524h11.023c.734 0 1.332.715 1.332 1.595 0 .878-.598 1.593-1.332 1.593H39.552l.378 5.7h13.002c.336 0 .659.053.988.163l.07.023.074.013c.807.14 1.519.673 1.952 1.461l7.948 14.533c.717 1.325.363 2.923-.79 3.558a2.09 2.09 0 01-1.018.26z" />
        <path d="M35.269 22.362a2.734 2.734 0 012.727 2.542l.292 4.589.119 1.873H50.369c.117 0 .332.226.332.595 0 .368-.215.593-.332.593H38.483l.142 2.132.246 3.7.124 1.868h13.938c.228 0 .446.037.672.111l.142.048.148.025c.498.087.964.445 1.244.955l7.945 14.527c.458.847.283 1.838-.39 2.209a1.067 1.067 0 01-.537.136c-.587 0-1.183-.394-1.516-1.002l-6.4-11.7-.57-1.04H35.928l-.092-.015h-.331l-.037.001-.102.002a2.74 2.74 0 01-2.727-2.553L31.55 25.339a2.743 2.743 0 012.549-2.911l.986-.061a3.8 3.8 0 01.184-.005zm0-2c-.104 0-.208.003-.313.01l-.98.061a4.74 4.74 0 00-4.422 5.035l1.09 16.621a4.736 4.736 0 004.897 4.419h.138c.093.015.185.015.276.015h16.53l6.4 11.7c.708 1.289 1.997 2.042 3.272 2.042.52 0 1.028-.123 1.502-.384 1.643-.905 2.165-3.1 1.184-4.913l-7.95-14.535c-.6-1.09-1.598-1.781-2.655-1.965a4.09 4.09 0 00-1.305-.214H40.866l-.246-3.7h9.749c1.289 0 2.332-1.166 2.332-2.593 0-1.429-1.043-2.595-2.332-2.595H40.284l-.292-4.589a4.727 4.727 0 00-4.723-4.415zM34.532 18.076a4.505 4.505 0 01-4.5-4.5c0-2.481 2.019-4.5 4.5-4.5a4.506 4.506 0 014.5 4.5c0 2.481-2.018 4.5-4.5 4.5z" />
        <path d="M34.532 10.075a3.504 3.504 0 013.5 3.5c0 1.93-1.57 3.5-3.5 3.5s-3.5-1.57-3.5-3.5c0-1.929 1.57-3.5 3.5-3.5zm0-2a5.5 5.5 0 10.001 11.001 5.5 5.5 0 00-.001-11.001zM23.019 33.527s-9.161 6.521-8.298 18.369c.863 11.847 10.168 18.658 18.227 19.809 8.059 1.151 16.979-2.173 21.968-10.966.479-1.073 1.007-1.84 0-2.8 0 0-1.295-1.007-2.303.527-1.007 1.535-2.494 5.421-7.961 8.202-3.358 1.727-7.82 3.262-14.006 1.392-6.188-1.871-13.862-8.97-12.854-18.946.67-5.276 2.446-9.449 8.01-13.765 1.15-1.055 1.103-1.774.575-2.398-.527-.624-1.343-.768-2.11-.288-.77.481-1.248.864-1.248.864z" />
      </g>
      <defs>
        <clipPath id="disabilitySubsistence_svg__clip0">
          <path fill="#fff" transform="translate(8 8)" d="M0 0h64v64H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgDisabilitySubsistence
