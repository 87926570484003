/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { getLoading } from '@elementinsurance/common/modules/app/app.selectors'
import { toggleLoader } from '@elementinsurance/common/modules/app/app.actions'
import { useSelector, useDispatch } from 'react-redux'
import { Loader } from '@elementinsurance/uikit'

const DynamicLoader = ({
  color = 'defaultColor',
  position,
  size = 'l',
  externalLoading = false,
  ...props
}: DynamicLoaderProps) => {
  const loading = useSelector(getLoading) || externalLoading
  return <Loader position="fixed" show={loading} size={size} color={color} {...props} />
}

const DynamicLoaderStarter = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(toggleLoader(true))
    return () => {
      dispatch(toggleLoader(false))
    }
  }, [])
  return null
}

DynamicLoader.Start = DynamicLoaderStarter

type DynamicLoaderProps = {
  externalLoading?: boolean
  color?: string
  position?: string
  size?: string
  force?: boolean
}

export default DynamicLoader
