import { TOGGLE_LOADER, TOGGLE_CART } from './app.constants'

export const toggleLoader = status => ({
  type: TOGGLE_LOADER,
  payload: status,
})

export const toggleCart = status => ({
  type: TOGGLE_CART,
  payload: status,
})
