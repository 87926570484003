import {
  SET_SELECTED_FREQUENCY_VARIANTS,
  CLEAR_WIZARD_DATA,
  WIZARD_SET_DATA,
  SET_STEPS,
  WIZARD_SET_COMPLETED,
} from './wizard.constants'

export function setWizardData(id, data) {
  return ({ dispatch }) => dispatch({ type: WIZARD_SET_DATA, payload: { id, ...data } })
}

export function setDependentField(name, value) {
  return ({ dispatch, getState }) => {
    const wizard = getState().wizard
    dispatch(setWizardData('dependencies', { ...wizard.dependencies, [name]: value }))
  }
}

export function updateSteps(steps) {
  return ({ dispatch }) => {
    dispatch({
      type: SET_STEPS,
      payload: steps,
    })
  }
}

export function clearWizard() {
  return ({ dispatch }) => dispatch({ type: CLEAR_WIZARD_DATA })
}

export function makeWizardCompleted() {
  return ({ dispatch }) => dispatch({ type: WIZARD_SET_COMPLETED })
}

export function setSelectedFrequencyVariants(selectedFrequencyVariants) {
  return ({ dispatch }) => dispatch({ type: SET_SELECTED_FREQUENCY_VARIANTS, payload: selectedFrequencyVariants })
}
