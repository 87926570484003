import React, { memo } from 'react'
import cn from 'classnames'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import styles from './carousel.module.scss'

const ReactCarousel = ({ className, children, ...props }) => {
  return (
    <Carousel className={cn(styles.carousel, className)} {...props}>
      {children}
    </Carousel>
  )
}

export default memo(ReactCarousel)
