import { Box, Text } from '@elementinsurance/uikit'
import { useTranslation } from '@elementinsurance/utils'

import FAQItem from './faq-item/FAQItem'

import styles from './faqContent.module.scss'

export default function FAQContent({ content }) {
  const { t } = useTranslation()

  return (
    <Box className={styles.content} direction="column" fullWidth>
      <Box data-testid="faq-info-content" fullWidth bottom="xs">
        <Text data-testid="faq-info-title" className={styles.title} component="h3" bold="bold">
          {t('common:faq.title')}
        </Text>
      </Box>
      {content.map(item => (
        <FAQItem key={item.question} question={item.question} answer={item.answer} />
      ))}
    </Box>
  )
}
