import * as React from 'react'

function SvgCarWarranty(props) {
  return (
    <svg width={80} height={80} viewBox="0 0 80 80" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.554 19a17.866 17.866 0 00-1.425-4h14.08a12 12 0 0111.833 10l.58 3.426.138.818C72.056 30.49 76 35.247 76 40.924v1.348c0 5.473-3.664 10.09-8.673 11.532v5.63a5.468 5.468 0 01-10.937 0v-5.162H33v-15.68a18.05 18.05 0 008.168-10.166h21.397l-.467-2.76A8 8 0 0054.21 19H41.554zM66 42a4 4 0 11-8 0 4 4 0 018 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 9.9c0-1.308-1.243-2.265-2.44-1.735C11.93 10.658 8 16.295 8 22.85c0 6.517 3.884 12.126 9.463 14.64a.99.99 0 01.568.909v27.084a6.52 6.52 0 0013.041 0V38.398c0-.637.31-1.254.865-1.567 4.874-2.755 8.163-7.984 8.163-13.982 0-6.597-3.98-12.265-9.67-14.732-1.197-.519-2.43.437-2.43 1.74V21a2 2 0 01-2 2h-4a2 2 0 01-2-2V9.9z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCarWarranty
