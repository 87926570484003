import * as React from 'react'

function SvgArrowDown(props) {
  return (
    <svg width={14} height={9} viewBox="0 0 14 9" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.293.293a1 1 0 011.414 0L7 5.586 12.293.293a1 1 0 111.414 1.414L7 8.414.293 1.707a1 1 0 010-1.414z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgArrowDown
