import { isTestEnvironment } from '@elementinsurance/utils'

const ELEMENT_SET_PURCHASE_FLOW_SESSION_TOKEN_HEADER = 'Element-Set-Purchase-Flow-Session-Token'
const ELEMENT_PURCHASE_FLOW_SESSION_TOKEN_HEADER = 'Element-Purchase-Flow-Session-Token'
export const BASE_URL =
  process.env.REACT_APP_API_HOST || `https://${isTestEnvironment() ? 'sandbox-' : ''}versicherung.jetzt-abschliessen.de`

const requestWrapper = async (method, url, options = {}) => {
  const destinationURL = new URL(url, BASE_URL)

  const sessionToken = sessionStorage.getItem(ELEMENT_PURCHASE_FLOW_SESSION_TOKEN_HEADER)

  const { headers, withSessionToken, body, allowedErrorCodes = [], ...restData } = options

  const requestHeaders = {
    ...headers,
  }

  if (sessionToken && withSessionToken) {
    requestHeaders[ELEMENT_PURCHASE_FLOW_SESSION_TOKEN_HEADER] = sessionToken
  }

  if (body) {
    requestHeaders['Content-Type'] = 'application/json'
  }

  const response = await fetch(destinationURL, {
    method,
    headers: requestHeaders,
    body: JSON.stringify(body), // JSON.stringify(undefined) === undefined
    ...restData,
  })

  const responseHeaders = {}
  for (const entry of response.headers.entries()) {
    responseHeaders[entry[0]?.toLowerCase()] = entry[1]
  }

  const sessionTokenInHeader = responseHeaders[ELEMENT_SET_PURCHASE_FLOW_SESSION_TOKEN_HEADER.toLowerCase()]
  if (sessionTokenInHeader) {
    sessionStorage.setItem(ELEMENT_PURCHASE_FLOW_SESSION_TOKEN_HEADER, sessionTokenInHeader)
  }

  const responseBody = await parseResponseBody(response, responseHeaders)

  const { status } = response
  if (status >= 400 && !allowedErrorCodes.includes(status)) {
    throw new Error(
      `HTTP request ${method} ${url} failed with response code [${status}] and body [${bodyToString(responseBody)}]`
    )
  }

  return {
    data: responseBody,
    status,
    headers: responseHeaders,
  }
}

async function parseResponseBody(response, headers) {
  let type = 'text'
  if (headers['content-type']) {
    if (headers['content-type'].startsWith('application/json')) {
      type = 'json'
    }
  }

  return await response[type]()
}

function bodyToString(body) {
  if (typeof body === 'string') {
    return body
  }
  return JSON.stringify(body)
}

const client = {
  get: (url, options) => requestWrapper('GET', url, options),
  post: (...args) => requestWrapper('POST', ...args),
  head: (url, options) => requestWrapper('HEAD', url, options),
}

export default client
