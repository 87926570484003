import { Box, Label, Tooltip } from '@elementinsurance/uikit'
import cn from 'classnames'
import PropTypes from 'prop-types'
import React, { forwardRef, memo } from 'react'
import styles from './input.module.scss'
import inputBaseStyles from './inputBase.module.scss'

export const colors = ['default', 'success', 'warning', 'danger']

const Input = forwardRef(function Input(
  {
    type = 'text',
    id,
    label,
    color,
    onFocus,
    onBlur,
    onChange,
    wrapperClassName,
    inputContainerClassName,
    className,
    value,
    disabled,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    serverName,
    required,
    tooltip,
    tooltipPosition,
    invalid,
    ...props
  },
  ref
) {
  const inputClassNames = cn(className, styles.input, inputBaseStyles.inputBase, {
    [inputBaseStyles[color]]: !!color,
  })

  const handleBlur = e => {
    if (e.target.value) {
      const trimmedValue = e.target.value.trim()
      if (trimmedValue !== e.target.value) {
        e.target.value = e.target.value.trim()
        onChange(e)
      }
    }

    onBlur && onBlur(e)
  }

  return (
    <Box className={wrapperClassName} direction="column">
      <Box justify="between">
        <Label name={props?.name} htmlFor={id} data-testid={`input-${props?.name}-label`}>
          {label}
        </Label>
        <Tooltip tooltip={tooltip} tooltipPosition={tooltipPosition} name={props.name} />
      </Box>
      <InputContainer className={inputContainerClassName} value={value} invalid={invalid}>
        <input
          data-testid={`input-${props?.name}-input`}
          ref={ref}
          id={id}
          required={required}
          type={type}
          disabled={disabled}
          value={value}
          aria-invalid={invalid}
          {...props}
          onFocus={onFocus}
          onBlur={handleBlur}
          onChange={onChange}
          className={inputClassNames}
        />
      </InputContainer>
    </Box>
  )
})

/**
 * This component highlights borders and adds check mark when field is filled.
 */
export function InputContainer({ className, value, invalid, children }) {
  const isFilled = typeof value === 'string' ? value.trim() !== '' : value != null
  return (
    <div
      aria-invalid={invalid}
      className={cn(inputBaseStyles.inputContainer, className, {
        [inputBaseStyles.inputContainerFilled]: isFilled,
        inputContainerFilled: isFilled,
      })}
    >
      {children}
    </div>
  )
}

Input.propTypes = {
  tooltip: PropTypes.node,
  tooltipPosition: PropTypes.oneOf(['left', 'right', 'bottom']),
  color: PropTypes.oneOf(colors),
}

export default memo(Input)
