import React, { memo } from 'react'
import cn from 'classnames'
import Text from '@elementinsurance/uikit/text'
import styles from './label.module.scss'

const Label = ({ children, name, htmlFor, withAsterisk = true, size = 'xs', className, ...props }) => {
  return children ? (
    <Text
      className={cn(styles.label, 'for-field', className)}
      component="label"
      htmlFor={htmlFor}
      color="body"
      size={size}
      {...props}
    >
      {children}
      {/* not used for now. can be deleted next time */}
      {withAsterisk && (
        <span className="field-asterix" data-testid={`label-${name}-asterix`}>
          *
        </span>
      )}
    </Text>
  ) : null
}

export default memo(Label)
