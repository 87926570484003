export const normalizeProduct = ({
  id,
  version,
  name,
  fields,
  options,
  productVariantMappings,
  ...rest
}: ProductResponse) => {
  const productLine = Object.keys(productVariantMappings)[0]

  return {
    id,
    version,
    name,
    fields: fields.map(({ dependsOn, ...i }) => {
      const required = i.constraints?.some(cons => cons.type === 'required')
      // dependsOn is some default prop, cause to error in console. Renamed
      return { ...i, required, dependson: dependsOn }
    }),
    options,
    productLine,
    productVariantMappings,
    ...rest,
  }
}

export type Product = ReturnType<typeof normalizeProduct>

export type ConstraintConfig = {
  when?: string
  value?: unknown
  potentialValues?: string[]
  possibleValues?: string[]
  rules: FieldConstraint[]
}

export type FieldConstraint = {
  type: string
  config?: ConstraintConfig
}

export type ProductField = Product['fields'][number]

type ProductResponse = {
  id: string
  version: number
  name: string
  productVariantMappings: Record<string, Record<string, string>>
  /**
   * Period, e.g. "P1M"
   */
  cancellationDeadline: string | null
  fields: ProductResponseField[]
  options: { name: string }[]
}

type ProductResponseField = {
  name: string
  type: string
  dependsOn: string[]
  autogenerated: boolean
  constraints: FieldConstraint[]
  formatValueAs?: string
  parseValueAs?: string
}
