import * as React from 'react'

function SvgPrint(props) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M17.219 8.219h-.938a.188.188 0 00-.187.187v.938c0 .103.084.187.187.187h.938a.188.188 0 00.187-.187v-.938a.188.188 0 00-.187-.187zm.75-2.438h-2.813V.813a.188.188 0 00-.187-.188H5.03a.188.188 0 00-.187.188V5.78H2.03c-1.036 0-1.875.84-1.875 1.875v7.688c0 .415.335.75.75.75h3.938v3.093c0 .104.084.188.187.188h9.938a.188.188 0 00.187-.188v-3.093h3.938a.75.75 0 00.75-.75V7.656c0-1.036-.84-1.875-1.875-1.875zM6.437 2.22h7.125V5.78H6.439V2.22zm7.125 15.562H6.439v-6.468h7.125v6.468zM18.25 14.5h-3.094V9.719H4.844V14.5H1.75V7.656c0-.154.127-.281.281-.281H17.97c.154 0 .281.127.281.281V14.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgPrint
