import { useFlowContext } from '@elementinsurance/common/modules/flow/flow.context'
import BannerForTestFlow from '../banner-for-test-flow/BannerForTestFlow'
import { getOverrides } from '@elementinsurance/utils'
import * as defaultComponents from './components'
import { Box } from '@elementinsurance/uikit'
import PropTypes from 'prop-types'

const Header = ({ className, overrides = {}, ...props }) => {
  const { customHeader, logo } = useFlowContext()
  const {
    Root: { component: Root, props: rootProps },
    Logo: { component: Logo, props: logoProps },
    Info: { component: Info, props: infoProps },
  } = getOverrides(defaultComponents, overrides)

  return (
    <>
      <BannerForTestFlow />
      <Root className={className} customHeader={customHeader} {...rootProps} {...props}>
        <Box flex="1" direction="column" justify="center">
          <Logo src={logo} {...logoProps} />
        </Box>
        <Box direction="column" justify="center">
          <Info {...infoProps} />
        </Box>
      </Root>
    </>
  )
}

Header.propTypes = {
  logo: PropTypes.string,
}

export default Header
