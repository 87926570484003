import * as React from 'react'

function SvgCheck(props) {
  return (
    <svg width={15} height={12} viewBox="0 0 15 12" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.745 1.62L13.248.12a.414.414 0 00-.585 0L4.954 7.83l-2.75-2.75a.414.414 0 00-.585 0L.121 6.577a.414.414 0 000 .585l4.54 4.54a.412.412 0 00.586 0l9.498-9.498a.414.414 0 000-.585z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCheck
