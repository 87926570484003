/* eslint-disable react-hooks/exhaustive-deps */
import { getPolicy } from 'packages/common/modules/policy/policy.selectors'
import { getSelectedQuoteId } from 'packages/common/modules/quotes/quotes.selectors'
import { PurchaseFlow } from 'packages/common/modules/flow/flow.normalizers'
import { Product } from 'packages/common/modules/product/product.normalizers'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useStep } from './wizard/useStep'
import { useTranslation } from '@elementinsurance/utils/i18n'
import { isTestEnvironment } from 'packages/utils/environment'
import _camelCase from 'lodash/camelCase'
import i18next from 'i18next'
import { useAnalyticsContext } from 'AnalyticsProvider'

export function useWizardTracking({
  flow,
  product,
  locale = 'de-DE',
}: {
  flow: PurchaseFlow
  locale: string
  product: Product
}) {
  const { id } = product
  const { step } = useStep()
  const { t } = useTranslation()
  const { productMappings, analytics } = useAnalyticsContext() as {
    productMappings: Record<string, string>
    analytics: any
  }

  const analyticsInstance = analytics(id, flow)

  useEffect(() => {
    if (step) {
      const title = i18next.exists('common:field.steps.' + _camelCase(step.id))
        ? { title: t('common:field.steps.' + _camelCase(step.id)) }
        : {}
      analyticsInstance?.track('Pageview', {
        action: undefined,
        ...mapFlowToAnalyticsVariables(flow, product),
        flow_locale: locale,
        flow_step_kind: step.kind,
        ...title,
        url: `/${step.id}`,
        ...productMappings,
      })
    }
  }, [step, locale, flow, t])

  const quoteId = useSelector(getSelectedQuoteId)

  useEffect(() => {
    if (quoteId) {
      analyticsInstance?.track('EventTracking', {
        action: 'selected_quote',
        quote_id: quoteId,
      })
    }
  }, [quoteId])

  const policy = useSelector(getPolicy)

  useEffect(() => {
    if (policy) {
      analyticsInstance?.track('EventTracking', {
        action: 'purchase',
        category: 'policy',
        policy_id: policy.id,
      })
    }
  }, [policy])
}

function mapFlowToAnalyticsVariables(flow: PurchaseFlow, product: Product) {
  return {
    flow_is_testdata: isTestEnvironment() ? true : flow?.state === 'TESTING',
    flow_state: flow?.state,
    flow_audience: flow.audience,
    flow_id: flow.id,
    flow_name: flow.crumb,
    product_name: product.name,
    flow_products: flow.products.map(x => x.id),
  }
}
