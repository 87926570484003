/* eslint-disable @typescript-eslint/no-unused-vars */
export function queryStringify(obj) {
  const paramsWithValues = Object.entries(obj).filter(([_name, value]) => value != null)
  const query = new URLSearchParams(paramsWithValues).toString()
  return query && '?' + query
}

export function queryParse(params) {
  const urlParams = new URLSearchParams(params)
  const entries = urlParams.entries()

  let result = {}

  for (const pair of entries) {
    result = {
      ...result,
      [pair[0]]: pair[1] === 'true' || pair[1] === 'false' ? pair[1] === 'true' : pair[1],
    }
  }

  return result
}
