import { getWizardSteps } from '@elementinsurance/common/modules/wizard/wizard.selectors'
import { ComponentType, useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

export enum StepKind {
  QuoteData = 'QuoteData',
  QuoteSelection = 'QuoteSelection',
  PersonalData = 'PersonalData',
  CustomStep = 'CustomStep',
  Summary = 'Summary',
  Success = 'Success',
}

export type Step = { id: string; component: ComponentType; showCart: boolean; kind: StepKind }

export function useStep() {
  const params = useParams()
  const navigate = useNavigate()

  if (!('productCrumb' in params) && process.env.NODE_ENV !== 'test') {
    throw new Error('This hook can only be called within a ProductPurchaseWizard')
  }

  const stepId = params['*'] || null
  const steps = useSelector(getWizardSteps) as Step[] // TODO: add type definition to the selector

  const stepIndex = useMemo(() => {
    if (!stepId || !steps || steps.length === 0) return null
    const index = steps.findIndex(step => step.id === decodeURIComponent(stepId))
    if (index < 0) {
      return null
    }

    return index
  }, [steps, stepId])

  return {
    step: stepIndex !== null ? steps[stepIndex] : null,
    stepIndex,
    navigateToStepById: useCallback(
      (stepId: string) => {
        const matchUrl = `/${params.flowCrumb}/abschliessen/${params.productCrumb}`
        return navigate(matchUrl + '/' + encodeURIComponent(stepId))
      },
      [navigate, params.flowCrumb, params.productCrumb]
    ),
  }
}
