import { useEffect } from 'react'
import { colorReplacer, useTranslation } from '@elementinsurance/utils'
import { BASE_URL } from '@elementinsurance/client'
import { PurchaseFlow } from '@elementinsurance/common/modules/flow/flow.normalizers'
import { ThirdPartyScript } from '@elementinsurance/common/modules/flow/PurchaseFlowResponse'

export function useFlowSettingsInDocument(flow: PurchaseFlow | undefined) {
  const { t } = useTranslation()
  useEffect(() => {
    if (flow?.id) {
      document.title = flow.pageTitle ?? 'Versicherung abschließen' // TODO: Localizations are not loaded at this moment. t('common:pageTitle')
      loadFavicon(flow)
      loadThirdPartyScripts(flow.thirdPartyScripts ?? [])

      setFontCssVariables(flow)
      if (flow.theme) {
        setCssVariablesFromTheme(flow.theme)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flow?.id, t])
}

function loadFavicon({ hasFavicon, id }: PurchaseFlow) {
  const favicon = document.getElementById('favicon')
  const attr = hasFavicon ? `${BASE_URL}/api/v2/flows/${id}/assets/favicon` : ''

  if (favicon) {
    favicon.setAttribute('href', attr)
  }
}

function setCssVariablesFromTheme({ colors, options }: Exclude<PurchaseFlow['theme'], undefined>) {
  Object.keys(colors).forEach(color => colorReplacer(color, colors))

  const borderRadius = options.borderRadius ? `${options.borderRadius}px` : '0'
  document.documentElement.style.setProperty('--b-border-radius', borderRadius)
}

function setFontCssVariables({ customFontFamily }: PurchaseFlow) {
  const defaultFontFamily = "'Lato', san-serif"
  document.documentElement.style.setProperty('--b-base-font', customFontFamily ?? defaultFontFamily)
}

function loadThirdPartyScripts(scripts: ThirdPartyScript[]) {
  for (const script of scripts) {
    const el = document.createElement('script')
    el.async = true
    el.type = 'text/javascript'
    el.src = script.src
    document.head.appendChild(el)
  }
}
