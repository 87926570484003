import * as React from 'react'

function SvgMakler(props) {
  return (
    <svg width={48} height={48} viewBox="0 0 48 48" fill="none" {...props}>
      <path
        d="M37.44 29.52c.7-1.7 1.08-3.52 1.08-5.52 0-1.44-.22-2.82-.6-4.1-1.3.3-2.66.46-4.08.46A18.14 18.14 0 0119 12.68a18.42 18.42 0 01-9.46 9.76c-.08.5-.08 1.04-.08 1.56A14.54 14.54 0 0024 38.54c2.1 0 4.12-.46 5.94-1.28 1.14 2.18 1.66 3.26 1.62 3.26-3.28 1.1-5.82 1.64-7.56 1.64-4.84 0-9.46-1.9-12.86-5.32a18.06 18.06 0 01-4.48-7.38H4v-9.1h2.18a18.18 18.18 0 0130.66-9.2 17.982 17.982 0 014.94 9.2H44v9.1h-.12L36.76 36l-10.6-1.2v-3.34h9.66l1.62-1.94zm-18.9-5.98c.6 0 1.18.24 1.6.68a2.272 2.272 0 010 3.2c-.42.42-1 .66-1.6.66-1.26 0-2.28-1-2.28-2.26 0-1.26 1.02-2.28 2.28-2.28zm10.9 0c1.26 0 2.26 1.02 2.26 2.28 0 1.26-1 2.26-2.26 2.26-1.26 0-2.28-1-2.28-2.26a2.28 2.28 0 012.28-2.28z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgMakler
