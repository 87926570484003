import client from '@elementinsurance/client'
import { queryStringify } from '@elementinsurance/utils'
import { BackendValidationErrors } from '@elementinsurance/client/errors'
import _toLower from 'lodash/toLower'

export const EXTERNAL_PARAMS_STORAGE_KEY = 'Element-Purchase-Flow-External-Params'

type LoadTranslationsRequestParams = {
  language: string
  namespace: string
  audience: string
  speech: string
  productId: string | undefined
  partnerId: string
  productLine: string | null
}

export const loadTranslationsRequest = async ({
  language,
  namespace,
  audience,
  speech,
  productId,
  partnerId,
  productLine,
}: LoadTranslationsRequestParams) => {
  const { data } = await client.get(
    `/api/v2/texts/${language}/namespace/${namespace}${queryStringify({
      productLine,
      productId,
      partnerId,
      audience,
      speech: _toLower(speech),
    })}`
  )
  return data
}

export const loadProductRequest = async ({ id }: { id: string }) => {
  const { data } = await client.get(`/api/v2/products/${id}`, {
    withSessionToken: true,
  })
  return data
}

export const createQuotesRequest = async ({ body }: { body: unknown }) => {
  const { data } = await client.post('/api/v2/quotes', {
    withSessionToken: true,
    body,
  })
  return data
}

export const validateQuoteRequest = async ({ body }: { body: unknown }) => {
  const response = await client.post('/api/v2/quotes', {
    withSessionToken: true,
    body,
    allowedErrorCodes: [400],
  })

  const responseBody = response.data || {}

  if (response.status === 400) {
    if (responseBody.errors) {
      throw new BackendValidationErrors(responseBody.errors)
    }

    throw new Error(
      'Bad request without errors object when creating quote for validation purposes: ' + responseBody.message
    )
  }

  return responseBody
}

export const createPolicyRequest = async ({
  body,
  withValidation = false,
}: {
  body: unknown
  withValidation: boolean
}) => {
  const url = `/api/v2/customers/policies${withValidation ? '/validation' : ''}`

  console.log(`[createPolicyRequest] ${url} for quoteId ${(body as any).quoteId}`)
  const response = await client.post(url, {
    withSessionToken: true,
    body,
    allowedErrorCodes: [400],
  })

  const responseBody = response.data || {}
  if (response.status === 400) {
    if (responseBody.errors) {
      throw new BackendValidationErrors(responseBody.errors)
    }
    throw new Error('Bad request without errors object when validating policy fields: ' + responseBody.message)
  }

  return responseBody
}

export const createCustomerRequest = async ({
  body,
  withValidation = false,
  testData = false,
}: {
  body: Record<string, unknown>
  withValidation: boolean
  testData: boolean
}) => {
  const url = `customers${withValidation ? `/validation${testData ? '?test=true' : ''}` : ''}`

  const externalParamsFromUrl = getExternalParamsFromEntrypointUrl()
  const response = await client.post(`/api/v2/${url}`, {
    withSessionToken: true,
    body: {
      ...body, // may also contain external params coming from an editable field
      subpartnerId: body.subpartnerId ?? externalParamsFromUrl.subpartnerId,
      externalId: body?.externalId || externalParamsFromUrl.externalCustomerId,
    },
    allowedErrorCodes: [400, 409],
  })

  const responseBody = response.data || {}
  if (response.status === 400 || response.status === 409) {
    if (responseBody.errors) {
      throw new BackendValidationErrors(responseBody.errors)
    }

    throw new Error('Bad request without errors object when validating customer: ' + responseBody.message)
  }

  return responseBody
}

type ExternalParams = { subpartnerId: string | null; externalCustomerId: string | null }

export function getExternalParamsFromEntrypointUrl(): ExternalParams {
  const item = sessionStorage.getItem(EXTERNAL_PARAMS_STORAGE_KEY)
  return (item && JSON.parse(item)) || {}
}

export function storeExternalParamsFromUrl() {
  const urlParams = new URLSearchParams(window.location.search)
  const prevExternalParams = getExternalParamsFromEntrypointUrl()

  const externalParams: ExternalParams = {
    subpartnerId: urlParams.get('subpartnerId') || prevExternalParams.subpartnerId,
    externalCustomerId: urlParams.get('externalCustomerId') || prevExternalParams.externalCustomerId,
  }

  sessionStorage.setItem(EXTERNAL_PARAMS_STORAGE_KEY, JSON.stringify(externalParams))
}
