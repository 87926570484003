import { createReducer } from '@elementinsurance/store'
import { TOGGLE_LOADER } from './app.constants'

export const initialState = {
  loading: false,
}

export default createReducer(initialState, {
  [TOGGLE_LOADER]: (state, { payload }) => ({
    ...state,
    loading: payload,
  }),
})
