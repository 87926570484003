import * as React from 'react'

function SvgAccident(props) {
  return (
    <svg width={80} height={80} viewBox="0 0 80 80" fill="none" {...props}>
      <path
        d="M32.473 57.96v2.044l5.569-1.146-5.569-.898zM39.292 55.917v-1.149l-4.527.419 4.527.73zM32.473 63.124v.63a3.41 3.41 0 006.819 0V61.77c-7.084 1.457-6.547 1.366-6.819 1.355zM40.028 15.777a5.888 5.888 0 100-11.777 5.888 5.888 0 000 11.777zM38.435 38.484c.624 1.829 2.7 2.224 3.607 1.77l-1.837-5.377a2.843 2.843 0 00-1.77 3.607z"
        fill="currentColor"
      />
      <path
        d="M54.441 33.91l-.026-5.157-.022-4.41c-.018-3.688-3.035-6.69-6.723-6.69H32.387c-3.689 0-6.705 3.002-6.724 6.69l-.104 20.873a2.839 2.839 0 002.671 2.847v26.385a1.552 1.552 0 003.103 0l.013-50.076a.594.594 0 011.187.033l-.06 27.878c7.182-.664 6.663-.622 6.819-.618v-6.026c0-.419.34-.758.758-.758a.74.74 0 01.74.743c-.017 4.413-.026 26.967-.026 26.967a3.41 3.41 0 106.819 0V41.64l-4.539 1.55a5.944 5.944 0 11-3.841-11.25l9.53-3.254.013 2.587 2.067 5.985 1.705-.582.002-.001c1.099-.376 1.921-1.405 1.921-2.766z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgAccident
