import React from 'react'
import cn from 'classnames'
import { isTestEnvironment, useTranslation } from '@elementinsurance/utils'
import { Box, Button } from '@elementinsurance/uikit'

import styles from './desktopButtonBar.module.scss'

const DesktopButtonBar = ({
  prevSize = 'xs',
  nextSize = 'm',
  className,
  onPrevious,
  nextLabel,
  disabled,
  onNext,
  fillFormButton: FillFormButton,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <Box className={cn(styles.wrapper, className, 'desktopButtonBar')} justify="between" {...props}>
      {onPrevious ? (
        <Button data-testid="button-back" onClick={onPrevious} color="primary" size={prevSize} type="button">
          {t('common:back')}
        </Button>
      ) : (
        <Box />
      )}
      <Box className={styles.actionButtons} justify="around">
        {isTestEnvironment() ? <FillFormButton /> : <div />}
        {onNext && (
          <Button
            data-testid="button-next"
            disabled={disabled}
            onClick={onNext}
            color="primary"
            size={nextSize}
            type="button"
          >
            {nextLabel || t('common:next')}
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default DesktopButtonBar
