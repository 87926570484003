import * as React from 'react'

function SvgCarRental(props) {
  return (
    <svg width={80} height={80} viewBox="0 0 80 80" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64 26c5.523 0 10-4.477 10-10S69.523 6 64 6s-10 4.477-10 10 4.477 10 10 10zm0 2c6.627 0 12-5.373 12-12S70.627 4 64 4 52 9.373 52 16s5.373 12 12 12z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.733 12.858a1 1 0 011.409-.125l3.561 2.98 2.43-1.9a1 1 0 111.233 1.575l-2.94 2.3a1.2 1.2 0 01-1.51-.025l.642-.767-.642.767-4.058-3.396a1 1 0 01-.125-1.409zM66.183 29.83l.438 2.596.139.818C72.056 34.49 76 39.247 76 44.924v1.348c0 5.473-3.664 10.09-8.673 11.532v5.63a5.468 5.468 0 01-10.937 0v-5.162H22.228v5.161a5.468 5.468 0 01-10.937 0v-6.12C7.004 55.481 4 51.227 4 46.272v-1.347c0-5.978 4.371-10.935 10.092-11.85l.11-.649.58-3.427A12 12 0 0126.612 19h23.71c.31 1.427.84 2.772 1.55 4h-25.26a8 8 0 00-7.888 6.666l-.466 2.76h44.306l-.431-2.55a14.136 14.136 0 004.049-.045zM66 46a4 4 0 11-8 0 4 4 0 018 0zm-49 4a4 4 0 100-8 4 4 0 000 8z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCarRental
