import * as React from 'react'

function SvgPerson(props) {
  return (
    <svg width={37} height={36} viewBox="0 0 37 36" fill="none" {...props}>
      <g clipPath="url(#person_svg__clip0)" fill="currentColor">
        <path d="M30.428 27.832C28.83 25.26 25.314 22.5 18.125 22.5c-7.189 0-10.703 2.756-12.303 5.332a15.722 15.722 0 0012.303 5.918 15.72 15.72 0 0012.303-5.918z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M18.125 20.25a6.75 6.75 0 100-13.5 6.75 6.75 0 000 13.5z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.125 2.25a15.75 15.75 0 100 31.5 15.75 15.75 0 000-31.5zM.125 18a18 18 0 1136 0 18 18 0 01-36 0z"
        />
      </g>
      <defs>
        <clipPath id="person_svg__clip0">
          <path fill="#fff" transform="translate(.125)" d="M0 0h36v36H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgPerson
