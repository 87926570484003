import React from 'react'
import PropTypes from 'prop-types'
import { Image } from '@elementinsurance/uikit'

import styles from './logo.module.scss'

const Logo = ({ children, className, src, onClick, name, ...props }) => {
  return (
    <div className={className}>
      {src && (
        <Image data-testid="header-logo" className={styles.image} src={src} alt={name} onClick={onClick} {...props} />
      )}
      {children}
    </div>
  )
}

export const logoProps = {
  className: PropTypes.string,
  src: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
}

Logo.propTypes = logoProps

export default Logo
