import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Info } from '@elementinsurance/uikit/icons/svgr/s'

import styles from './icon.module.scss'

const Icon = ({ className, color, onClick, ...props }) => {
  return (
    <button type="button" onClick={onClick} className={styles.imageWrapper} {...props}>
      <Info
        className={cn(styles.image, className, {
          [styles[color]]: !!color,
        })}
      />
    </button>
  )
}

Icon.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'danger']),
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default Icon
