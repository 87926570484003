import { createReducer } from '@elementinsurance/store'
import { normalizeQuotesData } from './quotes.normalizers'
import { SET_SELECTED_QUOTE_ID, QUOTES_CREATED, SET_QUOTES_OPTIONS } from './quotes.constants'

export const initialState = {}

export default createReducer(initialState, {
  [SET_SELECTED_QUOTE_ID]: (state, { payload }) => ({
    ...state,
    selectedQuoteId: payload,
  }),
  [SET_QUOTES_OPTIONS]: handleQuoteOptions,
  [QUOTES_CREATED]: handleQuotes,
})

function handleQuoteOptions(state, { payload }) {
  return payload
    ? {
        ...state,
        options: {
          ...(state?.options ? state?.options : {}),
          ...payload,
        },
      }
    : state
}

function handleQuotes(state, { payload }) {
  return payload
    ? {
        ...state,
        ...normalizeQuotesData(payload),
      }
    : state
}
