import React from 'react'
import PropTypes from 'prop-types'
import ReactRadio from 'rc-checkbox'

import './radio.module.scss'

const Radio = ({ ...props }) => {
  return <ReactRadio type="radio" prefixCls="element-in-radio" {...props} />
}

Radio.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
}

export default Radio
