import * as colors from 'color'

const colorReplacer = (name, boColors) => {
  const root = document.documentElement

  const brandHandler = type => color => {
    const clr = colors(color.trim())
    const formActive = clr.lighten(0.1)
    const buttonHover = clr.darken(0.5)

    root.style.setProperty(`--b-color-brand-${type}`, color)
    root.style.setProperty(`--b-color-btn-${type}-hov`, buttonHover.rgb())
    root.style.setProperty(`--b-color-form-${type}-active`, formActive.rgb())
  }

  const handlers = {
    primary: brandHandler('primary'),
    secondary: brandHandler('secondary'),
    primaryShaded: color => {
      root.style.setProperty('--b-color-brand-primary-shaded', color)
      root.style.setProperty('--b-color-form-filled-primary', color)
    },
    secondaryShaded: color => {
      root.style.setProperty('--b-color-brand-secondary-shaded', color)
    },
    footer: color => {
      const clr = colors(color.trim())
      const textColor = clr.isDark() ? '#fff' : boColors['textPrimary']

      root.style.setProperty('--b-color-brand-footer', color)
      root.style.setProperty('--b-color-text-footer', textColor)
    },
    textHighlight: color => {
      root.style.setProperty('--b-color-text-highlight', color)
    },
    textPrimary: color => {
      root.style.setProperty('--b-color-text-primary', color)
    },
    textSecondary: color => {
      root.style.setProperty('--b-color-text-secondary', color)
    },
  }

  const handler = handlers[name]

  handler && handler(boColors[name])
}

export default colorReplacer

export { colors }
