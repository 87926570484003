import * as React from 'react'

function SvgCarDeductible(props) {
  return (
    <svg width={80} height={80} viewBox="0 0 80 80" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.101 34.65a28.535 28.535 0 005.019 2.214 2.667 2.667 0 001.566.036c1.966-.55 7.16-2.311 10.346-6.255H63.31l-.44-2.599a7.55 7.55 0 00-7.45-6.279H30.654V18H55.42c5.53 0 10.253 3.98 11.175 9.418l.547 3.227.13.77C72.277 32.59 76 37.07 76 42.417v1.269c0 5.155-3.46 9.504-8.191 10.862v5.302c0 2.844-2.312 5.15-5.165 5.15a5.157 5.157 0 01-5.164-5.15v-4.862H25.215v4.862A5.157 5.157 0 0120.051 65c-2.853 0-5.165-2.306-5.165-5.15v-5.765C10.837 52.36 8 48.354 8 43.685v-1.268c0-3.008 1.179-5.742 3.101-7.768zm51.677 12.547a3.773 3.773 0 003.778-3.767 3.773 3.773 0 00-3.778-3.767A3.773 3.773 0 0059 43.43a3.773 3.773 0 003.778 3.767zM24.056 43.43a3.773 3.773 0 01-3.778 3.767A3.773 3.773 0 0116.5 43.43a3.773 3.773 0 013.778-3.767 3.773 3.773 0 013.778 3.767z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.217 34.88C13.673 34.048 4.86 30.607 4.86 22.96V11.68c0-1.276.803-2.41 2.024-2.78C9.381 8.144 13.688 7 16.898 7c3.209 0 7.516 1.144 10.013 1.9 1.221.371 2.024 1.504 2.024 2.78v11.28c0 8.182-8.791 11.237-11.348 11.952a2.335 2.335 0 01-1.37-.031zm6.942-17.092a1.003 1.003 0 10-1.411-1.426l-6.542 6.47-2.22-2.196a1.003 1.003 0 00-1.412 1.427l3.632 3.592 7.953-7.867z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCarDeductible
