import React from 'react'
import PropTypes from 'prop-types'
import { Box, Text, ModalLink } from '@elementinsurance/uikit'
import { useTranslation, formatMessage } from '@elementinsurance/utils'

import _camelCase from 'lodash/camelCase'

import styles from './menuItem.module.scss'

const MenuItem = ({ children, name, href, modalContent, contentLinkReplacer = null, className, ...props }) => {
  const { t } = useTranslation()
  return (
    <Box component="li" bottom="xxs" className={className} {...props}>
      {href && (
        <Text
          data-testid={`footer-link-${name}`}
          className={styles.link}
          component="a"
          target="_blank"
          href={href}
          color="footer"
        >
          {t(`common:footer.menu.link.${_camelCase(name)}`)}
        </Text>
      )}

      {modalContent && (
        <ModalLink
          linkText={t(`common:footer.menu.link.${_camelCase(name)}`)}
          linkProps={{ color: 'footer', 'data-testid': `footer-link-${name}` }}
          showClose
        >
          {() => formatMessage(t(modalContent), { linkReplacer: contentLinkReplacer })}
        </ModalLink>
      )}

      {children}
    </Box>
  )
}

export const menuItemProps = {
  className: PropTypes.string,
  prefix: PropTypes.string,
}

MenuItem.propTypes = menuItemProps

export default MenuItem
