import { Box, Text } from '@elementinsurance/uikit'
import { colors } from '@elementinsurance/uikit/text'
import i18next from 'i18next'
import { I18nextProvider, initReactI18next, Trans, useTranslation as useT, withTranslation } from 'react-i18next'
import ReactMarkdown, { uriTransformer } from 'react-markdown'

export const getI18nConfig = ({
  languages,
  language = 'de-DE',
  defaultNS = 'common',
}: {
  languages: Record<string, any>
  language?: string
  defaultNS?: string
}) => {
  i18next.use(initReactI18next).init({
    debug: process.env.NODE_ENV !== 'production',
    resources: languages,
    load: 'all',
    keySeparator: false,
    fallbackLng: 'de-DE',
    lng: language,
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        if (value == null) {
          return value
        }

        if (format === 'possessive') {
          return value + (value.endsWith('s') ? "'" : 's')
        }
        return value
      },
    },
    defaultNS,
    saveMissing: true,
    missingKeyHandler: (lng, namespace, key) => {
      const message = `Missing i18n key ${namespace}:${key}`

      console.error(message)
      if (window.Cypress || process.env.NODE_ENV === 'development') {
        throw new Error(message)
      }
    },
  })

  return i18next
}

export const I18nProvider = ({ i18n, children }: any) => {
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
}

export const getMessage = (key: string, variables: Record<string, unknown>): string => {
  if (i18next.exists(key, variables)) {
    return i18next.t(key, variables)
  }
  return 'TRANSLATION_NOT_FOUND'
}

export const getMessageOrFallback = (...args: any[]): string => {
  const key = args[0]
  const lastArg = args[args.length - 1]
  const hasVariables = typeof lastArg !== 'string'
  const variables = hasVariables ? lastArg : {}

  if (i18next.exists(key, variables)) {
    return i18next.t(key, variables)
  }

  const minRequiredArgumentsToContinue = hasVariables ? 2 : 1
  const newArgs = args.slice(1)
  if (newArgs.length < minRequiredArgumentsToContinue) {
    return key
  }

  return getMessageOrFallback(...newArgs)
}

const useTranslation: typeof useT = (...args) => {
  const defaultTranslationObj = useT(...args)

  function prefixCurryingFunc(prefix: string) {
    return {
      ...defaultTranslationObj,
      t: (value = '') => {
        throw new Error(
          `currying for useTranslation deprecated. Should be clear useTranslation function ${prefix}.${value.toUpperCase()}`
        )
      },
    }
  }

  return {
    ...prefixCurryingFunc,
    ...defaultTranslationObj,
  }
}

export { useTranslation, withTranslation, Trans }

export const formatMessage = (message: string, { linkReplacer }: { linkReplacer?: (value: string) => string } = {}) => {
  let transformLinkUri
  if (linkReplacer) {
    transformLinkUri = (uri: string) => linkReplacer(uri) ?? uriTransformer(uri)
  }

  return <ReactMarkdown source={message} transformLinkUri={transformLinkUri} />
}

export function TransRichText({
  i18nKey,
  values = {},
  color,
}: {
  i18nKey: string
  values?: Record<string, any>
  color?: string
}) {
  return (
    <Trans
      i18nKey={i18nKey}
      values={values}
      components={{
        italic: <i />,
        bold: <strong />,
        div: <div />,
        Box: <Box />,
        // @ts-ignore
        Text: <Text color={color || getColorFrom18n(i18nKey, values)} />,
        p: <p />,
        ul: <ul />,
        span: <span />,
      }}
    />
  )
}

function getColorFrom18n(i18nKey: string, values: Record<string, any>) {
  const raw = getMessage(i18nKey, values)
  if (raw.includes('color')) {
    return colors.find((c: string) => raw.includes(`color='${c}'`) || raw.includes(`color="${c}"`))
  }
}

export default i18next

export const getLocaleDateStrings = (locale = 'de') => {
  const months = []
  const weekdaysLong = []
  const weekdaysShort = []

  for (let i = 0; i <= 11; i++) {
    months[i] = new Date(1970, i, 1).toLocaleString(locale, {
      month: 'long',
    })
  }

  for (let i = 0; i <= 6; i++) {
    weekdaysShort[i] = new Date(1970, 0, i + 4).toLocaleString(locale, {
      weekday: 'short',
    })

    weekdaysLong[i] = new Date(1970, 0, i + 4).toLocaleString(locale, {
      weekday: 'long',
    })
  }

  return {
    months,
    weekdaysLong,
    weekdaysShort,
  }
}
