export function isTestEnvironment() {
  const { hostname } = window.location
  return (
    hostname.includes('sandbox') ||
    hostname.includes('localhost') ||
    hostname.includes('purchase-flow.web.app') ||
    isPreviewEnvironment()
  )
}

export function isPreviewEnvironment() {
  const { hostname } = window.location
  return hostname.includes('purchase-flow.netlify.app')
}
