import React from 'react'
import { Check, Cross } from '../icons/svgr/m'

import styles from './Checkmark.module.scss'

const Checkmark = ({ value }) => {
  return value ? (
    <Check data-testid="check" className={styles.check} />
  ) : (
    <Cross data-testid="cross" className={styles.cross} />
  )
}

export default Checkmark
