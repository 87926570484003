import React from 'react'
import cn from 'classnames'

import styles from './row.module.scss'

const Row = ({ className, children, ...rest }) => {
  return (
    <tr className={cn(styles.row, className)} {...rest}>
      {children}
    </tr>
  )
}

export default Row
