import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Box from '@elementinsurance/uikit/box'
import _omit from 'lodash/omit'

import styles from './button.module.scss'

export const sizes = ['xs', 's', 'm', 'l', 'xl', 'xxxl']
export const colors = ['primary', 'secondary', 'danger', 'success', 'warning', 'info']

const Button = ({
  top,
  left,
  right,
  bottom,
  className,
  contentClass,
  color,
  size,
  component: Component = 'button',
  loading,
  children,
  iconLeft,
  iconTop,
  iconRight,
  fullWidth,
  width,
  disabled,
  noWrap,
  compact,
  iconOnly,
  ...props
}) => {
  const type = Component === 'button' ? 'button' : null
  const isDisabled = loading || disabled

  const classes = cn(styles.button, styles[color], className, {
    [styles[size]]: !!size,
    [styles['disabled']]: !!isDisabled,
    [styles['vertical']]: !!iconTop,
    [styles['fullWidth']]: !!fullWidth,
    [styles[`width-${width}`]]: !!width,
    [styles['noWrap']]: !!noWrap,
    [styles['compact']]: !!compact,
    [styles['iconOnly']]: !!iconOnly,
  })

  const inlineStyles = {
    marginTop: top ? `var(--b-size-${top})` : '',
    marginBottom: bottom ? `var(--b-size-${bottom})` : '',
    marginLeft: left ? `var(--b-size-${left})` : '',
    marginRight: right ? `var(--b-size-${right})` : '',
  }

  const iconBefore = iconLeft || iconTop

  return (
    <Component
      className={classes}
      style={inlineStyles}
      type={type}
      disabled={isDisabled}
      {..._omit(props, 'highlighted')}
    >
      {iconBefore && (
        <Box
          component="span"
          className={cn({
            [iconLeft]: iconLeft,
            [iconTop]: iconTop,
          })}
        >
          {iconBefore}
        </Box>
      )}
      {children && (
        <Box component="span" className={cn('button-content', contentClass)}>
          {children}
        </Box>
      )}
      {iconRight && !iconTop && (
        <Box component="span" className="button-iconRight">
          {iconRight}
        </Box>
      )}
    </Component>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  contentClass: PropTypes.string,
  top: PropTypes.oneOf(sizes),
  bottom: PropTypes.oneOf(sizes),
  left: PropTypes.oneOf(sizes),
  right: PropTypes.oneOf(sizes),
  color: PropTypes.oneOf(colors),
  size: PropTypes.oneOf(sizes),
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
  loading: PropTypes.bool,
  iconLeft: PropTypes.element,
  iconTop: PropTypes.element,
  iconRight: PropTypes.element,
  fullWidth: PropTypes.bool,
  width: PropTypes.oneOf(sizes),
  disabled: PropTypes.bool,
  noWrap: PropTypes.bool,
  compact: PropTypes.bool,
  iconOnly: PropTypes.bool,
}

export default Button
