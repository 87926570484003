import * as React from 'react'

function SvgClock(props) {
  return (
    <svg width={49} height={48} viewBox="0 0 49 48" fill="none" {...props}>
      <path
        d="M24.5 3c-11.597 0-21 9.403-21 21s9.403 21 21 21 21-9.403 21-21-9.403-21-21-21zm0 38.438c-9.628 0-17.438-7.81-17.438-17.438S14.872 6.562 24.5 6.562 41.938 14.372 41.938 24 34.127 41.438 24.5 41.438z"
        fill="currentColor"
      />
      <path
        d="M32.689 29.934l-6.684-4.832V13.5a.376.376 0 00-.375-.375h-2.255A.376.376 0 0023 13.5v12.91a.37.37 0 00.155.304l7.753 5.653a.379.379 0 00.525-.08l1.34-1.828a.374.374 0 00-.084-.525z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgClock
