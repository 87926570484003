import * as React from 'react'

function SvgPayment(props) {
  return (
    <svg width={40} height={40} viewBox="0 0 40 40" fill="none" {...props}>
      <path
        d="M2.5 29.375a4.375 4.375 0 004.375 4.375h26.25a4.375 4.375 0 004.375-4.375V17.344h-35v12.031zm5.156-5.938A2.344 2.344 0 0110 21.095h3.75a2.344 2.344 0 012.344 2.343V25a2.344 2.344 0 01-2.344 2.344H10A2.344 2.344 0 017.656 25v-1.563zM33.125 6.25H6.875A4.375 4.375 0 002.5 10.625v2.031h35v-2.031a4.375 4.375 0 00-4.375-4.375z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgPayment
