/* eslint-disable no-param-reassign */
import { Duration, DateTime } from 'luxon'
import { getMessageOrFallback, getMessage, i18n } from '@elementinsurance/utils'

export const germanDateFormat = 'dd.LL.yyyy'

/**
 * formats the given Date object to a day representation
 * so something like 31.12.2014 or 12/19/2012 depending on the browser locale
 * @param {Date} date
 * @returns {string}
 */
export const formatDate = (date, options = {}) => {
  const defaultOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }

  if (date.constructor === DateTime) {
    date = date.toJSDate()
  }

  return new Intl.DateTimeFormat([], {
    ...defaultOptions,
    ...options,
  }).format(date)
}

/**
 * formats the given Date object to a day+time representation
 * so something like 31.12.2014 18:22:32 or 12/19/2012 06:22:32 PM depending on the browser locale
 * @param {Date} date
 * @returns {string}
 */
export const formatDateTime = (date, options = {}) => {
  const defaultOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }

  if (date.constructor === DateTime) {
    date = date.toJSDate()
  }

  return new Intl.DateTimeFormat([], {
    ...defaultOptions,
    ...options,
  }).format(date)
}

export const nowInBerlin = () => {
  return DateTime.local().setZone('Europe/Berlin')
}

export const parseISOAsBerlin = isoDate => {
  return DateTime.fromISO(isoDate, {
    zone: 'Europe/Berlin',
  })
}

/**
 * returns something like '1982-05-25'
 */
export const toISODateString = date => DateTime.fromISO(date).toISODate()

export const formatDateTo = (date, format = germanDateFormat) =>
  date ? DateTime.fromISO(date, { zone: 'Europe/Berlin' }).toFormat(format) : '-'

export const formatFromBerlinISO = date =>
  DateTime.fromISO(toISODateString(date), {
    zone: 'Europe/Berlin',
  })

export const formatToBerlinISO = date => formatFromBerlinISO(date).toUTC().toISO()

export const formatDay = (value = '') => {
  if (value.length > 2) {
    value = value.slice(value.length - 2)
  }

  const number = Number(value)
  if (number < 10 && number > 0) value = '0' + number
  if (number > 31) value = DateTime.local().get('day')

  return value || 0
}

export const formatMonth = (value = '') => {
  if (value.length > 2) {
    value = value.slice(1)
  }

  const number = Number(value)
  if (number < 10 && number > 0) value = '0' + number
  if (number > 12) value = DateTime.local().get('month')

  return value || 0
}

export const formatYear = (value = '') => {
  if (value.length > 4) {
    value = value.slice(value.length - 4)
  }

  const number = Number(value)
  if (number > 0) {
    if (number < 10) {
      value = '000' + number
    } else if (number < 100) {
      value = '00' + number
    } else if (number < 1000) {
      value = '0' + number
    }
    if (number > 2100) value = DateTime.local().get('year')
  }

  return value || 0
}

export const getAge = (date, current = DateTime.local()) => {
  const birthDate = DateTime.fromISO(date)
  const { years } = current.diff(birthDate, 'years').toObject()
  return Math.floor(years)
}

export const parseISO8601ToDate = (duration, dependent) => {
  // E.g. period 1 year 3 months 2 days:  P1Y3M2D
  // E.g. period -1 year 3 months -2 days:  P-1Y3M-2D
  const date = dependent || nowInBerlin()
  const durationValues = Duration.fromISO(duration).values
  return DateTime.fromISO(date).plus(durationValues).startOf('day')
}

export const getISO8601ErrorMessage = (type = 'default', prefix, params = {}) => {
  const prfx = `${prefix}.${params.name}.validation`
  const res = {
    P1Y: getMessage('common:validation.field.date.notAfterYear', { count: 1 }),
    P1D: getMessage('common:validation.field.date.notTodayOrBefore'),
    default: i18n.exists(prfx)
      ? getMessage(prfx, params)
      : getMessageOrFallback(
          `${prefix}.validation.field.date_${type}`,
          'common:validation.field.date',
          'common:validation.field.invalid'
        ),
  }

  return res[type] || res.default
}

export const getTestDateValue = (notBefore, notAfter) => {
  if (notBefore) return toISODateString(notBefore.value.plus({ days: 1 }))
  if (notAfter) return toISODateString(notAfter.value.minus({ days: 1 }))
  return toISODateString(nowInBerlin())
}

export const getMaxNotAfterDate = (field, dependencies = {}, prefix, params = {}) => {
  const maxDate = field?.constraints?.find(c => c.type === 'maxDate' || c.type === 'maxDatetime')?.config.max
  if (maxDate?.duration) {
    return {
      value: parseISO8601ToDate(maxDate.duration, dependencies[maxDate.relativeTo]),
      message: getISO8601ErrorMessage(maxDate.duration, prefix),
    }
  }
  if (maxDate?.relativeTo === 'now') {
    const now = nowInBerlin()
    let value = maxDate.modifier ? now[maxDate.modifier]({ years: maxDate.years || 0 }) : now
    if (maxDate.relativeToTime === 'endOfDay') {
      value = value.endOf('day')
    }

    return {
      message: getISO8601ErrorMessage(maxDate.duration, prefix, params),
      value,
    }
  }
  const relativeTo = dependencies[maxDate?.relativeTo]
  if (relativeTo) {
    let value = DateTime.fromISO(relativeTo, { zone: 'Europe/Berlin' })
    value = maxDate.modifier ? value[maxDate.modifier]({ years: maxDate.years || 0 }) : relativeTo
    return {
      message: getMessage('common:validation.field.invalid'),
      value,
    }
  }
}

export const getMinNotBeforeDate = (field, dependencies = {}, prefix, params = {}) => {
  const minDate = field?.constraints?.find(c => c.type === 'minDate' || c.type === 'minDatetime')?.config.min
  if (minDate?.duration) {
    return {
      value: parseISO8601ToDate(minDate.duration, dependencies[minDate.relativeTo]),
      message: getISO8601ErrorMessage(minDate.duration, prefix, params),
    }
  }
  if (minDate?.relativeTo === 'now') {
    const now = nowInBerlin()
    let value = minDate.modifier ? now[minDate.modifier]({ days: minDate.days || 0 }) : now
    if (minDate.relativeToTime === 'startOfDay') {
      value = value.startOf('day')
    }

    return {
      message: getMessage('common:validation.field.invalid'),
      value,
    }
  }
}

export const getTimeDiff = (time, current = nowInBerlin()) => {
  const { minutes } = current.diff(time, ['minutes']).toObject()
  return Math.floor(minutes)
}

export const getDaysDiff = (date, current = nowInBerlin(), format) => {
  const result = current.diff(date, ['days'])

  const formats = {
    ISO: result.toISO(),
  }

  if (format) {
    return formats[format]
  }

  return Math.floor(result.toObject().days)
}

export function notBeforeTomorrow() {
  const now = nowInBerlin()
  return now.plus({ days: 1 }).startOf('day')
}

export function todayPlusYear(year) {
  const now = nowInBerlin()
  return now.plus({ year }).startOf('day')
}
