import { getWizardValues } from '@elementinsurance/common/modules/wizard/wizard.selectors'
import { useProductContext } from '@elementinsurance/common/modules/product/product.context'
import { createContext, useContext } from 'react'
import { TransRichText, i18n } from '@elementinsurance/utils'
import { Text } from '@elementinsurance/uikit'
import { useSelector } from 'react-redux'

export const ProductLineContext = createContext({ createInfo: null, productLinePrefix: null })

export const useProductLineInfo = () => {
  const { createInfo } = useContext(ProductLineContext)
  const product = useProductContext()
  if (!createInfo) {
    throw new Error('ProductLineContext must have a value')
  }
  const wizardValues = useSelector(getWizardValues)
  if (!wizardValues) {
    throw new Error('wizardValues must be provided')
  }
  return createInfo({ wizardValues, product })
}

export const useProductLineContext = () => useContext(ProductLineContext)

export function generateFeaturesFromI18n({ namespace, variants, variables, customExplanation }) {
  const res = []

  for (let i = 0, dynamicLength = 1; i < dynamicLength; i++) {
    const row = (i + 1).toString().padStart(2, '0')
    const variations = {}

    const descriptionKey = [`${namespace}:feature.${row}.description`, variables]
    const tooltipKey = [`${namespace}:feature.${row}.tooltip`, variables]

    if (i18n.exists(...descriptionKey) || i18n.exists(...tooltipKey)) {
      variants.forEach(variant => {
        const variantKey = [`${namespace}:feature.${row}.variantValue.${variant}`, variables]
        const value = i18n.exists(...variantKey) ? i18n.t(...variantKey) : null
        variations[variant] = parseFeatureVariantValue(value)
      })

      res.push({
        explanation: i18n.exists(...tooltipKey) ? (
          customExplanation ? (
            <Text color="secondary">
              <TransRichText color="secondary" i18nKey={`${namespace}:feature.${row}.tooltip`} values={variables} />
            </Text>
          ) : (
            i18n.t(...tooltipKey)
          )
        ) : null,
        name: i18n.exists(...descriptionKey) ? i18n.t(...descriptionKey) : null,
        variations,
      })

      ++dynamicLength
    }
  }

  return res
}

function parseFeatureVariantValue(value) {
  switch (value) {
    case ':check:':
      return true
    case ':cross:':
      return false
    default:
      return value
  }
}
