export const supportedProductLines = {
  'cyber-private-single-contract-renewable': true,
  'dog-passenger-surgery-insurance': true,
  'disability-subsistence-standalone': true,
  'real-estate-warranty-insurance': true,
  'car-warranty-one-time-b2c': true,
  'private-liability-v2': true,
  'private-liability': true,
  'car-third-party': true,
  'car-test-drive': true,
  'car-deductible': true,
  'dog-liability': true,
  'home-content': true,
  'home-content-v2': true,
  'accident-v2': true,
  'pet-health': true,
  'pet-health-offer': true,
  'car-rental': true,
  'bike-theft-damage': true,
  'bike-assistance': true,
  accident: true,
}

export const isProductSupported = product => {
  return product.productLineNames.some(pl => supportedProductLines[pl])
}
