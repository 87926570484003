import React from 'react'
import cn from 'classnames'
import { isTestEnvironment, useTranslation } from '@elementinsurance/utils'
import { Box, Button } from '@elementinsurance/uikit'

import styles from './mobileButtonBar.module.scss'

const MobileButtonBar = ({
  prevSize = 'xs',
  className,
  onPrevious,
  nextLabel,
  disabled,
  onNext,
  fillFormButton: FillFormButton,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <Box className={cn(styles.wrapper, className)} direction="column" justify="center" align="center" {...props}>
      {onNext && (
        <Button
          data-testid="button-next"
          disabled={disabled}
          onClick={onNext}
          color="primary"
          fullWidth
          bottom="xs"
          type="button"
        >
          {nextLabel || t('common:next')}
        </Button>
      )}
      <Box fullWidth justify="center" align="center">
        {onPrevious ? (
          <Button data-testid="button-back" onClick={onPrevious} size={prevSize} type="button">
            {t('common:back')}
          </Button>
        ) : (
          <Box />
        )}
        {isTestEnvironment() && <FillFormButton />}
      </Box>
    </Box>
  )
}

export default MobileButtonBar
