import React, { Suspense, useEffect, useState, memo } from 'react'
import { useFrequencyVariants, useProduct } from '@elementinsurance/common/modules/product/product.loaders'
import { PRODUCT_LINE_VALUE } from '@elementinsurance/common/modules/policy/policy.constants'
import { ProductContext } from '@elementinsurance/common/modules/product/product.context'
import { useLoadTranslations } from '@elementinsurance/common/modules/app/app.loaders'
import DynamicLoader from '@elementinsurance/common/components/dynamic-loader'
import { ProductLineContext } from './product-line-context'
import { useWizardTracking } from '../useWizardTracking'
import { useAnalyticsContext } from 'AnalyticsProvider'
import { useDispatch } from 'react-redux'

import BavariaDirectDynamicYield from './BavariaDirectDynamicYield'

function ProductPurchaseWizard({ routerProductCrumb, flow }) {
  const { data: product } = useProduct(routerProductCrumb)

  if (!product) {
    return null
  }

  return (
    <ProductContext.Provider value={product}>
      <PPWizard product={product} flow={flow} />
    </ProductContext.Provider>
  )
}

const PPWizard = memo(function PPWizard({ product, flow }) {
  const { productLine } = product
  const [CurrentWizard, setCurrentWizard] = useState()
  const [productLineModule, setProductLineModule] = useState()
  const { hotjars, setProductMappings } = useAnalyticsContext()
  const dispatch = useDispatch()
  useFrequencyVariants()

  const filteredProductLine = getProductLine(productLine)

  const { loading: isLoadingTranslations, translationsError } = useLoadTranslations(['common', filteredProductLine], {
    productLine: filteredProductLine,
    speech: flow?.speech,
    audience: flow?.audience,
    partnerId: flow?.partner?.id,
    productId: product.id,
  })

  // These bits are magical and clumsy - we need to make something to load the whole suite of modules for a product line
  useEffect(() => {
    import(`./product-lines/${filteredProductLine}/product-line-info`).then(ProductLineModule => {
      dispatch({ type: [PRODUCT_LINE_VALUE], payload: { productLine } })
      setProductLineModule(ProductLineModule) // we load it here, so ProductLineContext could pass values to the Cart that is separate from the wizard for now
    })

    const Wizard = React.lazy(() => import(`./product-lines/${filteredProductLine}/Wizard`))
    setCurrentWizard(Wizard)
    const filterActiveProduct = flow.products.filter(flowProduct => flowProduct.name === product.name)

    const curInsuranceProducts =
      filterActiveProduct.length > 0 ? filterActiveProduct[0].insuranceProducts?.map(fil => fil.name).join() : ''

    hotjars(product.id, {
      partnerName: flow.partner?.name,
      insuranceProducts: curInsuranceProducts,
    }).track(`statistics. product=${product.id}&product_line=${filteredProductLine}`)

    setProductMappings({ partnerName: flow.partner?.name, insuranceProducts: curInsuranceProducts })
  }, [filteredProductLine]) // eslint-disable-line react-hooks/exhaustive-deps

  // Tracks wizard for Google Analytics
  useWizardTracking({ flow, product })

  if (translationsError) {
    return 'Failed to load translations'
  }

  if (isLoadingTranslations) {
    return null
  }

  return productLineModule ? (
    <Suspense fallback={<DynamicLoader.Start />}>
      <ProductLineContext.Provider
        value={{ createInfo: productLineModule.create, productLinePrefix: getProductLinePrefix(productLine) }}
      >
        <CurrentWizard product={product} />
        <BavariaDirectDynamicYield product={product} />
      </ProductLineContext.Provider>
    </Suspense>
  ) : null
})

function getProductLine(productLine) {
  if (['home-content', 'home-content-v2'].includes(productLine)) {
    return 'home-content'
  }

  if (['private-liability', 'private-liability-v2'].includes(productLine)) {
    return 'private-liability'
  }

  if (['accident', 'accident-v2'].includes(productLine)) {
    return 'accident'
  }

  if (['bike-assistance', 'bike-theft-damage'].includes(productLine)) {
    return 'bike-assistance'
  }

  return productLine
}

export function getProductLinePrefix(productLine) {
  switch (productLine) {
    case 'accident':
      return 'insuredPersons'
    case 'accident-v2':
      return 'insuredEntities'

    default:
      // eslint-disable-next-line no-unused-expressions
      ''
  }
}

export default memo(ProductPurchaseWizard)
