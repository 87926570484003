import { isIOS } from '@elementinsurance/utils'
import fileDownload from 'js-file-download'

export const downloadInNewTab = url => {
  const a = document.createElement('a')
  a.href = url
  a.rel = 'noopener noreferrer'
  a.target = '_blank'
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

/**
 * Extracts file name from 'Content-Disposition' header.
 * API must have 'Access-Control-Expose-Headers' contain 'Content-Disposition'
 */
function getFileName(response) {
  const contentDisposition = response.headers.get('Content-Disposition') ?? ''
  return /filename="(.+?)"/i.exec(contentDisposition)?.[1]
}

export async function downloadFile({ mimeType, fileName, url, forceOnIOS = true }) {
  if (forceOnIOS && isIOS()) {
    forceDownload(url)
    return
  }

  const response = await fetch(url)
  const downloadedFileName = fileName ?? getFileName(response)
  const blob = await response.blob()
  fileDownload(blob, downloadedFileName, mimeType ?? blob.type)
}

async function forceDownload(url) {
  const link = document.createElement('a')
  link.href = url
  link.click()
}
