import { useEffect, useState } from 'react'
import { i18n, queryParse } from '@elementinsurance/utils'
import { loadTranslationsRequest } from '@elementinsurance/api'

// `undefined` is used instead of ProductId when we need common translations
export const productsWithTranslationsCache = new Map<ProductId | undefined, NamespacesWithTranslations>()

export function useLoadTranslations(
  namespaces: string[],
  { productLine, productId, partnerId, speech, audience }: Omit<TranslationContext, 'language'>
) {
  const [state, setState] = useState<UseLoadTranslationsState>({
    loading: true,
    error: null,
  })
  const namespacesCacheKey = namespaces.sort().join()
  const { language } = i18n

  useEffect(() => {
    setState({ loading: true, error: null })
    loadProductTranslations(namespaces, {
      language,
      audience,
      partnerId,
      productId,
      productLine,
      speech,
    })
      .then(() => {
        setState({ loading: false, error: null })
      })
      .catch(error => {
        console.error(`Failed to load translations for ${productId}`, error)
        setState({ loading: false, error })
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [namespacesCacheKey, language, audience, partnerId, productId, productLine, speech])

  return state
}

function replaceTranslationsWithKeys(bundle: Translations) {
  return Object.fromEntries(Object.keys(bundle).map(key => [key, key]))
}

export async function loadProductTranslations(namespaces: string[], translationContext: TranslationContext) {
  const { productId, language } = translationContext

  let productTranslations = productsWithTranslationsCache.get(productId)

  if (!productTranslations) {
    productTranslations = await getProductTranslations(namespaces, translationContext)
    productsWithTranslationsCache.set(productId, productTranslations)
  }

  Object.entries(productTranslations).forEach(([namespace, translations]) => {
    i18n.removeResourceBundle(language, namespace) // removes old one if exists. this prevents merging old and new translation keys
    i18n.addResourceBundle(language, namespace, translations)
  })
}

export async function getProductTranslations(
  namespaces: string[],
  translationContext: TranslationContext
): Promise<NamespacesWithTranslations> {
  const queries = queryParse(window.location.search) as Record<string, string | boolean>

  const promises = namespaces.map(async (namespace: string) => {
    const translations = await loadNamespaceTranslations({
      namespace,
      showTranslationKeys: !!queries.showTranslationKeys,
      ...translationContext,
    })

    return [namespace, translations]
  })

  const namespaceTranslationPairs = await Promise.all(promises)

  return Object.fromEntries(namespaceTranslationPairs)
}

export async function loadNamespaceTranslations({
  namespace,
  language,
  showTranslationKeys,
  speech,
  audience,
  partnerId,
  productId,
  productLine,
}: TranslationContext & {
  namespace: string
  showTranslationKeys: boolean
}) {
  const remoteTranslations = await loadTranslationsRequest({
    language: 'de', // TODO
    speech,
    audience,
    namespace,
    productLine: namespace === 'common' && productLine ? productLine : null,
    partnerId,
    productId,
  }).catch(error => {
    console.error(`Remote translations not found for: ${language} and speech: ${speech}`, error)
    return null
  })

  const translations = {
    ...remoteTranslations,
  }

  return showTranslationKeys ? replaceTranslationsWithKeys(translations) : translations
}

type NamespacesWithTranslations = Record<Namespace, Translations>
type Translations = Record<string, string>

type ProductId = string
type Namespace = string

type TranslationContext = {
  language: string
  productLine?: string
  productId?: string
  partnerId: string
  speech: string
  audience: string
}

type UseLoadTranslationsState = { loading: boolean; error: unknown }
