export class BackendValidationErrors extends Error {
  constructor(errors) {
    super('Backend validation error: ' + JSON.stringify(errors))

    this.errors = errors.map(e => ({
      field: e.field.split('.').pop(),
      code: e.code || 'invalid',
      technicalMessage: e.defaultMessage,
    }))
  }
}

export class SingleFieldBackendValidationError extends BackendValidationErrors {
  constructor(field, code, technicalMessage) {
    super([
      {
        field,
        code,
        technicalMessage,
      },
    ])
  }
}
