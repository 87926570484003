import { useEffect } from 'react'
import useSWR from 'swr'
import _get from 'lodash/get'
import { normalizeProduct } from '@elementinsurance/common/modules/product/product.normalizers'
import { getSelectedFrequencyVariants } from '@elementinsurance/common//modules/wizard/wizard.selectors'
import { setSelectedFrequencyVariants } from '@elementinsurance/common/modules/wizard/wizard.actions'
import { useProductContext } from '@elementinsurance/common/modules/product/product.context'
import { getWizardData } from '@elementinsurance/common/modules/wizard/wizard.selectors'
import { useFlowProductId } from '@elementinsurance/common/modules/flow/flow.loaders.js'
import client from '@elementinsurance/client'
import { useDispatch, useSelector } from 'react-redux'
import { FieldConstraint, Product } from './product.normalizers'

export function useProduct(productCrumb: string): any {
  const productId: string | null = useFlowProductId(productCrumb)
  return useSWR(
    `/api/v2/products/${productId}`,
    async url => {
      const { data } = await client.get(url, { withSessionToken: true })
      return normalizeProduct(data)
    },
    { shouldRetryOnError: false, revalidateOnFocus: false }
  )
}

export function useProductField(name: string) {
  const product = useProductContext()
  return getProductField(product, name)
}

export function getProductField(product: Product, name: string) {
  return product.fields.find(field => field.name === name)
}

export function getProductFieldValues(product: Product, name: string) {
  const field = getProductField(product, name)
  return getValues(field?.constraints)
}

export function useProductVariants() {
  const product = useProductContext()
  const productLine = _get(product, 'productLine', '')
  const items = product?.productVariantMappings?.[productLine] ?? {}
  return Object.keys(items)
}

const getUnitCondtions = (arr: FieldConstraint[] | undefined = []) => {
  return arr
    .filter(i => i.type === 'conditional')
    .reduce((acc: any, i: any) => {
      acc[i.config.value] = i.config.rules[0].config.possibleValues
      return acc
    }, {})
}

export const useUnitsCondtions = () => {
  const fieldName = 'quote.payment.frequency.unit'
  const units = useProductField(fieldName)
  const frequencies = usePaymentFrequencies()
  const productVariants = useProductVariants()
  const conditions = getUnitCondtions(units?.constraints)
  const unitsCondtions = productVariants.reduce((acc: any, variantKey) => {
    acc[variantKey] = conditions[variantKey] || frequencies
    return acc
  }, {})
  return unitsCondtions
}

export const useFrequencyVariants = () => {
  const selectedFrequencyVariants = useSelector(getSelectedFrequencyVariants)
  const unitsCondtions = useUnitsCondtions()
  const productVariants = useProductVariants()
  const dispatch = useDispatch()
  useEffect(() => {
    if (!selectedFrequencyVariants) {
      const res = productVariants.reduce((acc: any, variantKey) => {
        acc[variantKey] = unitsCondtions[variantKey][0]
        return acc
      }, {})
      // @ts-ignore
      dispatch(setSelectedFrequencyVariants(res))
    }
  }, [selectedFrequencyVariants]) // eslint-disable-line react-hooks/exhaustive-deps
}

const getValues = (constraints: FieldConstraint[] | undefined = []) =>
  constraints.find(i => i.type === 'in')?.config?.possibleValues ?? []
export const usePaymentFrequencies = () => {
  const wizard = useSelector(getWizardData)
  const product = useProductContext()
  const field = product?.fields?.find(field => field.name === 'quote.payment.frequency.unit')
  if (field?.dependson?.length) {
    const condition = field?.constraints.find(i => i.type === 'conditional')
    if (condition?.config?.when) {
      const when = wizard.dependencies?.[condition.config.when]
      if (when !== null && when === condition.config.value) {
        return getValues(condition.config.rules)
      }
    }
  }
  return getValues(field?.constraints) || []
}

export const useProductI18nVariables = () => {
  const product = useProductContext()
  if (!product) {
    return null
  }

  const options: { [name: string]: string } = {}

  const variantMappings = product.productVariantMappings[product.productLine]
  Object.entries(variantMappings).forEach(([name, label]) => {
    options[`variantName${name}`] = label as string
  })

  return options
}
