import * as React from 'react'

function SvgCoverStart(props) {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="M30 8a4 4 0 00-4-4h-1v-.972c0-.538-.414-1-.952-1.027A1 1 0 0023 3v1H9v-.972c0-.538-.414-1-.952-1.027A1 1 0 007 3v1H6a4 4 0 00-4 4v.75a.25.25 0 00.25.25h27.5a.25.25 0 00.25-.25V8zM2 26a4 4 0 004 4h20a4 4 0 004-4V11.187a.188.188 0 00-.188-.187H2.188a.188.188 0 00-.187.188V26zm21.5-13a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm0 5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm-5-5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm0 5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm0 5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm-5-5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm0 5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm-5-5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm0 5a1.5 1.5 0 110 3 1.5 1.5 0 010-3z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCoverStart
