import * as React from 'react'

function SvgPhonenumber(props) {
  return (
    <svg width={30} height={48} viewBox="0 0 30 48" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 3H6a3 3 0 00-3 3v36a3 3 0 003 3h18a3 3 0 003-3V6a3 3 0 00-3-3zM6 0a6 6 0 00-6 6v36a6 6 0 006 6h18a6 6 0 006-6V6a6 6 0 00-6-6H6z"
        fill="currentColor"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M15 42a3 3 0 100-6 3 3 0 000 6z" fill="currentColor" />
    </svg>
  )
}

export default SvgPhonenumber
