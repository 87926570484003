import { createReducer } from '@elementinsurance/store'
import { PRODUCT_LINE_VALUE, POLICY_CREATED } from './policy.constants'

export const initialState = {
  productLine: null,
  policy: null,
}

export default createReducer(initialState, {
  [POLICY_CREATED]: (state, { payload }) => ({
    ...state,
    policy: payload,
  }),
  [PRODUCT_LINE_VALUE]: (state, { payload }) => ({ ...state, productLine: payload.productLine }),
})
