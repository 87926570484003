import React, { useState } from 'react'
import { Box, Text, Label } from '@elementinsurance/uikit'

import cn from 'classnames'
import styles from './card.module.scss'

const Card = ({ children, title, name }) => {
  const [contentVisible, setContentVisible] = useState()
  return (
    <Box direction="column" bottom="s" data-testid={`card-${name}`}>
      <Box className={cn(styles.cardBody)} direction="column" fullWidth>
        <Box
          onClick={() => setContentVisible(!contentVisible)}
          className={cn(styles.header)}
          justify="between"
          role="button"
          right="s"
          left="s"
          top="xs"
        >
          <Text data-testid={`card-${name}-title`} role="heading" color="body" bold="bold" bottom="xs" size="m">
            {title}
          </Text>
          <Box className={cn(styles.arrowContainer)} top="xxs" size="m">
            {children && (
              <Box
                className={cn(styles.arrow, {
                  [styles.arrowReversed]: contentVisible,
                })}
                fullWidth
              />
            )}
          </Box>
        </Box>
      </Box>
      {children && contentVisible && (
        <Box className={cn(styles.cardBody, styles.description)} direction="column" fullWidth>
          <Box className={cn(styles.content)} direction="column" bottom="xxs" right="xs" top="xs" left="xs">
            {children}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export function CardGroup({ children }) {
  return (
    <Box className={styles.line} direction="column">
      {children}
    </Box>
  )
}

export function CardItem({ children, name, label, ...rest }) {
  const labelledBy = 'label_' + name
  return (
    <Box justify="start" direction="column" {...rest}>
      <Box bottom="xxs">
        <Label name={name} id={labelledBy} data-testid={`title-${name}`} className={styles.leftPart}>
          {label}
        </Label>
        <Text
          data-testid={`value-${name}`}
          aria-labelledby={labelledBy}
          className={styles.rightPart}
          bold="normal"
          left="xxs"
          size="xs"
        >
          {children}
        </Text>
      </Box>
    </Box>
  )
}

Card.Group = CardGroup
Card.Item = CardItem

export default Card
