import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { Box } from '@elementinsurance/uikit'
import { Loader } from '@elementinsurance/uikit/icons/svgr/l'
import styles from './loader.module.scss'

export const colors = ['primary', 'secondary', 'defaultColor']
export const sizes = ['s', 'm', 'l', 'xl']
export const positions = ['absolute', 'fixed']

const MainLoader = ({
  className = undefined,
  color = 'primary',
  show = false,
  position = 'fixed',
  size = 'l',
  ...props
}) => {
  return (
    <>
      <Box
        className={cn(styles.wrapper, { [styles.hidden]: !show, [styles.visible]: show }, className)}
        position={position}
        direction="column"
        fullWidth
        {...props}
      >
        <Box className={styles.overlay} position={position} fullWidth />
        <Loader
          data-testid="loader"
          className={cn(styles.loader, {
            [styles[color]]: !!color,
            [styles[size]]: !!size,
          })}
        />
      </Box>
    </>
  )
}

MainLoader.propTypes = {
  color: PropTypes.oneOf(colors),
  size: PropTypes.oneOf(sizes),
  position: PropTypes.oneOf(positions),
  show: PropTypes.bool,
}

export default MainLoader
