import React from 'react'
import { Media, TABLET_MIN_WIDTH } from '@elementinsurance/utils'

import DesktopButtonBar from './desktop/DesktopButtonBar'
import MobileButtonBar from './mobile/MobileButtonBar'
import FillFormButton from './fill-form-button'

const ButtonBar = ({ useMobileView = false, onPrevious, nextLabel, disabled, onNext, ...props }) => {
  return (
    <Media query={{ maxWidth: TABLET_MIN_WIDTH }}>
      {isMobile =>
        isMobile && useMobileView ? (
          <MobileButtonBar
            nextLabel={nextLabel}
            disabled={disabled}
            onPrevious={onPrevious}
            onNext={onNext}
            fillFormButton={FillFormButton}
            {...props}
          />
        ) : (
          <DesktopButtonBar
            nextLabel={nextLabel}
            disabled={disabled}
            onPrevious={onPrevious}
            onNext={onNext}
            fillFormButton={FillFormButton}
            {...props}
          />
        )
      }
    </Media>
  )
}

export default ButtonBar
