import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import ReactCheckbox from 'rc-checkbox'
import { Tooltip, Text, Box, Loader } from '@elementinsurance/uikit'

import styles from './checkbox.module.scss'
import { Loader as LoderIcon } from '../icons/svgr/l'

const Checkbox = ({ className, loading, onChange, label, radio, name, tooltip, tooltipPosition, value, ...props }) => {
  const classes = cn(className, {
    'element-in-checkbox-radio': radio,
  })

  const handleChange = event => {
    onChange && onChange(event.target.checked)
  }

  const checkbox = (
    <ReactCheckbox
      data-testid={`checkbox-${name}`}
      type="checkbox"
      prefixCls="element-in-checkbox"
      className={classes}
      onChange={handleChange}
      name={name}
      checked={value}
      {...props}
    />
  )
  const icon = true
  const loader = icon ? (
    <LoderIcon
      width={24}
      height={24}
      style={{
        background: '0 0',
      }}
    />
  ) : (
    <Loader show />
  )
  if (label) {
    return (
      <Box justify="between">
        <Box align="start" component="label">
          {loading ? loader : checkbox}
          {typeof label === 'string' ? (
            <Box className={styles.content}>
              <Text data-testid={`checkbox-${name}-label`} size="xs">
                <Text inject>
                  <>
                    {label}
                    <span data-testid={`checkbox-${name}-asterix`} className={cn(styles.asterix, 'field-asterix')}>
                      *
                    </span>
                  </>
                </Text>
              </Text>
            </Box>
          ) : (
            label
          )}
          <Tooltip tooltip={tooltip} tooltipPosition={tooltipPosition} name={name} />
        </Box>
      </Box>
    )
  }

  return checkbox
}

Checkbox.propTypes = {
  label: PropTypes.node,
  radio: PropTypes.bool,
  onChange: PropTypes.func,
}

export default Checkbox
