import { normalizeFlow } from './flow.normalizers'
import { useFlowContext } from '@elementinsurance/common/modules/flow/flow.context'
import client from '@elementinsurance/client'
import useSWR from 'swr'

export function useFlow(crumb) {
  return useSWR(
    `/api/v2/flows/${crumb}`,
    async url => {
      const { data } = await client.get(url)
      return normalizeFlow({ ...data, crumb })
    },
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  )
}

export function useFlowProductId(crumb) {
  const { products = [] } = useFlowContext()
  return products?.find(p => p.name.trim() === crumb.trim() || p.id === crumb)?.id ?? null
}
