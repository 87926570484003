import { useState } from 'react'
import cn from 'classnames'
import { Box, Text } from '@elementinsurance/uikit'
import ArrowUpIcon from '@elementinsurance/uikit/icons/svgr/m/ArrowUp'

import styles from './FAQItem.module.scss'

export default function FAQItem({ question, answer }) {
  const [open, setOpen] = useState(false)

  const toggleContent = () => setOpen(!open)

  return (
    <Box fullWidth direction="column" data-testid={`faq-card-${question}`}>
      <Box
        fullWidth
        className={cn(styles.header, {
          [styles.open]: !!open,
        })}
        padding="xxs"
        onClick={toggleContent}
        data-testid={`faq-card-${question}-title`}
      >
        <Box flex="1">
          <Text className={styles.title} size="xs">
            {question}
          </Text>
        </Box>
        <Box
          className={cn(styles.toggle, {
            [styles.open]: !!open,
          })}
        >
          <ArrowUpIcon />
        </Box>
      </Box>
      <Box
        fullWidth
        padding="xxs"
        className={cn(styles.content, {
          [styles.open]: !!open,
        })}
        data-testid={`faq-card-${question}-content`}
      >
        <Box danger fullWidth direction="column" className={styles.answer}>
          {answer}
        </Box>
      </Box>
    </Box>
  )
}
