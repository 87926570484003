import { storeExternalParamsFromUrl } from 'packages/api'
import { useEffect } from 'react'
import { useLocation, Routes, Route, useSearchParams } from 'react-router-dom'
import { ProductsByFlow, PurchaseFlowContainer } from './purchase-flow/PurchaseFlow'
import useSessionStorage from '@elementinsurance/common/hooks/useSesionStorage'

const Pages = () => {
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const fromQueryParamValue = searchParams.get('from')
  const [, setSessionStorageFromData] = useSessionStorage('from', '')

  useEffect(() => {
    if (fromQueryParamValue === 'old') {
      setSessionStorageFromData(fromQueryParamValue)
    }
    window.scrollTo(0, 0)
    storeExternalParamsFromUrl()
  }, [location.pathname])

  return (
    <Routes>
      <Route path="/:flowCrumb" element={<ProductsByFlow />} />
      <Route path="/:flowCrumb/abschliessen/:productCrumb/*" element={<PurchaseFlowContainer />} />
    </Routes>
  )
}

export default Pages
