import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Text } from '..'

import styles from './modalLink.module.scss'
import { Modal } from '../modal'

const ModalLink = ({ linkText, children, component: Component = Modal, linkProps = {}, ...rest }) => {
  const [isOpen, setOpen] = useState(false)

  const handleClick = e => {
    e.preventDefault()
    setOpen(true)
    document.body.classList.add('modal-open')
    window.scrollTo(0, 0)
  }

  const handleClose = () => {
    setOpen(false)
    document.body.classList.remove('modal-open')
  }

  return (
    <>
      <Text
        className={styles.link}
        color="highlight"
        component="a"
        href="#"
        size="xs"
        onClick={handleClick}
        {...linkProps}
      >
        {linkText}
      </Text>

      {isOpen && (
        <Component open={isOpen} onClose={handleClose} {...rest}>
          {children}
        </Component>
      )}
    </>
  )
}

ModalLink.propTypes = {
  linkText: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  component: PropTypes.elementType,
}

export default ModalLink
