import { useState, useEffect, Dispatch, SetStateAction } from 'react'

const useSessionStorage = (key: string, initialValue: string): [string, Dispatch<SetStateAction<any>>] => {
  const storedValue = sessionStorage.getItem(key) || initialValue
  const [value, setValue] = useState<string>(storedValue)

  useEffect(() => {
    sessionStorage.setItem(key, value)
  }, [key, value])

  const handleStorageChange = (event: StorageEvent) => {
    if (event.storageArea === sessionStorage && event.key === key) {
      setValue(event.newValue || '')
    }
  }

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [key])

  const setSessionStorageValue = (newValue: string) => {
    setValue(newValue)
    sessionStorage.setItem(key, newValue)
  }

  return [value, setSessionStorageValue]
}

export default useSessionStorage
