import * as React from 'react'

const SvgEbike = props => (
  <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.667 26.667c-1.89 0-3.472-.64-4.75-1.918C.64 23.472 0 21.89 0 20c0-1.889.65-3.472 1.95-4.75 1.3-1.277 2.872-1.917 4.717-1.917 1.71 0 3.15.511 4.317 1.534a6.733 6.733 0 0 1 2.216 3.8h.867L11.667 12H9.333V9.333H16V12h-1.467L15 13.333h6.4L19.467 8H16V5.333h3.467c.577 0 1.094.156 1.55.467A2.6 2.6 0 0 1 22 7.067l2.267 6.2h1.066c1.845 0 3.417.65 4.718 1.949 1.3 1.3 1.949 2.873 1.949 4.717 0 1.867-.644 3.456-1.933 4.767-1.29 1.311-2.867 1.967-4.734 1.967-1.6 0-3.005-.5-4.216-1.5-1.211-1-1.984-2.278-2.317-3.834h-5.6a6.567 6.567 0 0 1-2.267 3.818c-1.2 1.01-2.622 1.516-4.266 1.516Zm0-2.667c.91 0 1.694-.25 2.35-.75a3.992 3.992 0 0 0 1.383-1.917H6.667v-2.666H10.4a3.883 3.883 0 0 0-1.383-1.934c-.656-.489-1.44-.733-2.35-.733-1.134 0-2.084.384-2.851 1.15-.766.767-1.15 1.717-1.15 2.85 0 1.111.384 2.056 1.15 2.833.767.778 1.717 1.167 2.85 1.167Zm10.266-5.333H18.8a7.96 7.96 0 0 1 .45-1.434c.19-.444.439-.855.75-1.233h-4.067l1 2.667Zm8.4 5.333c1.134 0 2.084-.389 2.851-1.167.766-.777 1.15-1.722 1.15-2.833 0-1.133-.384-2.083-1.15-2.85-.767-.766-1.717-1.15-2.85-1.15H25.2l1.333 3.533-2.533.934-1.267-3.534a4.001 4.001 0 0 0-1.033 1.334c-.244.51-.367 1.089-.367 1.733 0 1.111.384 2.056 1.151 2.833.766.778 1.716 1.167 2.85 1.167Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgEbike
