import { Box, Label, Text, Tooltip } from '@elementinsurance/uikit'
import cn from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Radio from './Radio'
import styles from './radioGroup.module.scss'

export const colors = ['primary', 'secondary', 'danger']

const RadioGroup = ({
  item: Component = Radio,
  id,
  className,
  disabled,
  options = [],
  value,
  color = 'primary',
  label,
  labelProps,
  tooltip,
  tooltipPosition,
  labelColor = 'body',
  labelSize = 'xs',
  inline = true,
  name,
  onChange,
  onBlur,
}) => {
  const handleChange = event => {
    const { value } = event.target
    onChange && onChange(value)

    // needed for react-hook-form in "blur" validation mode
    onBlur && onBlur()
  }

  return (
    <Box direction="column" data-testid={`radio-group-${name}`}>
      <Box justify="between">
        <Label
          data-testid={`input-radio-${name}-label`}
          className={styles.mainRadioLabel}
          component="label"
          htmlFor={id}
          {...labelProps}
        >
          {label}
        </Label>
        <Box position="relative">
          <Tooltip tooltip={tooltip} tooltipPosition={tooltipPosition} name={name} />
        </Box>
      </Box>
      <Box name={name} className={cn(styles.radioGroup, inline && styles.inline)}>
        {options.map(({ label: optionLabel, ...option }) => (
          <Box
            data-testid={`radio-option-${option.value}`}
            key={option.value}
            component="label"
            justify="start"
            align="start"
            style={{
              width: `${Math.round(100 / options.length)}%`,
            }}
            className={cn(styles.radioWrapper, className, {
              [styles[color]]: !!color,
              [styles.filled]: option.value === value,
              radioGroupFilled: option.value === value,
            })}
          >
            <Component
              className={cn(styles.item, option.className)}
              disabled={disabled}
              checked={option.value === value}
              aria-checked={option.value === value}
              onChange={handleChange}
              {...option}
            />
            {optionLabel && (
              <Text
                data-testid={`title-input-${name}-radio-label-${option.value}`}
                color={labelColor}
                size={labelSize}
                bold="bold"
                className={cn(styles.item, styles.label)}
              >
                {optionLabel}
              </Text>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  )
}

RadioGroup.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  color: PropTypes.oneOf(colors),
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
}

export default RadioGroup
