import { getLocaleDateStrings, i18n } from '@elementinsurance/utils'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import React from 'react'
import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import styles from './dayPickerWrapper.module.scss'

const DayPickerWrapper = ({ disabledDays, onChange, value, onBlur }) => {
  const handleDate = (selected, modifiers) => {
    if (!modifiers?.disabled) {
      const dateOnly = DateTime.fromJSDate(selected).toISODate()
      onChange(dateOnly)
    }
  }

  const locale = i18n?.language?.split('-')[0]

  const { months, weekdaysLong, weekdaysShort } = getLocaleDateStrings(locale)

  const date = value && DateTime.fromISO(value).toJSDate()

  return (
    <div className={styles.wrapper} data-testid="date-picker-wrapper">
      <DayPicker
        className={styles.picker}
        locale={locale}
        months={months}
        onBlur={onBlur}
        weekdaysLong={weekdaysLong}
        weekdaysShort={weekdaysShort}
        onDayClick={handleDate}
        selectedDays={date}
        disabledDays={disabledDays}
        month={date}
      />
    </div>
  )
}

DayPickerWrapper.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
}

export default DayPickerWrapper
