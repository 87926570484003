import { createSelector } from 'reselect'
import { Wizard } from './wizard.reducer'

export const getWizardData = (state: { wizard: Wizard }) => state.wizard

export const getWizardSteps = createSelector(getWizardData, wizard => wizard.steps)

export const getWizardStepValues = (stepId: string, customerSpecificData?: any) =>
  createSelector(getWizardData, wizard => {
    const customer = JSON.parse(localStorage.getItem('customer')!)
    return customerSpecificData ? { ...customer, ...wizard[stepId] } : wizard[stepId]
  })

export const getPrevStepData = (stepId: string) =>
  createSelector(getWizardData, wizard => {
    const prevIndex = wizard.steps.findIndex(s => s.id === stepId) - 1
    const prevStep = prevIndex >= 0 ? wizard.steps[prevIndex] : null
    return prevStep && wizard[prevStep.id]
  })

/** Checks if we can navigate to the step */
export const isNavigableStepIndex = (stepIndex: number | null) =>
  createSelector(
    getWizardData,
    wizard => stepIndex !== null && (stepIndex === 0 || !!wizard[wizard.steps[stepIndex - 1].id])
  )

export const getWizardValues = createSelector(getWizardData, wizard => wizard)

export const getSelectedFrequencyVariants = createSelector(getWizardData, wizard => wizard.selectedFrequencyVariants)
