import * as React from 'react'

function SvgArrowRight(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M8.293 18.707a1 1 0 010-1.414L13.586 12 8.293 6.707a1 1 0 011.414-1.414L16.414 12l-6.707 6.707a1 1 0 01-1.414 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgArrowRight
