import * as React from 'react'

function SvgHouse(props) {
  return (
    <svg width={65} height={64} viewBox="0 0 65 64" fill="none" {...props}>
      <path
        d="M51.142 5H24.526a5.357 5.357 0 00-5.36 5.36v15.032L6.627 37.771A2.666 2.666 0 008.5 42.333v13.334a2.667 2.667 0 002.667 2.666h42.667a2.667 2.667 0 002.666-2.666v-45.31A5.354 5.354 0 0051.142 5zM29.625 39.963V53H13.833V38.152l7.965-7.864 7.827 8.008v1.667zm5.541-16.296h-5.333v-5.334h5.333v5.334zM45.833 45h-5.334v-5.333h5.334V45zm0-10.667h-5.334V29h5.334v5.333zm0-10.666h-5.334v-5.334h5.334v5.334z"
        fill="currentColor"
      />
      <path d="M19.167 40H24.5v5.333h-5.333V40z" fill="currentColor" />
    </svg>
  )
}

export default SvgHouse
