import * as React from 'react'

function SvgEye(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M22.083 11.396c-.824-1.736-1.804-3.152-2.941-4.247L17.948 8.34c.973.93 1.82 2.145 2.552 3.66-1.95 4.035-4.718 5.953-8.5 5.953-1.136 0-2.182-.175-3.138-.525L7.57 18.721c1.329.613 2.805.92 4.43.92 4.505 0 7.866-2.346 10.083-7.038a1.412 1.412 0 000-1.207zm-1.49-7.515l-1-1a.187.187 0 00-.264 0l-2.564 2.562C15.352 4.72 13.764 4.36 12 4.36c-4.505 0-7.865 2.346-10.083 7.038a1.414 1.414 0 000 1.207c.886 1.866 1.953 3.361 3.2 4.486l-2.48 2.48a.187.187 0 000 .265l1 1a.187.187 0 00.265 0l16.691-16.69a.186.186 0 00.04-.204.186.186 0 00-.04-.061zM3.499 12C5.452 7.965 8.22 6.047 12 6.047c1.278 0 2.44.22 3.495.666L13.848 8.36a4.125 4.125 0 00-5.582 5.582l-1.955 1.955C5.23 14.942 4.296 13.647 3.5 12zm5.782 0A2.627 2.627 0 0112.708 9.5l-3.302 3.302a2.626 2.626 0 01-.125-.801z"
        fill="currentColor"
      />
      <path
        d="M11.906 14.625a2.65 2.65 0 01-.24-.01l-1.238 1.237a4.127 4.127 0 005.33-5.33l-1.237 1.238a2.613 2.613 0 01-.189 1.245 2.625 2.625 0 01-2.426 1.62z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgEye
