import React from 'react'
import PropTypes from 'prop-types'
import { ReactSVG } from 'react-svg'

const Image = ({ svg, src, ...props }) => {
  if (svg) {
    return <ReactSVG src={src} {...props} />
  }

  return <img src={src} alt="main pic description" {...props} />
}

Image.propTypes = {
  svg: PropTypes.bool,
}

export default Image
