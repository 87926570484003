import { Box, Label, Tooltip } from '@elementinsurance/uikit'
import React, { useState } from 'react'
import { DateTime } from 'luxon'
import cn from 'classnames'
import DateInputTriplet from './DateInputTriplet'
import DayPickerWrapper from './DayPickerWrapper'
import styles from './dayPickerWrapper.module.scss'

const DateInput = ({
  id,
  label,
  labels,
  placeholders,
  value,
  name,
  onChange,
  onBlur,
  withPicker,
  range,
  tooltipPosition,
  tooltip,
  invalid,
  disabled,
}) => {
  const [isPickerVisible, setIsPickerVisible] = useState(false)

  const propagateBlur = event => {
    const isBlurOutside = !event.currentTarget.contains(event.relatedTarget)
    if (isBlurOutside) {
      setIsPickerVisible(false)
      // eslint-disable-next-line no-unused-expressions
      onBlur?.(event)
    }
  }

  const disabledDays = range && {
    after: range.notAfter?.toJSDate(),
    before: range.notBefore?.toJSDate(),
  }
  const labelledById = id && `${id}_label`

  const getValidDateOrNull = value => (DateTime.fromISO(value).isValid ? value : null)
  return (
    <div onBlur={propagateBlur} data-testid={`date-input-${name}`}>
      <Box justify="between" id={labelledById} direction="row">
        {labels ? (
          <>
            <Label style={{ flex: 1 }} data-part="day">
              {labels.day}
            </Label>
            <Label style={{ flex: 1 }} data-part="month">
              {labels.month}
            </Label>
            <Label style={{ flex: 1 }} data-part="year">
              {labels.year}
            </Label>
          </>
        ) : (
          <Label>{label}</Label>
        )}
        <div className={cn({ [styles.tooltipAligned]: labels })}>
          <Tooltip name={name} tooltipPosition={tooltipPosition} tooltip={tooltip} />
        </div>
      </Box>
      <DateInputTriplet
        disabled={disabled}
        invalid={invalid}
        labelledById={labelledById}
        value={value}
        onPartFocus={() => withPicker && setIsPickerVisible(true)}
        name={name}
        onChange={onChange}
        placeholders={placeholders ?? labels}
      />
      {isPickerVisible && (
        <DayPickerWrapper onChange={onChange} value={getValidDateOrNull(value)} disabledDays={disabledDays} />
      )}
    </div>
  )
}

export default DateInput
