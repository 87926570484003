import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { Box, Image } from '@elementinsurance/uikit'
import SecureSSL from './img/secureSSL.svg'
import VisaSvg from './img/visa.svg'
import MasterCardSvg from './img/mastercard.svg'
import StripeSvg from './img/stripe.svg'

import styles from './partners.module.scss'

const imageMapper = {
  visa: VisaSvg,
  mastercard: MasterCardSvg,
  stripe: StripeSvg,
}

const Partners = ({ children, className, showSSL = true, cards = ['visa', 'mastercard', 'stripe'], ...props }) => {
  const renderCards = (card, idx) => {
    const svgImage = imageMapper[card.toLowerCase()]

    return svgImage ? (
      <Image data-testid={`footer-${card}-icon`} key={`${card}-${idx}`} className={styles.card} src={svgImage} svg />
    ) : null
  }

  return (
    <Box fullWidth justify="between" align="center" className={cn(styles.partners, className)} {...props}>
      {showSSL && (
        <Box className={styles.ssl}>
          <Image src={SecureSSL} data-testid="footer-ssl-icon" svg />
        </Box>
      )}
      {cards && (
        <Box className={styles.cards} justify="center" align="center">
          {cards.map(renderCards)}
        </Box>
      )}
      {children}
    </Box>
  )
}

export const partnersProptypes = {
  className: PropTypes.string,
  showSSL: PropTypes.bool,
  cards: PropTypes.array,
}

Partners.propTypes = partnersProptypes

export default Partners
