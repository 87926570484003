import * as React from 'react'

function SvgPrivateLiability(props) {
  return (
    <svg width={80} height={80} viewBox="0 0 80 80" fill="none" {...props}>
      <path
        d="M21.124 43.187l-.035-.04a6.684 6.684 0 00-.368-.278l.403.318zM54.878 43.188l.348-.282a8.816 8.816 0 00-.31.238l-.038.044z"
        fill="currentColor"
      />
      <path
        d="M65.51 30.762a29.822 29.822 0 00-24.329-18.216 1.173 1.173 0 01-1.056-1.156c0-.63-.51-1.14-1.14-1.14h-1.971c-.63 0-1.139.51-1.139 1.139 0 .598-.463 1.092-1.058 1.155A29.695 29.695 0 008.25 42.125v.786c0 .844 1.004 1.286 1.627.716a8.318 8.318 0 0110.815-.78l.03.022a6.605 6.605 0 01.9.758c.829.83 2.176.83 3.005 0a8.312 8.312 0 0110.811-.768c.275.207.437.53.437.875v19.641a2.125 2.125 0 11-4.25 0 2.125 2.125 0 00-4.25 0 6.375 6.375 0 0012.75 0V43.734c0-.344.162-.668.437-.875a8.312 8.312 0 0110.81.768c.83.83 2.176.83 3.005 0a7.072 7.072 0 01.849-.721l.034-.028c3.272-2.453 7.97-2.142 10.866.75.622.569 1.624.127 1.624-.716v-.787a29.698 29.698 0 00-2.24-11.363z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgPrivateLiability
