import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { Box } from '@elementinsurance/uikit'

import styles from './root.module.scss'

const Root = ({ children, className, ...props }) => {
  return (
    <Box data-testid="footer" component="footer" direction="column" className={cn(styles.footer, className)} {...props}>
      {children}
    </Box>
  )
}

export const rootProps = {
  className: PropTypes.string,
}

Root.propTypes = rootProps

export default Root
