import React, { createRef, FC, memo, useState } from 'react'
import cn from 'classnames'
import Input from '@elementinsurance/uikit/input/Input'
import Box from '@elementinsurance/uikit/box/Box'
import DatePicker, { DateUtils, DayModifiers } from 'react-day-picker'
import { DateTime, Duration } from 'luxon'
import { formatDateTo, getLocaleDateStrings, i18n, nowInBerlin, Media, TABLET_MIN_WIDTH } from '@elementinsurance/utils'
import { germanDateFormat } from '@elementinsurance/utils/dates'
import 'react-day-picker/lib/style.css'

import styles from './dateRange.module.scss'
import { useOnClickOutside } from '../hooks/useOnClickOutside'

const DateRange: FC<DateRangeProps> = ({
  show = false,
  placeholder,
  labelFrom,
  labelTo,
  labelTime,
  onChange,
  disabledDays,
  maxDays,
  required,
  value,
  withTime = false,
  timeEditable = true,
  tooltip,
  name,
}) => {
  const format = germanDateFormat

  const convertedValue = value ? convertFromPeriod(value) : null

  const [open, setOpen] = useState(show)
  const [startDate, setStartDate] = useState(convertedValue?.from ?? null)
  const [endDate, setEndDate] = useState(convertedValue?.to ?? null)
  const [selectedTime, setTime] = useState(
    convertedValue?.time || nowInBerlin().plus({ minutes: 35 }).toFormat('HH:mm')
  )

  const ref = createRef<HTMLDivElement>()
  useOnClickOutside(ref, () => {
    setOpen(false)
    if (!endDate) {
      // reset after closing if no end date was chosen
      setStartDate(null)
      setEndDate(null)
    }
  })

  const handleDate = (date: Date, modifiers: DayModifiers) => {
    if (modifiers.disabled) {
      return
    }

    // if the same as startDate, do nothing
    if (startDate && !endDate && DateUtils.isSameDay(date, startDate.toJSDate())) {
      // setStartDate(null) // maybe user wanted to clear the component
      return
    }

    const selected = DateTime.fromJSDate(date)
    const isBeforeStartDate = startDate && DateUtils.isDayBefore(date, startDate.toJSDate())
    const isEndDate = !!startDate && !endDate && !isBeforeStartDate

    if (isEndDate) {
      setEndDate(selected)
      onChange(
        convertToPeriod({
          from: startDate,
          to: selected,
          time: selectedTime,
        })
      )
    } else {
      setStartDate(selected)
      setEndDate(null)
    }
  }

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTime(e.target.value)
    onChange(convertToPeriod({ from: startDate, to: endDate, time: e.target.value }))
  }

  const locale = i18n?.language?.split('-')[0]

  const { months, weekdaysLong, weekdaysShort } = getLocaleDateStrings(locale)

  // if we are in progress of selecting endDate, we restrict calendar to maxDays from startDate
  const maxEndDate = startDate && !endDate && maxDays && startDate.plus({ days: maxDays }).toJSDate()
  const unavailableDays = maxEndDate
    ? {
        before: disabledDays?.before,
        after: maxEndDate,
      }
    : disabledDays

  const renderTime = () => {
    return (
      withTime && (
        <Box className={styles.timeWrapper} position="relative" justify="end">
          <Input // @ts-ignore
            wrapperClassName={styles.time}
            value={selectedTime}
            label={labelTime}
            tooltip={tooltip}
            type="time"
            data-testid={`${name}-time-picker`}
            onChange={handleTimeChange}
            disabled={!timeEditable}
          />
        </Box>
      )
    )
  }

  return (
    <Box className={styles.root} fullWidth data-testid={`${name}-range-picker`}>
      <Media query={{ maxWidth: TABLET_MIN_WIDTH }}>{isMobile => (isMobile ? renderTime() : null)}</Media>
      <div ref={ref} onClick={() => setOpen(true)}>
        <Box
          className={cn(styles.wrapper, {
            [styles.fullWidth]: !!withTime,
          })}
          position="relative"
        >
          <Input // @ts-ignore
            wrapperClassName={styles.inputWrapper}
            className={cn(styles.from, {
              [styles.filled]: !!startDate,
            })}
            value={startDate ? formatDateTo(startDate, format) : ''}
            type="text"
            data-testid={`${name}-from`}
            name="from"
            placeholder={placeholder}
            label={labelFrom}
            required={required}
            disabled
          />
          <Input // @ts-ignore
            wrapperClassName={styles.inputWrapper}
            className={cn(styles.to, {
              [styles.filled]: !!endDate,
            })}
            value={endDate ? formatDateTo(endDate, format) : ''}
            type="text"
            name="to"
            data-testid={`${name}-to`}
            label={labelTo}
            placeholder={placeholder}
            required={required}
            disabled
          />
          {open && (
            <Box data-testid={`${name}-picker`} className={styles.picker}>
              <DatePicker
                locale={locale}
                months={months}
                // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                month={startDate?.toJSDate()!}
                weekdaysLong={weekdaysLong}
                weekdaysShort={weekdaysShort}
                onDayClick={handleDate}
                disabledDays={unavailableDays}
                modifiers={{ start: startDate?.toJSDate(), end: endDate?.toJSDate() }}
                // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                selectedDays={[startDate?.toJSDate(), { from: startDate?.toJSDate()!, to: endDate?.toJSDate()! }]}
              />
            </Box>
          )}
        </Box>
      </div>
      <Media query={{ maxWidth: TABLET_MIN_WIDTH }}>{isMobile => (isMobile ? null : renderTime())}</Media>
    </Box>
  )
}

export function convertToPeriod({
  from,
  to,
  time,
}: {
  from: DateTime | null
  to: DateTime | null
  time: string
}): Period | null {
  if (!from || !to) {
    return null
  }

  let start = from.startOf('day')
  if (time) {
    const [hour, minute] = time.split(':').map(x => parseInt(x))
    start = start.set({ hour, minute })
  }

  const duration = to.startOf('day').diff(from.startOf('day'), 'days')
  return {
    start,
    duration,
  }
}

export function convertFromPeriod({ start, duration }: Period) {
  return {
    from: start.startOf('day'),
    to: start.startOf('day').plus(duration),
    time: start.toFormat('HH:mm'),
  }
}

type Period = { start: DateTime; duration: Duration }

type DateRangeProps = {
  show: boolean // TODO: why do we even need it
  placeholder?: string
  labelFrom?: string
  labelTo?: string
  labelTime?: string
  onChange: (value: Period | null) => void
  disabledDays?: { before: Date; after: Date }
  maxDays?: number
  required?: boolean
  value: Period
  withTime?: boolean
  timeEditable?: boolean
  tooltip?: string
  name: string
}

export default memo(DateRange)
