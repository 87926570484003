import { useEffect, useState } from 'react'
import { hotjar } from 'react-hotjar'

interface Window extends globalThis.Window {
  CookieBot?: {
    consent?: {
      statistics?: any
    }
  }
}

let isInit = false
export const initializeHotjar = () => {
  if (!isInit) {
    const hjid = 3120267
    const hjsv = 6
    hotjar.initialize(hjid, hjsv)
    isInit = true
  }
  return hotjar
}

export const hotjarsMain =
  (isAllowed?: boolean) =>
  (id: string, extras: { [partnerName: string]: string } = {}) => {
    if (isAllowed) {
      const hotjar = initializeHotjar()
      hotjar.identify(id, { userProperty: id, ...extras })
    }
    return {
      track: (event: string) => (isAllowed ? hotjar.event(event) : console.log('Hotjar event:', event)),
    }
  }

export const useHotjarInit = () => {
  const [isStatisticEnabled, setStatisticEnabled] = useState()
  useEffect(() => {
    waitForElementExists('#CookieBot').then(() => {
      const is = (window as Window)?.CookieBot?.consent?.statistics
      if (is) {
        setStatisticEnabled(is)
      } else {
        const funcLink = () => {
          setTimeout(() => setStatisticEnabled((window as Window)?.CookieBot?.consent?.statistics), 0)
        }
        waitForElementExists('#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll').then(exists => {
          if (exists) {
            document
              .querySelector('#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll')
              ?.addEventListener('click', funcLink)
            document
              .querySelector('#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection')
              ?.addEventListener('click', funcLink)
          }
        })

        return () => {
          document
            .querySelector('#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll')
            ?.removeEventListener('click', funcLink)
          document
            .querySelector('#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection')
            ?.removeEventListener('click', funcLink)
        }
      }
    })
  }, [])

  return isStatisticEnabled
}

function waitForElementExists(querySelector: string) {
  let timeout: ReturnType<typeof setTimeout> | undefined

  const removeTimeout = () => {
    clearTimeout(timeout)
    timeout = undefined
  }

  const dateNow = +Date.now() // will remove after testing

  return new Promise(resolve => {
    const observerDocument = new MutationObserver(() => {
      if (document.querySelector(querySelector)) {
        resolve(true)
        observerDocument.disconnect()
        removeTimeout()
        console.log(`${querySelector} exists`)
        console.log(`difference for test ${querySelector}`, Date.now() - dateNow) // will remove after testing
      }
    })

    observerDocument.observe(document.body, {
      childList: true,
      subtree: true,
    })

    timeout = setTimeout(() => {
      resolve(false)
      observerDocument.disconnect()
      removeTimeout()
      console.log(`${querySelector} not exists at all`)
    }, 5000)
  })
}
