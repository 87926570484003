import * as React from 'react'

function SvgCheck(props) {
  return (
    <svg width={21} height={16} viewBox="0 0 21 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.327 2.159L18.33.162a.552.552 0 00-.78 0L7.272 10.439 3.606 6.773a.552.552 0 00-.78 0L.828 8.77a.552.552 0 000 .78l6.054 6.053a.55.55 0 00.78 0L20.327 2.94a.552.552 0 000-.78z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCheck
