import * as React from 'react'

function SvgSearch(props) {
  return (
    <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
      <path d="M22 20l-2 2-6-6v-2h2z" />
      <path d="M9 16c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7-3.1 7-7 7zM9 4C6.2 4 4 6.2 4 9s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5z" />
      <path d="M12.624 13.401l.707-.707 2.475 2.475-.708.707z" />
    </svg>
  )
}

export default SvgSearch
