import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import styles from './title.module.scss'

const TableTitle = ({ className, color, children, ...props }) => {
  return (
    <th
      className={cn(
        styles.title,
        {
          [styles[color]]: !!color,
        },
        className
      )}
      {...props}
    >
      {children}
    </th>
  )
}

TableTitle.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
}

export default TableTitle
