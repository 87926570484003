import { useState } from 'react'
import FAQContent from '@elementinsurance/common/components/faq-content/FAQContent'
import { Box, Modal, Text } from '@elementinsurance/uikit'
import useFaqContent from '../../../useFaqContent'
import styles from './info.module.scss'

export default function Info({ className, ...props }) {
  const [open, setOpen] = useState()

  const toggleModal = status => () => setOpen(status)
  const faqContent = useFaqContent()

  if (!faqContent) {
    return null
  }

  return (
    <Box
      data-testid="header-info"
      direction="column"
      justify="end"
      align="end"
      className={className}
      fullWidth
      {...props}
    >
      <Text data-testid="header-info-icon" className={styles.info} onClick={toggleModal(true)}>
        FAQ
      </Text>
      <Modal open={open} onClose={toggleModal(false)} rounded showClose>
        <FAQContent content={faqContent} />
      </Modal>
    </Box>
  )
}
