import React from 'react'
import { Button, Text, Box } from '@elementinsurance/uikit'
import { useTranslation } from '@elementinsurance/utils'
import styles from './modal.module.scss'
import Modal from './Modal'

const ModalConfirm = ({
  name = 'default',
  withoutButtons,
  description,
  className,
  onCancel,
  yesText,
  noText,
  clear,
  title,
  onOk,
} = {}) => {
  const { t } = useTranslation()
  return (
    <Modal showClose onClose={clear} open className={className}>
      {title && (
        <Text
          data-testid={`modal-confirm-${name}-title`}
          className={styles.modalConfirmTitle}
          bold="bold"
          bottom="xs"
          size="m"
        >
          {title}
        </Text>
      )}

      {description && (
        <Text data-testid={`modal-confirm-${name}-description`} bold="normal" size="xs">
          {description}
        </Text>
      )}

      {!withoutButtons && (
        <Box justify="start" top="m">
          <Button
            onClick={() => {
              onCancel()
              clear()
            }}
            data-testid={`modal-confirm-${name}-button-no`}
            type="button"
            color="info"
            right="s"
          >
            {noText || t('common:field.radioGroup.option.no')}
          </Button>
          <Button
            data-testid={`modal-confirm-${name}-button-yes`}
            color="primary"
            onClick={() => {
              onOk()
              clear()
            }}
            type="button"
            size="xl"
          >
            {yesText || t('common:field.radioGroup.option.yes')}
          </Button>
        </Box>
      )}
    </Modal>
  )
}

export default ModalConfirm
