import React from 'react'
import cn from 'classnames'

import FAQContent from '@elementinsurance/common/components/faq-content/FAQContent'
import { useTranslation } from '@elementinsurance/utils'
import { Box, ModalLink } from '@elementinsurance/uikit'
import PropTypes from 'prop-types'
import useFaqContent from '../../../useFaqContent'

import styles from './menu.module.scss'

const Menu = ({ children, items = [], className, ...props }) => {
  const { t } = useTranslation()
  const faqContent = useFaqContent()

  const menuItems = faqContent
    ? [
        {
          name: 'FAQ',
          children: (
            <ModalLink
              rounded
              showClose
              linkText={t('common:footer.menu.link.faq')}
              linkProps={{ color: 'footer', 'data-testid': 'footer-link-faq' }}
            >
              <FAQContent content={faqContent} />
            </ModalLink>
          ),
        },
        ...items,
      ]
    : items

  return (
    <Box component="ul" direction="column" className={cn(styles.menu, className)} {...props}>
      {children({ items: menuItems })}
    </Box>
  )
}

Menu.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
}

export default Menu
